/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { DSResponseListJobTraceWithUserInfoDto } from '../models/DSResponseListJobTraceWithUserInfoDto';
import type { DSResponseObject } from '../models/DSResponseObject';
import type { DSResponseString } from '../models/DSResponseString';
import type { JobTraceRequest } from '../models/JobTraceRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class JobTraceControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobFilter(
        requestBody: JobTraceRequest,
    ): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/jobTrace/filter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param jobId
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public searchByJobId(
        jobId: string,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/{jobId}',
            path: {
                'jobId': jobId,
            },
        });
    }
    /**
     * @param jobId
     * @returns DSResponseObject OK
     * @throws ApiError
     */
    public deleteJob(
        jobId: string,
    ): Observable<DSResponseObject> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/jobTrace/{jobId}',
            path: {
                'jobId': jobId,
            },
        });
    }
    /**
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobProfileStatistics(): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/statistics/linkedin/profile',
        });
    }
    /**
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobPostStatistics(): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/statistics/linkedin/post',
        });
    }
    /**
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobYoutubeStatistics(): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/statistics/download/youtube',
        });
    }
    /**
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobLinkedinList(): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/list/linkedin/profile',
        });
    }
    /**
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobPostList(): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/list/linkedin/post',
        });
    }
    /**
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobYoutubeList(): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/list/download/youtube',
        });
    }
}
