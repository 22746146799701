<div class="talent-hunt">
  <div class="user-info">
    <div>
      <p>Candidate Pool</p>
      <span>
        Here you’ll find the list of all the candidate pools you have created.
      </span>
    </div>

    <button class="custom-button" (click)="createCandidatePool()">
      Create New Candidate Pool
    </button>
  </div>
</div>
<div class="table-container">
  <div>
    <div class="analytics">
      <img src="../../../assets/images/candidates.svg" alt="candidates icon" />
      <div>
        <span>Candidates</span>
        <p>{{ totalMemberCount }}</p>
      </div>
      <hr />
      <img src="../../../assets/images/folder.svg" alt="folder icon" />
      <div>
        <span>Pools</span>
        <p>{{ numberOfPools }}</p>
      </div>
    </div>

    <div class="card talent-wrapper">
      <div class="col-12 filter-wrapper">
        <div class="col-5">
          <input
            class="search-input"
            pInputText
            placeholder="Search..."
            (keyup)="searchPools()"
            [(ngModel)]="searchText"
          />
        </div>
      </div>
      <p-table
        #dt
        [value]="groups$"
        [rows]="paginationService.searchListPaginationState.rows"
        [first]="paginationService.searchListPaginationState.first"
        [paginator]="true"
        [globalFilterFields]="['name']"
        [rowHover]="true"
        dataKey="externalId"
        [showCurrentPageReport]="true"
      >
        <ng-template pTemplate="header" class="header">
          <tr class="header-row">
            <td colspan="5">
              <div class="header-content">
                <th pColumn="group.name" style="width: 20%; margin-left: 14px">
                  Candidate Pool
                </th>
                <th pColumn="group.description" style="width: 42.9%">
                  Description
                </th>
                <th pColumn="group.keywordList" style="width: 28%">Keywords</th>

                <th pColumn="group.memberCount" style="width: 10.3%">
                  Profile
                </th>
                <th style="width: 6%">Actions</th>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-group let-expanded="expanded">
          <tr>
            <td (click)="viewProfileDetails(group.id)" style="width: 20.6%">
              <div
                class="wrapper"
                [pTooltip]="group?.name"
                style="padding-left: 1rem;    min-width: 224px;"
              >
                <div class="ellipsis" style="max-width: 172px; ">
                  {{ group.name }}
                </div>
              </div>
            </td>
            <td (click)="viewProfileDetails(group.id)" style="width: 70%">
              <div class="wrapper" [pTooltip]="group?.description">
                <div class="ellipsis" style="max-width: 400px;">
                  {{ group.description }}
                </div>
              </div>
            </td>

            <td (click)="viewProfileDetails(group.id)" style="width: 27.5%">
              <div
                class="wrapper wrapper-tags"
                style="min-width: 310px; height: auto; min-height: 100px;"
              >
                <ng-container
                  *ngFor="let keyword of splitKeywords(group.keywordList); let i = index"
                >
                  <span
                    class="tag"
                    *ngIf="isExpanded || i < 2"
                    >{{ keyword }}</span
                  >
                </ng-container>
                <button
                  *ngIf="splitKeywords(group.keywordList).length > 2"
                  (click)="toggleExpand(); $event.stopPropagation()"
                  class="expand-button"
                >
                  {{ isExpanded ? '▲' : '▼' }}
                </button>
              </div>
            </td>

            <td (click)="viewProfileDetails(group.id)" style="width: 28%">
              <div class="wrapper" style="min-width: 95px;">
                {{ group.memberCount }}
              </div>
            </td>

            <!-- <td (click)="viewProfileDetails(profile)">
            <div class="wrapper">Pending</div>
          </td> -->
            <td>
              <div class="flex wrapper">
                <button
                  class="image-button mr-2"
                  pTooltip="Edit"
                  (click)="editCandidatePool(group.id)"
                >
                  <img src="../../../assets/images/edit.svg" alt="Edit Image" />
                </button>

                <button class="image-button mr-2" pTooltip="Delete">
                  <img
                    src="../../../assets/images/deleteIcon.svg"
                    alt="Delete Image"
                    (click)="deleteGroup(group.id)"
                  />
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<app-modal
  #confirmationModal
  [isVisible]="confirmationModalVisible"
></app-modal>
