import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  ChangeProcessStatusRequest,
  CompanyDto,
  CreateLinkedinProfileFilterRequest,
  LinkedinProfilesControllerService,
  ProcessCandidateStatusControllerService,
  ProcessControllerService,
  ProcessDto,
  StepControllerService,
  StepDto,
  UpdateProcessRequest,
} from 'src/app/oapi_client/data_symphony';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmDialogOutgoingRequestComponent } from '../hr-providers/outgoing-requests-list/upsert-outgoing-request/confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { Subscription } from 'rxjs';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';
import { take } from 'rxjs/operators';
import { ReviewProviderComponent } from './review-provider/review-provider.component';

interface ProcessStep {
  name: string;
  details?: string;
  isOpen?: boolean;
  id?: number;
  isCompleted?: boolean;
  showUndoMessage?: boolean;
  timeoutId?: any;
}

@Component({
  selector: 'app-outgoing-request-details',
  templateUrl: './outgoing-request-details.component.html',
  styleUrls: ['./outgoing-request-details.component.scss'],
})
export class OutgoingRequestDetailsComponent {
  constructor(
    private location: Location,
    private processManagementService: ProcessControllerService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private processUtils: ProcessUtilsService,
    private dialogService: DialogService,
    private router: Router,
    private stepControllerService: StepControllerService,
    private talentService: ProcessCandidateStatusControllerService,
    private readonly linkedinProfileService: LinkedinProfilesControllerService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.providers = navigation.extras.state['providers'];
    }
  }

  public providers: CompanyDto[] = [];
  public process: ProcessDto | undefined;
  public keyword: string | null = null;
  public processSteps: ProcessStep[] = [];
  public id: number = 0;
  public allCandidatesByProcess: any[] = [];
  public videoUrl: any;
  public steps: any[] = [];
  public orgCandidatesByProces: any[] = [];
  confirmationModalVisible: boolean = false;
  @ViewChild('confirmationModal') confirmationModal: ModalComponent | undefined;
  private acceptSubscription: Subscription | undefined;
  public isInfoVisible = false;
  public isLoading: boolean = true;

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      this.id = id;
      this.getProcessById(id);
      this.getCandidates();
    });
 
  }

  getProcessById(id: number): void {
    this.processManagementService.getById1(id).subscribe({
      next: (response) => {
        this.process = response?.body;
        this.setProcessSteps(response.body?.steps, response.body?.stepOrder);
        this.breadcrumbService.setGroupName(
          response?.body?.requirement?.name ?? ''
        );
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching process:', error);
        this.isLoading = false;
      },
    });
  }

  navigateToPreviousPage(): void {
    this.location.back();
  }

  setProcessSteps(
    steps: StepDto[] | undefined,
    stepOrder: number[] | undefined
  ): void {
    const orderedSteps = stepOrder?.map((orderId) =>
      steps?.find((step) => step.id === orderId)
    );
    this.processSteps = orderedSteps?.filter(
      (step) => step !== undefined
    ) as ProcessStep[];
  }

  assignToHrProvider() {
    if(this.providers.length > 0){
      const request = {
        hrProviderIds: this.providers
          .map((provider) => provider.id)
          .filter((id): id is number => id !== undefined),
      } as UpdateProcessRequest;
      const ref = this.dialogService.open(ConfirmDialogOutgoingRequestComponent, {
        data: {
          request: request,
          providers: this.providers,
          requestId: this.id,
          assignToHrProvider: true,
        },
      });
  
      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
  
      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  
      ref.onClose.subscribe(() => {
        this.getProcessById(this.id);
      });
      return;
    }

    this.router.navigate([`/hr-providers/${this.id}`]);
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  formatJobType(jobType: string): string {
    return this.processUtils.formatJobType(jobType);
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    return this.processUtils.formatExperience(min, max);
  }

  formatLanguages(languages: Array<string> | undefined): string {
    return this.processUtils.formatLanguages(languages);
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    return this.processUtils.formatJobTypes(jobTypes);
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    return this.processUtils.formatWorkingTypes(workingTypes);
  }

  getStepList() {
    this.stepControllerService.getStepList().subscribe((steps) => {
      if (steps.body)
        this.steps = steps.body?.map((item) => ({
          label: item['name'],
          value: item['id'],
        }));
    });
  }

  getVideo() {
    this.talentService.getCandidateVideo(36).subscribe({
      next: (res: Blob) => {
        this.videoUrl = res;
      },
      error: (err) => {
        console.error('Error fetching video:', err);
      },
    });
  }

  getCandidates() {
    this.talentService.getAllByProcessId1(this.id).subscribe((res) => {
      if (res.body) {
        let candidatesByProcess = res.body.sort((a, b) => a.id! - b.id!);

        const stepCandidatesMap = new Map<number, Map<string, any>>();
        const memberIds: string[] = [];

        candidatesByProcess.forEach((candidate) => {
          const stepId = candidate.stepId!;
          const memberId = candidate.memberId!;
          memberIds.push(memberId);

          if (!stepCandidatesMap.has(stepId)) {
            stepCandidatesMap.set(stepId, new Map<string, any>());
          }

          const candidatesInStep = stepCandidatesMap.get(stepId)!;

          if (!candidatesInStep.has(memberId)) {
            candidatesInStep.set(memberId, candidate);
          } else {
            const existingCandidate = candidatesInStep.get(memberId)!;
            if (candidate.id! < existingCandidate.id!) {
              candidatesInStep.set(memberId, candidate);
            }
          }
        });

        const requestBody: CreateLinkedinProfileFilterRequest = {
          memberId: memberIds,
          pageSize: memberIds.length,
        };

        this.linkedinProfileService
          .filter1(requestBody)
          .subscribe((profileResponse) => {
            const profiles = profileResponse.body?.content || [];

            candidatesByProcess = Array.from(
              stepCandidatesMap.values()
            ).flatMap((stepMap) => Array.from(stepMap.values()));

            this.allCandidatesByProcess = candidatesByProcess.map((talent) => {
              const profile = profiles.find(
                (p) =>
                  p.publicMemberId === talent.memberId ||
                  p.publicLinkedinMemberId === talent.memberId
              );
              return {
                ...talent,
                profile: profile ? profile : null,
              };
            });

            this.orgCandidatesByProces = this.allCandidatesByProcess;
          });
      }
    });
  }

  filterCandidates(step: any) {
    step.isOpen = !step.isOpen;
    this.processSteps.map((process) =>
      process !== step ? (process.isOpen = false) : ''
    );
    this.allCandidatesByProcess = this.orgCandidatesByProces.filter(
      (candidate) => candidate.stepId === step.id
    );
  }

  removeStepProcess(step: any) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Are you sure you want to remove ${step.name} step from the request?`,
          paraph: `It will remove all the candidates if they are added.`,
        },
      })
      .onClose.subscribe((res) => {
        if (res) {
          const steps = this.processSteps.filter(
            (processStep) => step.id !== processStep.id
          );
          this.processManagementService
            .updateProcess(this.id, { stepIds: steps.map((step) => step.id!) })
            .subscribe(() => this.getProcessById(this.id));
        }
      });
  }

  acceptAsCompleted() {
    if (this.confirmationModal) {
      this.confirmationModal.isVisible = true;
      this.confirmationModal.header =
        'Are you sure you want to accept this process as completed?';
      this.confirmationModal.message = "Once you confirm you can't go back.";
      this.confirmationModal.acceptText = 'Yes';
      this.confirmationModal.cancelText = 'No';
      this.confirmationModal.isVisible = true;

      if (this.acceptSubscription) {
        this.acceptSubscription.unsubscribe();
      }

      this.confirmationModal.cancel.subscribe(() => {
        this.confirmationModal!.isVisible = false;
      });

      this.acceptSubscription = this.confirmationModal.accept
        .pipe(take(1))
        .subscribe(() => {
          this.confirmationModal!.isVisible = false;
          this.acceptProcessAsCompleted();
          const ref = this.dialogService.open(SuccessDialogComponent, {
            data: {
              title: 'Successfully completed!',
              paraph:
                'You have successfully accepted the process as completed.',
            },
          }).onClose.subscribe(()=>{
            const ref = this.dialogService.open(ReviewProviderComponent, {data:{processId: this.id}})
            const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
            const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
            const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
            dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
          });
        });
    }
  }

  showInfo() {
    this.isInfoVisible = true;
  }

  hideInfo() {
    this.isInfoVisible = false;
  }

  acceptProcessAsCompleted() {
    const requestBody: ChangeProcessStatusRequest = {
      processStatus: ChangeProcessStatusRequest.processStatus.SUCCEED,
      changedBy: this.process?.employer?.id,
    };

    this.processManagementService.changeStatus(this.id, requestBody).subscribe({
      next: (response) => {
        this.getProcessById(this.process?.id!);
      },
      error: (error) => {
        console.error('Error changing process status', error);

        alert('Failed to mark process as completed. Please try again.');
      },
    });
  }
}
