import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { TopBarComponent } from './layout/top-bar/top-bar.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { NewSearchComponent } from './new-search/new-search.component';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { OfflineProfileListComponent } from './offline-profile-list/offline-profile-list.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PreviousHuntsComponent } from '../shared/previous-hunts/previous-hunts.component';
import { ExperienceLocationModalComponent } from '../shared/experience-location-modal/experience-location-modal.component';
import { IndustryEducationModalComponent } from '../shared/industry-education-modal/industry-education-modal.component';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { UserProfileDetailsComponent } from '../shared/user-profile-details/user-profile-details';
import { ModalComponent } from '../shared/modal/modal.component';
import { CandidatePoolComponent } from './candidate-pool/candidate-pool.component';
import { CandidatePoolMemberComponent } from './candidate-pool-member/candidate-pool-member.component';
import { UpsertCandidateModalComponent } from './candidate-pool/upsert-candidate-modal/upsert-candidate-modal.component';
import { StyleClassModule } from 'primeng/styleclass';
import { CountryModalComponent } from './admin-home/country-modal/country-modal.component';
import { HrProviderDetailsComponent } from './hr-provider-details/hr-provider-details.component';
import { OutgoingRequestDetailsComponent } from './outgoing-request-details/outgoing-request-details.component';
import { HrProvidersListComponent } from './hr-providers/hr-providers-list/hr-providers-list.component';
import { FiltersModalComponent } from '../shared/filters-modal/filters-modal.component';
import { HrProviderComponent } from './hr-providers/hr-providers-list/hr-provider/hr-provider.component';
import { RatingModule } from 'primeng/rating';
import { ProposalsComponent } from './proposals/proposals.component';
import { OutgoingRequestComponent } from './outgoing-request/outgoing-request.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { UpsertOutgoingRequestComponent } from './hr-providers/outgoing-requests-list/upsert-outgoing-request/upsert-outgoing-request.component';
import { OutgoingRequestsListComponent } from './hr-providers/outgoing-requests-list/outgoing-requests-list.component';
import { OutgoingRequestItemComponent } from './hr-providers/outgoing-requests-list/outgoing-request-item/outgoing-request-item.component';
import { ConfirmDialogOutgoingRequestComponent } from './hr-providers/outgoing-requests-list/upsert-outgoing-request/confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import { ReceivedRequestsComponent } from './received-requests/received-requests.component';
import { ReceivedRequestDetailsComponent } from './received-request-details/received-request-details.component';
import { SendCounterOfferComponent } from './negotiation-dialogs/send-counter-offer/send-counter-offer.component';
import { ConfirmationDialogComponent } from './negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from './negotiation-dialogs/success-dialog/success-dialog.component';
import { TalentDiscoveryModalComponent } from './talent-discovery-modal/talent-discovery-modal.component';
import { CandidatePoolModalComponent } from './candidate-pool-modal/candidate-pool-modal.component';
import { ProcessCandidatesTableComponent } from './received-request-details/process-candidates-table/process-candidates-table.component';
import { UploadVideoDialogComponent } from './received-request-details/process-candidates-table/upload-video-dialog/upload-video-dialog.component';
import { ErrorDialogComponent } from './negotiation-dialogs/error-dialog/error-dialog.component';
import { NotesModalComponent } from '../shared/notes-modal/notes-modal.component';
import { ReviewProviderComponent } from './outgoing-request-details/review-provider/review-provider.component';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';

@NgModule({
  declarations: [
    AdminHomeComponent,
    AdminLayoutComponent,
    FooterComponent,
    MenuComponent,
    TopBarComponent,
    NewSearchComponent,
    ProfileListComponent,
    ProfileDetailsComponent,
    OfflineProfileListComponent,
    PreviousHuntsComponent,
    ExperienceLocationModalComponent,
    IndustryEducationModalComponent,
    BreadcrumbComponent,
    UserProfileDetailsComponent,
    ModalComponent,
    CandidatePoolComponent,
    CandidatePoolMemberComponent,
    UpsertCandidateModalComponent,
    CountryModalComponent,
    HrProviderDetailsComponent,
    OutgoingRequestDetailsComponent,
    HrProvidersListComponent,
    FiltersModalComponent,
    HrProviderComponent,
    ProposalsComponent,
    OutgoingRequestComponent,
    OutgoingRequestsListComponent,
    OutgoingRequestItemComponent,
    UpsertOutgoingRequestComponent,
    ConfirmDialogOutgoingRequestComponent,
    ReceivedRequestsComponent,
    ReceivedRequestDetailsComponent,
    SendCounterOfferComponent,
    ConfirmationDialogComponent,
    SuccessDialogComponent,
    TalentDiscoveryModalComponent,
    CandidatePoolModalComponent,
    ProcessCandidatesTableComponent,
    UploadVideoDialogComponent,
    ErrorDialogComponent,
    NotesModalComponent,
    ReviewProviderComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AdminRoutingModule,
    SharedModule,
    TieredMenuModule,
    BreadcrumbModule,
    StyleClassModule,
    RatingModule,
    TabMenuModule
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
