/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { DSResponsePageListReturnerDto } from '../models/DSResponsePageListReturnerDto';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class LinkedinControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinTitles(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/titles/{page}',
            path: {
                'page': page,
            },
        });
    }
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinSkills(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/skills/{page}',
            path: {
                'page': page,
            },
        });
    }
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinSchools(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/schools/{page}',
            path: {
                'page': page,
            },
        });
    }
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinLocations(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/locations/{page}',
            path: {
                'page': page,
            },
        });
    }
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinLanguages(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/languages/{page}',
            path: {
                'page': page,
            },
        });
    }
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinIndustries(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/industries/{page}',
            path: {
                'page': page,
            },
        });
    }
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinDegrees(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/degrees/{page}',
            path: {
                'page': page,
            },
        });
    }
    /**
     * @param page
     * @returns DSResponsePageListReturnerDto OK
     * @throws ApiError
     */
    public getLinkedinCompanies(
        page: number,
    ): Observable<DSResponsePageListReturnerDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/companies/{page}',
            path: {
                'page': page,
            },
        });
    }
}
