<div class="talent-hunt">
  <div>
    <p>Received requests</p>
    <span>
      Here you’ll find the list of all the requests that have been sent to you.
    </span>
  </div>
</div>

<div class="wrapper" *ngIf="selectedProcess">
  <div class="left">
    <div class="tabs" *ngIf="processes.length !== 0">
      <button
        [ngClass]="{'active': selectedTab === 'all'}"
        (click)="filterProcesses('all')"
      >
        View all
      </button>

      <button
        [ngClass]="{'active': selectedTab === 'IN_PROGRESS'}"
        (click)="filterProcesses('IN_PROGRESS')"
      >
        In progress
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'IN_REVIEW'}"
        (click)="filterProcesses('IN_REVIEW')"
      >
        In review
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'SUCCEED'}"
        (click)="filterProcesses('SUCCEED')"
      >
        Done
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'PENDING'}"
        (click)="filterProcesses('PENDING')"
      >
        Pending
      </button>
    </div>
    <div class="small-card-container">
      <ng-container *ngFor="let process of paginatedProcesses">
        <div
          class="card-info"
          [ngClass]="{'selected': process.id === selectedProcessId}"
          (click)="selectProcess(process.id)"
        >
          <div class="card-info-wrapper">
            <div class="card-info-left">
              <div>
                <h4>{{ process?.requirement?.name }}</h4>
                <span
                  >By <strong>{{process.employerName}}</strong></span
                >
              </div>
              <p
                class="status"
                [ngClass]="{
                    'succeed': process.status === 'SUCCEED' || process.status === 'IN_REVIEW',
                    'pending': process.status === 'PENDING',
                    'in-progress': process.status === 'IN_PROGRESS'
                  }"
              >
                <ng-container
                  *ngIf="process.status === 'SUCCEED'; else otherStatus"
                >
                  done
                </ng-container>
                <ng-template #otherStatus>
                  {{ process.status | titlecase }}
                </ng-template>
              </p>
            </div>
            <div class="card-info-right">
              <div class="price">
                <p *ngIf="process.budget === null">
                  Price: <strong>Not defined</strong>
                </p>
                <p *ngIf="process.budget !== null">
                  Price:
                  <strong
                    >{{ process.budget?.amount
                    }}&nbsp;{{process.budget?.type}}</strong
                  >
                </p>
                <span>{{ formatDate(process.createdAt) }}</span>
              </div>
              <div style="width: 200px;">
                <div style="margin-left: 18px;">
                  <ng-container *ngIf="process.status === 'PENDING' ">
                    <button
                      class="offer"
                      (click)="sendOffer($event,process.id!, process.employerId!)"
                    >
                      Send Offer
                    </button>
                    <button
                      class="decline"
                      (click)="decline($event,process.id!, process.employerId!)"
                    >
                      Decline
                    </button>
                  </ng-container>
                </div>
                <ng-container
                  *ngIf="process.status === 'IN_PROGRESS' || process.status === 'SUCCEED' || process.status === 'IN_REVIEW'"
                >
                  <p class="text"><strong>View Process</strong></p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="wrapper-line"></div>
  <div class="right">
    <ng-container>
      <div class="right-top">
        <div>
          <img
            src="../../../assets/images/expandIcon.svg"
            alt="expand"
            style="margin-bottom: 10px;"
            (click)="navigateToDetails(selectedProcess.id)"
          />

          <p
            class="status"
            [ngClass]="{
                'succeed': selectedProcess.status === 'SUCCEED' || selectedProcess.status === 'IN_REVIEW',
                'pending': selectedProcess.status === 'PENDING',
                'in-progress': selectedProcess.status === 'IN_PROGRESS'
              }"
          >
            <ng-container
              *ngIf="selectedProcess.status === 'SUCCEED'; else otherStatus"
            >
              done
            </ng-container>
            <ng-template #otherStatus>
              {{ selectedProcess.status | titlecase }}
            </ng-template>
          </p>
        </div>
        <div class="company-container">
          <img
            src="../../../assets/images/closeIcon.svg"
            alt="close"
            style="margin-bottom: 10px;"
            (click)="toggleView()"
          />
          <div class="company-info">
            <span
              >By <strong>{{selectedProcess.employerName}}</strong></span
            >
            <span>{{ formatDate(selectedProcess.createdAt) }}</span>
          </div>
        </div>
      </div>
      <h2 class="title">{{selectedProcess.requirement?.name}}</h2>

      <div class="container">
        <div>
          <h2>Experience</h2>
          <p>
            {{ formatExperience(selectedProcess?.requirement?.minExperience, selectedProcess?.requirement?.maxExperience) }}
          </p>
        </div>
        <div>
          <h2>Language(s)</h2>
          <p>
            {{ formatLanguages(selectedProcess?.requirement?.languages) }}
          </p>
        </div>
      </div>
      <div class="container">
        <div>
          <h2>Location</h2>
          <p>{{selectedProcess?.requirement?.location}}</p>
        </div>
        <div>
          <h2>Remote</h2>
          <p>
            {{ formatWorkingTypes(selectedProcess?.requirement?.workingTypes) }}
          </p>
        </div>
      </div>
      <div class="container">
        <div>
          <h2>Job type</h2>
          <p>{{ formatJobTypes(selectedProcess?.requirement?.jobTypes) }}</p>
        </div>
      </div>

      <h2>Skills</h2>
      <div class="skills">
        <ng-container
          *ngFor="let keyword of selectedProcess?.requirement?.keywordList"
        >
          <span>{{ keyword }}</span>
        </ng-container>
      </div>
      <h2>Process</h2>
      <ul>
        <li *ngFor="let step of processSteps">
          <div class="process">
            <span>{{ step.name }}</span>
          </div>
        </li>
      </ul>
      <div class="info">
        <img src="../../../assets/images/info.svg" alt="info" />
        <p>
          You’ll be able to add more tasks and edit existing ones once you
          confirm this request.
        </p>
      </div>
    </ng-container>
  </div>
</div>

<div class="wrapper expanded" *ngIf="!selectedProcess">
  <div class="tabs" *ngIf="processes.length !== 0">
    <button
      [ngClass]="{'active': selectedTab === 'all'}"
      (click)="filterProcesses('all')"
    >
      View all
    </button>

    <button
      [ngClass]="{'active': selectedTab === 'IN_PROGRESS'}"
      (click)="filterProcesses('IN_PROGRESS')"
    >
      In progress
    </button>
    <button
    [ngClass]="{'active': selectedTab === 'IN_REVIEW'}"
    (click)="filterProcesses('IN_REVIEW')"
  >
    In review
  </button>
    <button
      [ngClass]="{'active': selectedTab === 'SUCCEED'}"
      (click)="filterProcesses('SUCCEED')"
    >
      Done
    </button>
    <button
      [ngClass]="{'active': selectedTab === 'PENDING'}"
      (click)="filterProcesses('PENDING')"
    >
      Pending
    </button>
  
  </div>
  <div class="small-card-container">
    <ng-container *ngFor="let process of paginatedProcesses">
      <div
        class="card-info"
        [ngClass]="{'selected': process.id === selectedProcessId}"
        (click)="selectProcess(process.id)"
      >
        <div class="card-info-wrapper">
          <div class="card-info-left">
            <div class="card-name">
              <div class="overflow-text">
                <h4>{{ process.requirement?.name }}</h4>
                <span
                  >By <strong>{{process.employerName}}</strong></span
                >
              </div>
            </div>
            <p
              class="status"
              [ngClass]="{
                        'succeed': process.status === 'SUCCEED' || process.status === 'IN_REVIEW',
                        'pending': process.status === 'PENDING',
                        'in-progress': process.status === 'IN_PROGRESS'
                      }"
            >
              <ng-container
                *ngIf="process.status === 'SUCCEED'; else otherStatus"
              >
                done
              </ng-container>
              <ng-template
                #otherStatus
                >{{ process.status | titlecase }}</ng-template
              >
            </p>
          </div>
          <div class="card-info-right">
            <div class="price" *ngIf="process.status === 'DONE'">
              <p *ngIf="process.budget === null">
                Price: <strong>Not defined</strong>
              </p>
              <p *ngIf="process.budget !== null">
                Price:
                <strong
                  >{{ process.budget?.amount



                  }}&nbsp;{{process.budget?.type}}</strong
                >
              </p>
              <span>{{ formatDate(process.createdAt) }}</span>
            </div>
            <div>
              <ng-container *ngIf="process.status === 'PENDING' ">
                <button
                  class="offer"
                  (click)="sendOffer($event,process.id!, process.employerId!)"
                >
                  Send Offer
                </button>
                <button
                  class="decline"
                  (click)="decline($event,process.id!, process.employerId!)"
                >
                  Decline
                </button>
              </ng-container>
              <ng-container
                *ngIf="process.status === 'IN_PROGRESS' || process.status === 'SUCCEED' ||  process.status === 'IN_REVIEW'"
              >
                <p class="text"><strong>View Process</strong></p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="processes.length === 0" class="empty">
  <div><img src="../../../assets/images/folders.svg" alt="folder" /></div>
  <h2>No recieved requests.</h2>
  <p>You haven‘t recieved any requests yet.</p>
</div>

<div class="pagination" *ngIf="totalRecords > pageSize">
  <p-paginator
    [rows]="pageSize"
    [totalRecords]="totalRecords"
    (onPageChange)="onPageChange($event)"
  ></p-paginator>
</div>
