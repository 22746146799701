import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CountryControllerService,
  CreateLinkedinSearchLinkJobRequest,
  DSResponseString,
  JobTraceRequest,
  LinkedinProfilesControllerService,
} from '../../oapi_client/data_symphony';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DataService } from 'src/app/helpers/data.service';
import { AuthHelper } from 'src/app/helpers/auth.helper';
import * as Sentry from '@sentry/browser';
import { ViewChild } from '@angular/core';
import {
  JobTraceWithUserInfoDto,
  JobTraceControllerService,
} from '../../oapi_client/data_symphony';
import { DatePipe } from '@angular/common';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

interface JobTitle {
  name: string;
  code: string;
}
interface Country {
  code: string;
  name: string;
}

@Component({
  selector: 'app-new-search',
  templateUrl: './new-search.component.html',
  styleUrls: ['./new-search.component.scss'],
  providers: [DatePipe],
})
export class NewSearchComponent implements OnInit {
  formGroup!: FormGroup;
  values: string[] = [];
  currentUserID: number = 0;
  isFilterApplied: boolean = false;
  search: any = {};
  selectedSearches: JobTraceWithUserInfoDto[] = [];
  searches$: JobTraceWithUserInfoDto[] = []; // Initialize with an empty Observable
  private refreshTimer$ = interval(500000); // Interval in milliseconds (5 seconds)
  private destroy$ = new Subject<void>();
  countries?: Array<Country>;
  fullStackSkills$?: Observable<string[]>;
  selectedSkills: string[] = [];
  selectedCountries!: Array<Country>;
  selectedJobTitles!: JobTitle[];
  confirmationModalVisible: boolean = false;
  isExpanded: boolean = false;
  isCountryExpanded: boolean = false;
  loading$: Observable<boolean> = this.dataService.loading$!;

  @ViewChild('dt') dataTable: Table | undefined;
  @ViewChild('confirmationModal') confirmationModal: ModalComponent | undefined;

  constructor(
    private router: Router,
    private readonly countryControllerService: CountryControllerService,
    private readonly dataService: DataService,
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    private authHelper: AuthHelper,
    private messageService: MessageService,
    private readonly jobTraceService: JobTraceControllerService,
    private datePipe: DatePipe,
    protected paginationService: PaginationService
  ) {
    countryControllerService.findCountry('EN').subscribe((response) => {
      if (response.success) {
        this.countries = response.body?.map((item) => ({
          code: item['code'],
          name: item['name'],
        }));
      }
    });
  }

  ngOnInit() {
    this.loading$ = this.dataService.loading$;
    this.formGroup! = new FormGroup({
      values: new FormControl<string[] | null>(null),
    });

    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );

    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.searchListPaginationState.first;
      this.dataTable.rows =
        this.paginationService.searchListPaginationState.rows;
    }

    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
    this.dataService.updateSearches();
    this.dataService.searches$.subscribe((jobs: JobTraceWithUserInfoDto[]) => {
      this.searches$ = jobs;
    });

    this.refreshTimer$
      .pipe(takeUntil(this.destroy$), takeUntil(this.stopTimerConditionally()))
      .subscribe(() => {
        if (!this.isFilterApplied) {
          this.dataService.updateSearches();
          this.dataService.searches$.subscribe(
            (jobs: JobTraceWithUserInfoDto[]) => {
              this.searches$ = jobs;
            }
          );
        }
      });
  }

  private stopTimerConditionally() {
    return new Observable<void>((observer) => {
      if (this.isFilterApplied) {
        observer.next();
        observer.complete();
      }
    });
  }

  onValueRemoved(event: any): void {
    const index = this.selectedSkills.indexOf(event.value);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
    }
  }

  onValueAdded(event: any): void {
    this.values = this.values.map((str) => str.trim().toLowerCase());
    const uniqueSkills = [...new Set(this.values)];
    this.values = uniqueSkills.flat();
  }

  clearAllInputs() {
    this.fullStackSkills$ = new Observable<string[]>();
    this.selectedSkills = [];
    this.selectedCountries = new Array<Country>();
    this.values = [];
  }

  startSearch() {
    if (!this.values || this.values.length === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Search keywords can not be null.',
      });
      return;
    }

    const searchReqBody: CreateLinkedinSearchLinkJobRequest = {
      keywordList: this.values,
      countryList: this.selectedCountries
        ? (this.selectedCountries.map((country) => country.code) as string[])
        : [],
    };
    //  searchResults: DSResponseString | undefined;
    this.linkedinProfileService.getLinkedinProfiles(searchReqBody).subscribe(
      (response: DSResponseString) => {
        this.dataService.updateSearches();
        this.clearAllInputs();
      },
      (error) => {
        Sentry.captureException('Talent Hunt can not be started.', {
          tags: { customTag: 'Talent Hunt' },
          level: 'error',
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatDateString(dateString: string): string {
    return <string>this.datePipe.transform(dateString, 'dd/MM/yyyy');
  }

  viewSearchDetails(selectedSearch: any) {
    this.paginationService.searchListPaginationState = {
      first: this.dataTable?.first,
      rows: this.dataTable?.rows,
    };

    const jobID = selectedSearch.jobId;
    const countryList = selectedSearch.parameters['country_list'];
    const keywordList = selectedSearch.parameters['keyword_list'];

    const url = this.router.createUrlTree(['/profile-list'], {
      queryParams: {
        jobID: jobID,
        countryList: countryList,
        keywordList: keywordList,
      },
    });

    localStorage.setItem('lastVisitedUrl', url.toString());
    this.router.navigateByUrl(url);
  }

  deleteSearch(search: any) {
    if (this.confirmationModal) {
      this.confirmationModal!.isVisible = true;
      this.confirmationModal.header =
        'Are you sure you want to delete this record?';
      this.confirmationModal.message = "Once you confirm you can't go back.";
      this.confirmationModal.acceptText = 'Yes';
      this.confirmationModal.cancelText = 'No';
      this.confirmationModal.isVisible = true;

      this.confirmationModal.accept.subscribe(() => {
        this.jobTraceService.deleteJob(search.jobId).subscribe((result) => {
          console.log(result);
          this.dataService.updateSearches();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Successfully',
            life: 3000,
          });
        });
        this.confirmationModal!.isVisible = false;
      });

      this.confirmationModal.cancel.subscribe(() => {
        this.confirmationModal!.isVisible = false;
      });
    }
  }

  receiveFilters(filters: JobTraceRequest) {
    if (filters) {
      this.searches$ = filters as JobTraceWithUserInfoDto[];
      this.isFilterApplied = true;
    }
  }
  splitKeywords(keywordList: string): string[] {
    return keywordList.split(',').filter((keyword) => keyword.trim() !== '');
  }

  toggleExpand(search: any, type: string) {
    if (type === 'keyword') {
      search.isExpandedKeyword = !search.isExpandedKeyword;
    } else if (type === 'country') {
      search.isExpandedCountry = !search.isExpandedCountry;
    }
  }
}
