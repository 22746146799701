import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProcessUtilsService {
  constructor(private datePipe: DatePipe) {}

  formatDate(dateString: string | undefined): string {
    if (!dateString) {
      return 'Unknown Date';
    }
    const date = new Date(dateString);
    return this.datePipe.transform(date, 'dd.MM.yyyy.') ?? 'Invalid Date';
  }

  formatJobType(jobType: string): string {
    switch (jobType) {
      case 'PART_TIME':
        return 'Part-time';
      case 'FULL_TIME':
        return 'Full-time';
      default:
        return jobType
          .replace('_', ' ')
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    if (min !== null && max !== null && min !== max) {
      return `${min}-${max} years`;
    } else if (min !== null) {
      return `+${min} years`;
    } else if (max !== null) {
      return `${max} years`;
    } else {
      return 'Not specified';
    }
  }

  formatLanguages(languages: Array<string> | undefined): string {
    if (!languages) return 'Not specified';
    return languages
      .map(
        (language) =>
          language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()
      )
      .join(', ');
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    if (!jobTypes) return 'Not specified';
    return jobTypes.map((jobType) => this.formatJobType(jobType)).join(', ');
  }

  formatWorkingType(workingType: string): string {
    return (
      workingType.charAt(0).toUpperCase() + workingType.slice(1).toLowerCase()
    );
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    if (!workingTypes) return 'Not specified';
    return workingTypes
      .map((workingType) => this.formatWorkingType(workingType))
      .join(', ');
  }
}
