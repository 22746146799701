import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../helpers/auth-guard.service';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { NewSearchComponent } from './new-search/new-search.component';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { OfflineProfileListComponent } from './offline-profile-list/offline-profile-list.component';
import { UserProfileDetailsComponent } from '../shared/user-profile-details/user-profile-details';
import { CandidatePoolComponent } from './candidate-pool/candidate-pool.component';
import { CandidatePoolMemberComponent } from './candidate-pool-member/candidate-pool-member.component';
import { HrProviderDetailsComponent } from './hr-provider-details/hr-provider-details.component';
import { OutgoingRequestDetailsComponent } from './outgoing-request-details/outgoing-request-details.component';
import { HrProvidersListComponent } from './hr-providers/hr-providers-list/hr-providers-list.component';
import { OutgoingRequestComponent } from './outgoing-request/outgoing-request.component';
import { OutgoingRequestsListComponent } from './hr-providers/outgoing-requests-list/outgoing-requests-list.component';
import { ReceivedRequestsComponent } from './received-requests/received-requests.component';
import { UpsertOutgoingRequestComponent } from './hr-providers/outgoing-requests-list/upsert-outgoing-request/upsert-outgoing-request.component';
import { ReceivedRequestDetailsComponent } from './received-request-details/received-request-details.component';

const routes: Routes = [
  {
    path: '',

    component: AdminLayoutComponent,
    children: [
      {
        path: 'admin/dashboard',
        component: AdminHomeComponent,
        canActivate: [authGuard],
      },
      {
        path: 'admin/talent-hunt',
        canActivate: [authGuard],
        component: NewSearchComponent,
      },
      {
        path: 'admin/profile-list',
        canActivate: [authGuard],
        component: ProfileListComponent,
      },
      {
        path: 'admin/talent-discovery',
        canActivate: [authGuard],
        component: OfflineProfileListComponent,
      },
      {
        path: 'profile/:id',
        canActivate: [authGuard],
        component: UserProfileDetailsComponent,
      },
      {
        path: 'admin/candidate-pool',
        canActivate: [authGuard],
        component: CandidatePoolComponent,
      },
      {
        path: 'candidate-pool/:id',
        canActivate: [authGuard],
        component: CandidatePoolMemberComponent,
      },
      {
        path: 'provider-details/:id',
        canActivate: [authGuard],
        component: HrProviderDetailsComponent,
      },
      {
        path: 'outgoing-request-details/:id',
        canActivate: [authGuard],
        component: OutgoingRequestDetailsComponent,
      },
      {
        path: 'admin/hr-providers',
        canActivate: [authGuard],
        component: HrProvidersListComponent,
      },
      {
        path: 'admin/outgoing-request',
        canActivate: [authGuard],
        component: OutgoingRequestComponent,
      },
      {
        path: 'admin/outgoing-requests',
        canActivate: [authGuard],
        component: OutgoingRequestsListComponent,
      },
      {
        path: 'admin/received-requests',
        canActivate: [authGuard],
        component: ReceivedRequestsComponent,
      },
      {
        path: 'received-request-details/:id',
        canActivate: [authGuard],
        component: ReceivedRequestDetailsComponent,
      },
      {
        path: 'admin/outgoing-requests-upsert/:id',
        canActivate: [authGuard],
        component: UpsertOutgoingRequestComponent,
      },
      {
        path: 'admin/outgoing-requests-upsert',
        canActivate: [authGuard],
        component: UpsertOutgoingRequestComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
