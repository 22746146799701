<div class="modal-header-container">
  <p class="modal-title">Add from Talent discovery</p>
  <span class="modal-description"
    >You can add complete groups or you can choose individual people.</span
  >
</div>

<div class="table-container">
  <div class="talent-wrapper">
    <p-table
      #dt
      [value]="profiles$"
      [lazy]="true"
      (onLazyLoad)="loadProfiles($event)"
      [rows]="rows"
      [first]="first"
      [paginator]="true"
      [rowHover]="true"
      dataKey="externalId"
      [paginatorPosition]="'bottom'"
      class="table"
    >
      <ng-template pTemplate="header" class="header">
        <tr>
          <th colspan="5">
            <div class="button-wrapper">
              <div style="display: flex">
                <th style="display: flex; align-items: center">
                  <label class="custom-checkbox-label">
                    <input
                      type="checkbox"
                      [checked]="selectAll"
                      (change)="toggleSelectAll($event)"
                    />
                    <span class="custom-checkbox-icon"></span>
                  </label>
                  <span style="padding-left: 5px;">
                    Select All
                    <span *ngIf="selectAll" class="records">
                      ({{totalRecords}})
                    </span>
                  </span>
                  <div class="filters">
                    <app-experience-location-modal
                      (filterApplied)="applyFilters($event)"
                    ></app-experience-location-modal>
                    <app-industry-education-modal
                      (filterApplied)="applyFilters($event)"
                    ></app-industry-education-modal>
                  </div>
                </th>
              </div>
            </div>
          </th>
        </tr>
        <tr class="header-row">
          <td colspan="3">
            <div class="header-content">
              <th
                pColumn="person_info.name"
                style="width: 31%; ; margin-left: 25px"
              >
                Name
              </th>
              <th pColumn="person_info.title" style="width: 33.5%">Title</th>
              <th pColumn="person_info.experience" style="width: 30%">
                Experiences
              </th>
              <!-- <th style="width: 5%">Consent status</th> -->
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-profile let-expanded="expanded">
        <tr>
          <td style="width: 30%; padding-left: 1rem;">
            <div class="name">
              <label class="custom-checkbox-label">
                <input
                  type="checkbox"
                  [checked]="selectedProfiles[filter.pageIndex ?? 0]?.has(profile.publicMemberId || profile.publicLinkedinMemberId)"
                  (change)="toggleSelect(profile)"
                  class="custom-checkbox"
                />
                <span class="custom-checkbox-icon"></span>
              </label>

              <span
                class="ellipsis"
                [pTooltip]="profile.personInfo.name"
                style="padding-left: 5px; width: 172px;"
              >
                {{ profile.personInfo.name }}
              </span>
            </div>
          </td>
          <td style="width: 40%; padding-right: 5px;">
            <div [pTooltip]="profile?.personInfo?.title">
              <div class="ellipsis">
                {{ profile?.personInfo?.title }}
              </div>
            </div>
          </td>
          <td style="width: 30%;">
            <div [pTooltip]="generateTooltip(profile)">
              <div class="ellipsis">
                <ng-container
                  *ngFor="let experience of profile?.experience?.slice(0, 3); let last = last"
                >
                  <span
                    *ngIf="!last && isValidInstitutionName(experience.institutionName)"
                    >{{ experience.institutionName }},
                  </span>
                  <span *ngIf="last">{{ experience.institutionName }}</span>
                </ng-container>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright">
        <span class="records">
          Showing {{ first + 1 }} to {{ first + profiles$.length }} of
          {{ totalRecords }} 
        </span>
      </ng-template>
    </p-table>
  </div>
  <div class="button-container">
    <button class="button-transparent" (click)="onClose()">Cancel</button>
    <button class="button-purple" (click)="addTalentsToProcessStep()">
      Save
    </button>
  </div>
</div>
