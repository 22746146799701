<div class="talent-hunt">
  <div>
    <p>Outgoing requests</p>
    <span>
      Here you’ll find the list of all the requests that you have prepared/sent
      out to other companies. You can edit them, remove them, see progress and
      add new ones.
    </span>
  </div>
</div>

<div class="wrapper expanded">
  <div class="close-icon">
    <img
      src="../../../assets/images/closeIcon.svg"
      alt="close"
      (click)="navigateToPreviousPage()"
    />
  </div>

  <div class="company-info">
    <span>{{formatDate(process?.createdAt)}}</span>
  </div>
  <div class="provider-info">
    <h2 class="title">{{process?.requirement?.name}}</h2>
    <p
      *ngIf="process?.status!== 'IN_REVIEW'"
      class="status"
      [ngClass]="{
            'succeed': process?.status === 'SUCCEED' , 
            'pending': process?.status === 'PENDING',
            'in-progress': process?.status === 'IN_PROGRESS'
          }"
    >
      <ng-container *ngIf="process?.status === 'SUCCEED'; else otherStatus">
        done
      </ng-container>
      <ng-template #otherStatus>{{ process?.status | titlecase }}</ng-template>
    </p>
    <button
      class="button-purple"
      (click)="acceptAsCompleted()"
      *ngIf="process?.status === 'IN_REVIEW'"
    >
      Accept as completed
    </button>
    <div class="image-container">
      <img
        src="../../../assets/images/info.svg"
        alt="info"
        *ngIf="process?.status === 'IN_REVIEW'"
        (mouseover)="showInfo()"
        (mouseleave)="hideInfo()"
        [ngClass]="{'hovered': isInfoVisible}"
      />

      <div *ngIf="isInfoVisible" class="info-text">
        HR company completed their process. If it’s all set, click the button.
      </div>
    </div>

    <span
      *ngIf="process?.status == 'PENDING' && providers.length > 0"
      (click)="assignToHrProvider()"
      style="cursor: pointer;"
      >Assign To HR Provider</span
    >
    <img
      *ngIf="process?.status == 'PENDING'"
      src="../../../assets/images/whiteEditIcon.svg"
      alt="edit"
      routerLink="/admin/outgoing-requests-upsert/{{id}}"
    />
    <img
      *ngIf="process?.status == 'PENDING'"
      src="../../../assets/images/deleteIcon.svg"
      alt="Delete icon"
    />
  </div>

  <div class="container">
    <div>
      <h2>Experience</h2>
      <p>
        {{ formatExperience(process?.requirement?.minExperience, process?.requirement?.maxExperience) }}
      </p>
    </div>
    <div>
      <h2>Language(s)</h2>
      <p>{{ formatLanguages(process?.requirement?.languages) }}</p>
    </div>
    <div>
      <h2>Location</h2>
      <p>{{process?.requirement?.location}}</p>
    </div>
    <div>
      <h2>Remote</h2>
      <p>{{ formatWorkingTypes(process?.requirement?.workingTypes) }}</p>
    </div>
    <div>
      <h2>Job type</h2>
      <p>{{ formatJobTypes(process?.requirement?.jobTypes) }}</p>
    </div>
    <div *ngIf="process?.requirement?.keywordList">
      <h2>Skills</h2>
      <div class="skills">
        <ng-container *ngFor="let keyword of process?.requirement?.keywordList">
          <span>{{ keyword }}</span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="description">
    <h2>Description</h2>
    <p>{{ process?.requirement?.description }}</p>
  </div>
  <hr />
  <div class="process-top">
    <h2>Process</h2>
    <p
      *ngIf="process?.status=== 'IN_REVIEW'"
      class="status"
      [ngClass]="{
    'succeed': process?.status === 'IN_REVIEW'}"
    >
      To be reviewed
    </p>
  </div>

  <p>
    When HR company marks the whole process as ‘To be reviewed‘, you can close
    the job.
  </p>

  <div *ngFor="let step of processSteps">
    <div class="process-item" [ngClass]="{'open': step.isOpen}">
      <div class="process">
        <div
          class="not-completed"
          *ngIf="process?.status !== 'IN_REVIEW' && process?.status !== 'SUCCEED'"
        ></div>
        <div class="not-completed" *ngIf="process?.status === 'IN_REVIEW' || process?.status === 'SUCCEED'">
          <img src="../../../assets/images/checkmark.svg" />
        </div>
        <span>{{ step.name }}</span>
      </div>
      <div
        class="filter-buttons"
        *ngIf="step.isOpen && allCandidatesByProcess.length>0"
      >
        <div class="sort">
          <span
            >Sort by: <span>Successful</span
            ><span class="pi pi-angle-down"></span
          ></span>
        </div>
        <div class="filter">
          <app-filters-modal (filtersApplied)="ngOnInit()"></app-filters-modal>
        </div>
      </div>
      <div class="process-action">
        <button class="note-button" [disabled]="true" *ngIf="step.isOpen">
          <img
            src="../../../assets/images/note-process.svg"
            alt="Note"
            class="disabled"
          />
        </button>
        <button
          pButton
          pRipple
          icon="pi pi-angle-down"
          class="expand-button"
          (click)="filterCandidates(step)"
          [ngClass]="{'open': step.isOpen, 'closed': !step.isOpen}"
        ></button>
      </div>
    </div>
    <div class="panel-content" [ngClass]="{'opened': step.isOpen}">
      <app-process-candidates-table
        [candidates]="allCandidatesByProcess"
      ></app-process-candidates-table>
    </div>
  </div>
  <hr />
</div>

<app-proposals [processId]="id"></app-proposals>
<app-modal
  #confirmationModal
  [isVisible]="confirmationModalVisible"
></app-modal>
