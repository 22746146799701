/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateLinkedinProfileFilterRequest = {
    personNameSurname?: string;
    personAbout?: string;
    personTitle?: string;
    personLocation?: Array<string>;
    experienceInstitutionName?: Array<string>;
    experienceTitle?: Array<string>;
    experienceAbout?: string;
    minYears?: number;
    maxYears?: number;
    memberId?: Array<string>;
    experienceLocation?: Array<string>;
    industryName?: Array<string>;
    skill?: Array<string>;
    educationDegree?: Array<string>;
    educationInstitutionName?: Array<string>;
    certificationTitle?: string;
    certificationInstitutionName?: string;
    certificationAbout?: string;
    publicationTitle?: string;
    publicationInstitutionName?: string;
    publicationAbout?: string;
    courseTitle?: string;
    courseAbout?: string;
    projectTitle?: string;
    projectAbout?: string;
    awardTitle?: string;
    awardInstitution?: string;
    languageName?: Array<string>;
    languageDegree?: string;
    testScoreTitle?: string;
    testScoreEquals?: string;
    jobId?: string;
    pageSize?: number;
    pageIndex?: number;
    sortBy?: string;
    sortDirection?: CreateLinkedinProfileFilterRequest.sortDirection;
};
export namespace CreateLinkedinProfileFilterRequest {
    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }
}

