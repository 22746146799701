import { Component, Input, OnInit, Output } from '@angular/core';
import { CompanyDto } from 'src/app/oapi_client/data_symphony';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hr-provider',
  templateUrl: './hr-provider.component.html',
  styleUrls: ['./hr-provider.component.scss'],
})
export class HrProviderComponent implements OnInit {
  @Input() public provider!: CompanyDto;
  @Output() public addProvider = new EventEmitter<CompanyDto>();
  public averageRating: number = 0;
  public select: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.averageRating = this.getAverageRating(this.provider.companyStarsDto!);
  }

  public getAverageRating(stars: any[]): number {
    if (stars.length == 0 || stars === null) {
      return 0;
    }
    const total = stars.reduce((sum, item) => sum + item.starCount!, 0);
    return total / stars.length;
  }

  selectProvider() {
    this.select = !this.select;
    this.addProvider.emit(this.provider!);
  }

  navigateToProviderDetails(id: number | undefined) {
    this.router.navigate(['/provider-details', id]);
  }
}
