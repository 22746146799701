import { Component, Input } from '@angular/core';
import { stat } from 'fs';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  BudgetDto,
  CompanyControllerService,
  CreateNegotiationRequest,
  NegotiationControllerService,
  OfferWithCompanyDto,
  ProcessControllerService,
} from 'src/app/oapi_client/data_symphony';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';
import { forkJoin, map } from 'rxjs';
import { SendCounterOfferComponent } from '../negotiation-dialogs/send-counter-offer/send-counter-offer.component';

@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.scss'],
})
export class ProposalsComponent {
  public proposals: any[] = [];
  public successTitle: string = '';
  public successParaph: string = '';
  public paraph: string = '';
  public title: string = '';
  public proposal: string | null | undefined;
  @Input() processId: number = 0;

  constructor(
    private dialogService: DialogService,
    private negotiationControllerService: NegotiationControllerService,
    private processControllerService: ProcessControllerService,
    private companyController: CompanyControllerService
  ) { }

  ngOnInit() {
    this.getProposals();
  }
  public nestp = [{ id: 1, name: 'joe' }, { id: 1, name: 'joe' }, { id: 1, name: 'adme' }]

  getProposals() {
    this.negotiationControllerService
      .getAllByProcessId(this.processId)
      .subscribe((proposals) => {
        if (proposals.body) {
          this.proposals = proposals.body.sort(
            (a, b) => a.hrProviderId! - b.hrProviderId!
          );
          const latestProposalsMap = new Map<number, any>();
          const hrProviderCount = new Map<number, number>();

          for (const proposal of this.proposals) {
            const hrProviderId = proposal.hrProviderId!;

            hrProviderCount.set(hrProviderId, (hrProviderCount.get(hrProviderId) || 0) + 1);

            if (!latestProposalsMap.has(hrProviderId) || new Date(proposal.date!) > new Date(latestProposalsMap.get(hrProviderId).date)) {
              proposal.hasThreeOccurrences = hrProviderCount.get(hrProviderId) === 3;
              latestProposalsMap.set(hrProviderId, proposal);
            }
          }

          const latestProposals = Array.from(latestProposalsMap.values());

          const result = [
            ...latestProposals,
            ...this.proposals.filter(proposal => !latestProposalsMap.has(proposal.hrProviderId!))
          ];
          this.proposals = result;
          console.log(this.proposals)
          const observables = this.proposals.map((element) =>
            this.companyController.getById2(element.hrProviderId!).pipe(
              map((provider) => ({
                proposal: element,
                provider: provider.body,
              }))
            )
          );

          forkJoin(observables).subscribe((results) => {
            results.forEach(({ proposal, provider }) => {
              proposal.companyName = provider?.name!;
              proposal.avgStarCount = provider?.meanStar;
              proposal.succeedProcessCount = provider?.companyStarsDto?.length;
            });
          });

          if (
            this.proposals.filter(
              (proposal) => proposal.isEmployerAgreed == true
            ).length == 1
          ) {
            this.proposals = this.proposals.filter(
              (proposal) => proposal.isEmployerAgreed == true
            );
          }
        }
      });
  }

  openDialog(
    status: string,
    proposalId: number,
    proposalAmount?: number,
    proposalMoneyType?: string,
    employerId?: number
  ) {
    switch (status) {
      case Status.Accept:
        this.title = "Are you sure you want to accept HRing's offer?";
        this.paraph =
          'Once you confirm all the other companies offers will be automatically declined.';
        this.proposal = '50 EUR';
        this.showDialog(
          ConfirmationDialogComponent,
          Status.Accept,
          proposalId,
          proposalAmount,
          proposalMoneyType
        );
        break;
      case Status.Decline:
        this.title = "Are you sure you want to decline HRing's offer?";
        this.paraph = "Once you confirm, you can't go back";
        this.proposal = null;
        this.showDialog(
          ConfirmationDialogComponent,
          Status.Decline,
          proposalId,
          undefined,
          undefined
        );
        break;
      case Status.Counter:
        this.title = 'Counter offer';
        this.paraph = "You can send the counter-offer if you wish for a price change.";
        this.showDialog(
          SendCounterOfferComponent,
          Status.Counter,
          proposalId,
        )
        break;
    }
  }

  cancel() { }

  showDialog(
    component: any,
    status: Status,
    proposalId: number,
    proposalAmount?: number,
    proposalMoneyType?: string
  ) {
    const ref = this.dialogService.open(component, {
      modal: true,
      data: {
        title: this.title,
        paraph: this.paraph,
        successTitle: this.successTitle,
        successParaph: this.successParaph,
        proposal:
          proposalAmount && proposalMoneyType
            ? `${proposalAmount} ${proposalMoneyType}`
            : undefined,
      },
    });
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    if (Status.Accept === status) {
      ref.onClose.subscribe((result) => {
        if (result) {
          this.acceptOffer(proposalId);
        }
      });
    } else if (Status.Decline === status) {
      ref.onClose.subscribe((result) => {
        if (result) {
          this.declineOffer(proposalId);
        }
      });
    } else if (Status.Counter === status) {
      ref.onClose.subscribe((result) => {
        if (result) {
          this.counterOffer(proposalId, result.amount, result.note);
        }
      });
    }
  }

  counterOffer(proposalId: number, amount: number, note: string) {

    const hrProvider = this.proposals.find(f => f.id === proposalId)

    const offer: CreateNegotiationRequest = {
      offerToId: hrProvider?.hrProviderId, //to the employer
      processId: this.processId,
      offer: {
        type: BudgetDto.type.EURO,
        amount: amount,
      },
      note: note,
    };

    this.negotiationControllerService.createNegotiation(offer).subscribe(() => {
      this.getProposals();
    })
  }

  public acceptOffer(proposalId: number) {
    this.processControllerService
      .approveNegotiation(proposalId)
      .subscribe(() => {
        const ref = this.dialogService.open(SuccessDialogComponent, {
          data: {
            title: 'Offer accepted',
            paraph: 'You have accepted the offer. The process can start.',
          },
        });
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        this.getProposals();
      });
  }

  public declineOffer(proposalId: number) {
    this.negotiationControllerService
      .declineOffer(proposalId)
      .subscribe((response) => {
        const ref = this.dialogService.open(SuccessDialogComponent, {
          data: {
            title: 'Request declined',
            paraph:
              'You have declined the request. The Employer will be notified.',
          },
        });
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        this.getProposals();
      });
  }
}

enum Status {
  Accept = 'Accept',
  Decline = 'Decline',
  Counter = 'Counter',
}
