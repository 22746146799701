/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKey } from './ApiKey';
import type { GrantedAuthority } from './GrantedAuthority';
import type { Team } from './Team';
import type { UserRole } from './UserRole';
export type User = {
    createdBy?: number;
    createdAt?: string;
    updatedAt?: string;
    updatedBy?: number;
    deletedAt?: string;
    id?: number;
    userRoles?: Array<UserRole>;
    teamName: User.teamName;
    firstName: string;
    lastName: string;
    username: string;
    email?: string;
    password: string;
    enabled?: boolean;
    secret?: string;
    accountNonExpired?: boolean;
    accountNonLocked?: boolean;
    credentialsNonExpired?: boolean;
    verificationCode?: string;
    team?: Team;
    apiKeyList?: Array<ApiKey>;
    authorities?: Array<GrantedAuthority>;
};
export namespace User {
    export enum teamName {
        ROOT = 'ROOT',
        EXTERNAL = 'EXTERNAL',
        INTERNAL = 'INTERNAL',
    }
}

