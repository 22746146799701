import { Component } from '@angular/core';

import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ProcessControllerService } from 'src/app/oapi_client/data_symphony';
import { RequestSuccessDialogComponent } from '../request-success-dialog/request-success-dialog.component';

@Component({
  selector: 'app-confirm-dialog-outgoing-request',
  templateUrl: './confirm-dialog-outgoing-request.component.html',
  styleUrls: ['./confirm-dialog-outgoing-request.component.scss'],
})
export class ConfirmDialogOutgoingRequestComponent {
  public providers: any[] = [];
  public averageRating: number = 0;
  public title: string =
    'Are you sure you want to send request to these companies?';
  constructor(
    private processService: ProcessControllerService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService
  ) {
    this.providers = this.config.data.providers;
    if (this.config.data.requestId) {
      if (!this.config.data.assignToHrProvider) {
        this.title = 'Are you sure you want to update this request?';
      }
    } else {
      this.title = 'Are you sure you want to send request to these companies?';
    }
  }

  send() {
    if (!this.config.data.requestId) {
      this.processService
        .create(this.config.data.request)
        .subscribe((response) => {
          this.ref.close(response.body!.id);
          const ref = this.dialogService.open(RequestSuccessDialogComponent, {
            data: {
              title: this.config.data.assignToHrProvider ? 'Request successfully updated' : 'Request successfully sent!',
              paraph:
                'You’ll get notified once the company responds to your request',
            },
          });
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent =
            dialogRef?.instance as DynamicDialogComponent;
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        });
    } else {
      this.processService
        .updateProcess(this.config.data.requestId, this.config.data.request)
        .subscribe((response) => {
          this.ref.close();
          const ref = this.dialogService.open(RequestSuccessDialogComponent, {
            data: {
              title: 'Request successfully updated!',
              paraph:
                'You’ll get notified once the company responds to your request.',
            },
          });
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent =
            dialogRef?.instance as DynamicDialogComponent;
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        });
    }
  }

  close() {
    this.ref.close();
  }

  ngOnInit(): void {
    this.addRatingProperty();
  }

  public addRatingProperty() {
    this.providers = this.providers.map((provider) => ({
      ...provider,
      averageRating: this.getAverageRating(provider.companyStarsDto!),
    }));
  }

  public getAverageRating(stars: any[]): number {
    if (stars.length == 0 || stars === null) {
      return 0;
    }
    const total = stars.reduce((sum, item) => sum + item.starCount!, 0);
    return total / stars.length;
  }
}
