import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DSResponseListJobTraceWithUserInfoDto, JobTraceWithUserInfoDto, JobTraceControllerService } from '../oapi_client/data_symphony';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private searchesSubject = new BehaviorSubject<JobTraceWithUserInfoDto[]>([]);
  public searches$: Observable<JobTraceWithUserInfoDto[]> = this.searchesSubject.asObservable();

  constructor(private jobTraceService: JobTraceControllerService) { }

  updateSearches(currentUserID: number) {
    this.jobTraceService.jobLinkedinList(currentUserID).pipe(
      map((response: DSResponseListJobTraceWithUserInfoDto) => response.body || [])
    ).subscribe((result: JobTraceWithUserInfoDto[]) => {
      this.searchesSubject.next(result);
    });
  }
}
