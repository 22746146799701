import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {User} from "../../../helpers/User";
import {AuthHelper} from "../../../helpers/auth.helper";
import {MenuItem} from "primeng/api/menuitem";

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit{
  currentUser$?: Observable<User>;
  items: MenuItem[] | undefined;
  constructor(private authHelper: AuthHelper) {
  }

  ngOnInit(): void {
    this.currentUser$ = this.authHelper.currentUser;
    let user: string = "";
    this.authHelper.currentUser.subscribe(usr=>
    {
      user=usr.userName;
    });
    this.items = [
      {label: 'Log Out', icon: 'pi pi-power-off', command: () => this.logout()}
  ];
  }
  logout(): void {
    this.authHelper.logout();
  }
}
