import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { UploadVideoDialogComponent } from './upload-video-dialog/upload-video-dialog.component';
import { ProcessCandidateStatusControllerService, UserControllerService } from 'src/app/oapi_client/data_symphony';
import { MessageService } from 'primeng/api';
import { ConfirmationDialogComponent } from '../../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '../../negotiation-dialogs/success-dialog/success-dialog.component';

@Component({
  selector: 'app-process-candidates-table',
  templateUrl: './process-candidates-table.component.html',
  styleUrls: ['./process-candidates-table.component.scss'],
})
export class ProcessCandidatesTableComponent implements OnInit {
  @Input() public candidates: any | undefined;
  @Input() disabledCandidates: { [id: number]: boolean } = {};
  @Output() public statusUpdated = new EventEmitter<void>();
  @Output() public selectedCandidatesChanged = new EventEmitter<number[]>();

  public rows: number = 5;
  public first: number = 0;
  public selectAll: boolean = false;
  public totalRecords: number = 0;
  public allConnectionsChecked: boolean = false;
  public videoAdded: boolean = false;
  public selectCandidate: any;
  public connectionsChecked: any[] = [];
  public showNoteDialog: boolean = false;
  public selectedCandidateIds: number[] = [];
  public employerLogged: boolean = false;

  constructor(
    private dialogService: DialogService,
    private talentService: ProcessCandidateStatusControllerService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private authService: UserControllerService
  ) { }

  ngOnInit(): void {
    this.authService.getUserRoles().subscribe(roles => {
      if (roles.body!.includes('ROLE_EMPLOYER_ADMIN')) {
        this.employerLogged = true;
      }
    });
  }

  ngOnChanges(): void {
    this.getVideos();
  }

  getVideos() {
    if (this.candidates.length > 0 && !this.videoAdded) {
      this.videoAdded = true;
      for (const element of this.candidates) {
        this.talentService.getCandidateVideo(element.id).subscribe(res => {
          if (res.type !== 'text/xml') {
            element.video = URL.createObjectURL(res);
          }
        }, error => {
          console.log('error')
        })
      }
    }
  }

  showNotes(id: number) {
    this.showNoteDialog = true;
  }

  checkAllConnections(event: any) {
    const isChecked = event.checked;
    this.candidates.forEach((candidate: { checked: boolean; id: number }) => {
      candidate.checked = isChecked;
    });

    if (isChecked) {
      this.selectedCandidateIds = this.candidates.map(
        (candidate: { id: any }) => candidate.id
      );
    } else {
      this.selectedCandidateIds = [];
    }

    this.selectedCandidatesChanged.emit([...this.selectedCandidateIds]);
    this.allConnectionsChecked = isChecked;
    this.cdr.detectChanges();
    console.log(this.allConnectionsChecked, 'sdf');
  }

  isValidInstitutionName(name: string | null): boolean {
    return name !== null && name?.trim().length > 0;
  }

  generateTooltip(candidate: any): string {
    let tooltipText = '';
    candidate?.profile?.experience?.forEach(
      (experience: { institutionName: string }) => {
        if (experience.institutionName && experience.institutionName.trim()) {
          if (tooltipText) {
            tooltipText += ', ';
          }
          tooltipText += experience.institutionName.trim();
        }
      }
    );
    return tooltipText;
  }

  getVideo(id: number) {
    this.talentService.getCandidateVideo(id).subscribe((res) => {
      return res;
    });
  }

  deleteVideo(candidate: any) {
    const ref = this.dialogService
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Are you sure you want to remove ${candidate?.profile?.personInfo?.name} video interview?`,
          paraph: `This action cannot be reversed.`,
        },
      });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.talentService.deleteCandidateVideo(candidate.id).subscribe(() => {
          const ref2 = this.dialogService.open(SuccessDialogComponent, {
            data: {
              title: `The video has been successfully deleted.`,
              paraph: `You can upload the video again.`,
            },
          });

          const dialogRef2 = this.dialogService.dialogComponentRefMap.get(ref2);
          const dynamicComponent2 = dialogRef2?.instance as DynamicDialogComponent;
          const ariaLabelledBy2 = dynamicComponent2.getAriaLabelledBy();
          dynamicComponent2.getAriaLabelledBy = () => ariaLabelledBy2;
          ref2.onClose.subscribe(()=>{this.statusUpdated.emit()})
        });
      }
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

  }

  uploadVideo(candidateName: string, memberId: string, candidateId: number) {
    const ref = this.dialogService.open(UploadVideoDialogComponent, {
      data: {
        name: candidateName,
        memberId: memberId,
        candidateId: candidateId,
      },
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(() => {
      this.videoAdded = false;
      this.getVideos();
    });
  }

  selectRow(candidate: any) {
    const id = candidate.id;
    if (this.selectedCandidateIds.includes(id)) {
      this.selectedCandidateIds = this.selectedCandidateIds.filter(
        (candidateId) => candidateId !== id
      );
    } else {
      this.selectedCandidateIds.push(id);
    }
  }

  isSelected(item: any): boolean {
    return item === this.selectCandidate;
  }

  onCheckboxChange(event: any, candidate: any) {
    const id = candidate.id;
    if (event.target.checked) {
      if (!this.selectedCandidateIds.includes(id)) {
        this.selectedCandidateIds.push(id);
      }
    } else {
      const index = this.selectedCandidateIds.indexOf(id);
      if (index > -1) {
        this.selectedCandidateIds.splice(index, 1);
      }
    }
    this.allConnectionsChecked =
      this.selectedCandidateIds.length === this.candidates.length;
    this.selectedCandidatesChanged.emit([...this.selectedCandidateIds]);
  }

  notesUpdate() {
    this.statusUpdated.emit();
  }

  succeedCandidateStatus(selectedCandidateIds: number[]) {
    if (!Array.isArray(selectedCandidateIds)) {
      selectedCandidateIds = [selectedCandidateIds];
    }

    if (!selectedCandidateIds || selectedCandidateIds.length === 0) {
      this.messageService.add({
        severity: 'info',
        summary: 'No Candidates Selected',
        detail: 'Please select candidates to update their status',
      });
      return;
    }
    this.talentService.succeedCandidateStatus(selectedCandidateIds).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Status Updated',
          detail: 'Successfully moved to the next process step',
        });
        this.allConnectionsChecked = false;
        this.cdr.detectChanges();
        selectedCandidateIds.forEach(id => {
          this.disabledCandidates[id] = true;
        });
        this.statusUpdated.emit();
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error updating the candidate status',
        });
        console.error('Failed to update candidate status', error);
      },
    });
  }
}
