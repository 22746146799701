import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  items: MenuItem[] = [];
  groupName: string = '';
  personName: string = '';
  source: string = '';
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.updateBreadcrumbs(this.groupName, this.personName, this.source);
    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.updateBreadcrumbs(this.groupName, this.personName, this.source);
        })
    );

    this.subscriptions.push(
      this.breadcrumbService.getGroupName().subscribe((name: string) => {
        this.groupName = name;
        this.updateBreadcrumbs(this.groupName, this.personName, this.source);
      })
    );

    this.subscriptions.push(
      this.breadcrumbService.getPersonName().subscribe((name: string) => {
        this.personName = name;
        this.updateBreadcrumbs(this.groupName, this.personName, this.source);
      })
    );

    this.subscriptions.push(
      this.breadcrumbService.getSource().subscribe((source: string) => {
        this.source = source;
        this.updateBreadcrumbs(this.groupName, this.personName, this.source);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  updateBreadcrumbs(
    groupName: string,
    personName: string,
    source: string
  ): void {
    const url = this.router.url;
    const storedGroupId = localStorage.getItem('groupId');

    this.items = [];
    if (url.includes('/dashboard')) {
      this.items.push({
        label: 'Dashboard',
        routerLink: '/dashboard',
      });
    } else if (url.includes('/talent-hunt')) {
      this.items.push({
        label: 'Talent Hunt',
        routerLink: '/talent-hunt',
      });
    } else if (url.includes('/hr-providers')) {
      this.items.push({
        label: 'HR Providers',
        routerLink: '/hr-providers',
      });
    } else if (url.includes('/provider-details')) {
      this.items.push({
        label: 'HR Providers',
        routerLink: '/hr-providers',
      });
      if (groupName) {
        this.items.push({
          label: groupName,
        });
      }
    } else if (url === '/outgoing-requests') {
      this.items.push({
        label: 'Outgoing Requests',
        routerLink: '/outgoing-requests',
      });
    } else if (url === '/outgoing-request') {
      this.items.push({
        label: 'Outgoing Requests',
        routerLink: '/outgoing-request',
      });
    } else if (url.includes('/outgoing-request-details')) {
      this.items.push({
        label: 'Outgoing Requests',
        routerLink: '/outgoing-request',
      });
      if (groupName) {
        this.items.push({
          label: groupName,
        });
      }
    } else if (url.includes('/received-requests')) {
      this.items.push({
        label: 'Received Requests',
        routerLink: '/received-requests',
      });
    } else if (url.includes('/received-request-details')) {
      this.items.push({
        label: 'Received Requests',
        routerLink: '/received-requests',
      });
      if (groupName) {
        this.items.push({
          label: groupName,
        });
      }
    } else if (url.includes('/profile-list')) {
      this.items.push({
        label: 'Talent Hunt',
        routerLink: '/talent-hunt',
      });
      if (groupName) {
        this.items.push({
          label: groupName,
        });
      }
    } else if (url.includes('/talent-discovery')) {
      this.items.push({
        label: 'Talent Discovery',
        routerLink: '/talent-discovery',
      });
    } else if (url.includes('/candidate-pool')) {
      this.items.push({
        label: 'Candidate Pools',
        routerLink: '/candidate-pool',
      });

      this.groupName = '';
    } else if (url.includes('/candidate-member')) {
      this.items.push({
        label: 'Candidate Pools',
        routerLink: '/candidate-pool',
      });
      if (this.groupName) {
        this.items.push({
          label: this.groupName,
          routerLink: `/candidate-member/${storedGroupId}`,
        });
      }
    } else if (url.includes('/profile')) {
      if (source === 'candidate-member') {
        this.items.push({
          label: 'Candidate Pools',
          routerLink: '/candidate-pool',
        });
        if (groupName) {
          this.items.push({
            label: groupName,
            routerLink: `/candidate-member/${storedGroupId}`,
          });
        }
      } else if (source === 'talent-discovery') {
        this.items.push({
          label: 'Talent Discovery',
          routerLink: '/talent-discovery',
        });
      } else if (source === 'talent-hunt') {
        this.items.push({
          label: 'Talent Hunt',
          routerLink: '/talent-hunt',
        });
        if (groupName) {
          const lastVisitedUrl = localStorage.getItem('lastVisitedUrl');
          if (lastVisitedUrl) {
            const urlParams = new URLSearchParams(lastVisitedUrl.split('?')[1]);
            this.items.push({
              label: groupName,
              routerLink: '/profile-list',
              queryParams: {
                jobID: urlParams.get('jobID'),
                countryList: urlParams.get('countryList'),
                keywordList: urlParams.get('keywordList'),
              },
            });
          } else {
            this.items.push({
              label: groupName,
            });
          }
        }
      }
      if (personName) {
        this.items.push({
          label: personName,
        });
      }
    }
  }
}
