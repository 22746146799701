import { Injectable } from "@angular/core";
import { CreateLinkedinProfileFilterRequest } from "../oapi_client/data_symphony";

@Injectable({
  providedIn: "root",
})
export class ParameterService {
  filterState: CreateLinkedinProfileFilterRequest = {
    personNameSurname: "",
    personAbout: "",
    personTitle: "",
    personLocation: [],
    minYears: undefined,
    maxYears: undefined,
    experienceInstitutionName: [],
    experienceTitle: [],
    experienceAbout: "",
    experienceLocation: [],
    industryName: [],
    skill: [],
    educationDegree: [],
    educationInstitutionName: [],
    certificationTitle: "",
    certificationInstitutionName: "",
    certificationAbout: "",
    publicationTitle: "",
    publicationInstitutionName: "",
    publicationAbout: "",
    courseTitle: "",
    courseAbout: "",
    projectTitle: "",
    projectAbout: "",
    awardTitle: "",
    awardInstitution: "",
    languageName: [],
    languageDegree: "",
    testScoreTitle: "",
    testScoreEquals: "",
    jobId: "",
    pageSize: 25,
    pageIndex: 0,
    sortBy: "",
    sortDirection: CreateLinkedinProfileFilterRequest.sortDirection.ASC,
  };
  onlineFilterState: CreateLinkedinProfileFilterRequest = {
    personNameSurname: "",
    personAbout: "",
    personTitle: "",
    personLocation: [],
    minYears: undefined,
    maxYears: undefined,
    experienceInstitutionName: [],
    experienceTitle: [],
    experienceAbout: "",
    experienceLocation: [],
    industryName: [],
    skill: [],
    educationDegree: [],
    educationInstitutionName: [],
    certificationTitle: "",
    certificationInstitutionName: "",
    certificationAbout: "",
    publicationTitle: "",
    publicationInstitutionName: "",
    publicationAbout: "",
    courseTitle: "",
    courseAbout: "",
    projectTitle: "",
    projectAbout: "",
    awardTitle: "",
    awardInstitution: "",
    languageName: [],
    languageDegree: "",
    testScoreTitle: "",
    testScoreEquals: "",
    jobId: "",
    pageSize: 25,
    pageIndex: 0,
    sortBy: "",
    sortDirection: CreateLinkedinProfileFilterRequest.sortDirection.ASC,
  };
}
