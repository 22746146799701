<div>
    <div class="text-center mt-5">
        <h4>Rate your HR provider</h4>
        <p>Now that the job is closed, you can rate the HR Provider. You can do this later.</p>
    <p-rating [(ngModel)]="value" [cancel]="false"/>
    </div>

    <div class="container-icons">
        <div class="icons-p">
            <span class="limit">{{characterCount}}/3000</span>
        </div>
        <textarea name="" id="" cols="30" rows="10" [(ngModel)]="comment"></textarea>
    </div>
    <div class="text-center">
        <button class="text-center" (click)="rate()" >Rate</button>
    </div>
</div>