import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent {
  constructor(private router: Router) {}

  ngOnInit() {}

  isAdminTalentHunt(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.startsWith('/talent-hunt') ||
      currentUrl.startsWith('/profile-list') ||
      currentUrl.startsWith('/talent-discovery') ||
      currentUrl.startsWith('/profile') ||
      currentUrl.startsWith('/candidate-pool') ||
      currentUrl.startsWith('/candidate-member') ||
      currentUrl.startsWith('/provider-details') ||
      currentUrl.startsWith('/outgoing-request-details') ||
      currentUrl.startsWith('/hr-providers') ||
      currentUrl.startsWith('/outgoing-request') ||
      currentUrl.startsWith('/outgoing-requests') ||
      currentUrl.startsWith('/received-requests') ||
      currentUrl.startsWith('/received-request-details')
    );
  }
}
