import { Component, OnInit } from '@angular/core';
import {
  CompanyControllerService,
  CompanyDto,
  ProcessControllerService,
  UpdateProcessRequest,
} from '../../../oapi_client/data_symphony';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmDialogOutgoingRequestComponent } from '../outgoing-requests-list/upsert-outgoing-request/confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import { RequestSuccessDialogComponent } from '../outgoing-requests-list/upsert-outgoing-request/request-success-dialog/request-success-dialog.component';

@Component({
  selector: 'app-hr-providers-list',
  templateUrl: './hr-providers-list.component.html',
  styleUrls: ['./hr-providers-list.component.scss'],
})
export class HrProvidersListComponent implements OnInit {
  public providers: CompanyDto[] = [];
  public first: number = 0;
  public rows: number = 8;
  public providersCount: number = 0;
  public pagedItems: any[] = [];
  public selectedProviders: CompanyDto[] = [];
  public searchText: string = '';
  public totalRecords: number = 0;
  public selectedSort: { label: string; value: number } = {
    label: 'Newest',
    value: 1,
  };
  public rating = [
    { label: 'Newest', value: 1 },
    { label: 'Best Rating', value: 2 },
  ];
  public request: any = null;
  public isLoading: boolean = true;
  public outgoingReqId: number = 0;
  public form: any = null;

  constructor(private companyService: CompanyControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private processControllerService: ProcessControllerService,

  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.request = navigation.extras.state['request'];
      this.form = navigation.extras.state['form'];
    }
   }

  ngOnInit(): void {
    this.isLoading = true;
    this.getHrProviders();

    this.route.params.subscribe((params) => {
      const id = +params['id'];
      if(Number.isNaN(id)){
        this.outgoingReqId = 0;
      }
      else{
        this.outgoingReqId = id;
      }
    });
  }

  backToRequest(){
    this.router.navigate(['/outgoing-requests-upsert'], {state: { form: this.form}});
  }

  getHrProviders() {
    this.companyService.getCompanyList('HR_PROVIDER').subscribe(
      (companies) => {
        this.providers = companies.body!.sort(
          (a, b) =>
            -new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        );
        this.updatePagedItems(0, 8);
        this.isLoading = false;
      },
      (error) => {
        console.log(error, 'error'), (this.isLoading = false);
      }
    );
  }

  paginate(event: any) {
    const startIndex = event.first;
    const endIndex = startIndex + event.rows;
    this.updatePagedItems(startIndex, endIndex);
  }

  update(selectedSort: any) {
    if (selectedSort.value == 1) {
      this.getHrProviders();
    } else {
      this.sortByBest();
    }
  }

  sortByBest() {
    const getMaxStarCount = (companyStarsDto: any[]) => {
      if (Array.isArray(companyStarsDto) && companyStarsDto.length > 0) {
        return Math.max(...companyStarsDto.map((star) => star.starCount ?? 0));
      }
      return 0;
    };
    this.providers = this.providers.sort((a, b) => {
      const starCountA = getMaxStarCount(a.companyStarsDto!);
      const starCountB = getMaxStarCount(b.companyStarsDto!);

      return starCountB - starCountA;
    });
    this.updatePagedItems(0, 8);
  }

  updatePagedItems(startIndex: number, endIndex: number) {
    const filteredProviders = this.providers.filter((provider) =>
      provider.name
        ?.toLocaleLowerCase()
        .includes(this.searchText.toLocaleLowerCase())
    );
    this.totalRecords = filteredProviders.length;
    this.pagedItems = filteredProviders.slice(startIndex, endIndex);
  }

  searchProviders() {
    this.updatePagedItems(0, 8);
  }

  addSelectedProvider(event: any) {
    const index = this.selectedProviders.findIndex(
      (provider) => provider.id === event.id
    );
    if (index !== -1) {
      this.selectedProviders = this.selectedProviders.filter(
        (provider) => provider.id !== event.id
      );
    } else {
      this.selectedProviders.push(event);
    }
  }

  receiveFilters(event: any) {
    if (
      event.request.rating == null &&
      event.request.minFinishedJobCount == null &&
      event.request.maxFinishedJobCount == null
    ) {
      this.getHrProviders();
    } else {
      this.providers = event.resp;
      this.updatePagedItems(0, 8);
    }
  }

  sendRequestToSelectedHRs() {
    const hrProviderIds = this.selectedProviders
      .map(provider => provider.id)
      .filter((id): id is number => id !== undefined);
  
    const openConfirmDialog = (request: any) => {
      const ref = this.dialogService.open(ConfirmDialogOutgoingRequestComponent, {
        data: {
          request,
          providers: this.selectedProviders,
          assignToHrProvider: true,
          requestId: this.outgoingReqId || undefined,
        },
      });
  
      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
  
      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  
      ref.onClose.subscribe((processId) => {
        if(this.outgoingReqId == 0)
          this.outgoingReqId = processId;
        this.router.navigate([`outgoing-request-details/${this.outgoingReqId}`]);
      });
    };
  
    if (this.request) {
      this.request.hrProviderIds = hrProviderIds;
      openConfirmDialog(this.request);
    } else if (this.outgoingReqId !== 0 && this.providers.length > 0) {
      const request = { hrProviderIds } as UpdateProcessRequest;
      openConfirmDialog(request);
    } else {
      this.router.navigate(['/outgoing-requests'], { state: { providers: this.selectedProviders } });
    }
  }
  
}
