<div class="layout-dashboard">
  <div class="col-12 md:col-6 xl:col-3 analytics">
    <div class="analytics-item-container">
      <div class="analytics-item">
        <img
          src="../../../assets/images/cpDashboardIcon.svg"
          alt="folder icon"
        />
        <div>
          <span>Candidates</span>
          <p>{{this.totalResultCount}}</p>
          <span class="green-text">
            <span>
              {{this.todayResultCount}}
            </span>
            added today
          </span>
        </div>
      </div>
      <div class="vertical-separator"></div>
    </div>
    <div class="analytics-item-container">
      <div class="analytics-item">
        <img
          src="../../../assets/images/talentHuntDashboard.svg"
          alt="talent icon"
        />
        <div>
          <span>Talent Hunt</span>
          <p>{{this.statsCounts.completed}}</p>
          <span class="purple-text">
            <span
              class="purple-text"
              >{{this.statsCounts.inProgress + this.statsCounts.started}}</span
            >
            in progress</span
          >
        </div>
      </div>
      <div class="vertical-separator"></div>
    </div>
    <div class="analytics-item-container">
      <div class="analytics-item">
        <img
          src="../../../assets/images/folderDashboardIcon.svg"
          alt="cp icon"
        />
        <div>
          <span>Candidate pools</span>
          <p>19</p>
          <span class="green-text">
            <span> 0 </span>
            new created</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 md:col-6 xl:col-3 analytics" style="margin: 3rem 0">
    <div class="countries-container">
      <div class="header">
        <div>
          <h2>Countries</h2>
          <p>By experience</p>
        </div>
        <div class="sort-by">Sort by: <span>Most popular</span></div>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>0-2</th>
            <th>2-5</th>
            <th>5-8</th>
            <th>8-10</th>
            <th>10+</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let country of countries">
            <td>{{country.name}}</td>

            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{country.experience['0-2']}}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{country.experience['2-5']}}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{country.experience['5-8']}}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{country.experience['8-10']}}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{country.experience['10+']}}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="view-more" (click)="openModal()">View more</div>
    </div>
  </div>

  <app-country-modal
    *ngIf="isModalOpen"
    [countries]="countries"
    (close)="closeModal()"
  ></app-country-modal>
</div>
