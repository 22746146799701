import { Component, Input, OnInit } from '@angular/core';
import { CompanyDto, ProcessDto } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-outgoing-request-item',
  templateUrl: './outgoing-request-item.component.html',
  styleUrls: ['./outgoing-request-item.component.scss']
})
export class OutgoingRequestItemComponent implements OnInit {

  @Input() public request!: ProcessDto;
  @Input() public providers: any;

  ngOnInit(): void {
  }

  getProviderNames(): string {
    return this.request?.hrProviders?.map(provider => provider.name).join(', ') || '';
  }

  getDate(time: string) {
    const date = new Date(time);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
  
    return `${this.addZero(day)} ${this.toStringMonth(month)} ${year}`;
  }

  addZero(number: number) {
    return number < 10 ? `0${number}` : number;
  }

  get budgetDisplay(): string {
    const budget = this.request.budget;
    return budget?.amount ? `${budget.amount} ${budget.type}` : 'N/D';
  }

  toStringMonth(month: number) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month];
  }
}
