/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateInviteUserRequest } from '../models/CreateInviteUserRequest';
import type { DSResponseString } from '../models/DSResponseString';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class RegisterControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public inviteMember(
        requestBody: CreateInviteUserRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/custom/user/register/invite',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param formData
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public createCompanyAndUser(
        formData?: {
            logo: Blob;
            info: string;
        },
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/custom/user/register/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
