<div class="talent-hunt">
    <div class="user-info">
        <div>
            <p>HR Providers</p>
            <span>
                Here you’ll find the list of all the HR Companies offering the services you’re looking for. You can
                multi select and send a request to multiple HR companies at a time. </span>
        </div>
    </div>
</div>

<div class="card">
    <div class="filter-search mt-4">
        <div class="search-bar">
            <input class="search" placeholder="Search..." (keyup)="searchProviders()" [(ngModel)]="searchText">
        </div>
        <div class="filter-button ml-4">
            <app-filters-modal (filtersApplied)="receiveFilters($event)"></app-filters-modal>
        </div>
        <div class="sort ml-2">
            <div class="label">
                Sort by: 
            </div>
            <div class="dropdown">
                <p-dropdown [options]="rating" [(ngModel)]="selectedSort"  placeholder="Select the minimum number" optionLabel="label"
                id="singleSelectInput" />
            </div>
        </div>
    </div>
    <div class="providers mt-5">
        <div *ngFor="let provider of pagedItems" class="provider">
            <app-hr-provider [provider]="provider" (addProvider)="addSelectedProvider($event)"></app-hr-provider>
        </div>
        <div *ngIf="pagedItems.length === 0">
            <div class="no-results">
                <div class="image">
                    <img src="../../assets/images/no-results.svg">
                </div>
                <h4>No results for your query!</h4>
                <p>Please try different keywords and filters.</p>
            </div>
        </div>
    </div>

    <div class="requestButton">
        <button pButton pRipple type="button" label="Send request to selected HRs"
            [ngStyle]="selectedProviders.length == 0 ? {'background': '#F0F2F4', 'color':'#BCBCBB'} : {}"
            routerLink="/admin/outgoing-requests" [state]="{ providers: selectedProviders}">
        </button>
    </div>

    <div class="pagination">
        <p-paginator [rows]="8" [totalRecords]="totalRecords" (onPageChange)="paginate($event)" />
    </div>
</div>