import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  profileListPaginationState: { first: number | null | undefined; rows: number | undefined; totalRecords: number | undefined} = { first: 0, rows: 25, totalRecords:0 };
  lazyProfileListPaginationState: { first: number | null | undefined; rows: number | undefined; totalRecords: number} = { first: 0, rows: 25, totalRecords:0 };
  searchListPaginationState: { first: number | null | undefined; rows: number | undefined} = { first: 0, rows: 10 };
}
