import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { LinkedinControllerService } from "../../oapi_client/data_symphony/services/LinkedinControllerService";
@Component({
  selector: "app-industry-education-modal",
  templateUrl: "./industry-education-modal.component.html",
  styleUrls: ["./industry-education-modal.component.scss"],
})
export class IndustryEducationModalComponent implements OnInit {
  @Input() profiles: any[] = [];

  isOpen: boolean = false;
  checkbox1: boolean = false;
  checkbox2: boolean = false;
  industries: any[] = [];
  selectedIndustries: { name: string }[] = [];
  companies: any[] = [];
  selectedCompanies: { name: string }[] = [];
  languages: any[] = [];
  selectedLanguages: { name: string }[] = [];
  titles: any[] = [];
  selectedTitles: { name: string }[] = [];
  degrees: any[] = [];
  selectedDegrees: { name: string }[] = [];
  schools: any[] = [];
  selectedSchools: { name: string }[] = [];
  empty: boolean = false;
  pageIndustries: number = 0;
  pageCompanies: number = 0;
  pageLanguages: number = 0;
  pageTitles: number = 0;
  pageDegrees: number = 0;
  pageSchools: number = 0;
  loading: boolean = true;

  constructor(
    private elementRef: ElementRef,
    private linkedinService: LinkedinControllerService
  ) {}
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();
  @Output() filterCleared: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.fetchIndustries(this.pageIndustries);
    this.fetchCompanies(this.pageCompanies);
    this.fetchLanguages(this.pageLanguages);
    this.fetchTitles(this.pageTitles);
    this.fetchDegrees(this.pageTitles);
    this.fetchSchools(this.pageSchools);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  fetchIndustries(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinIndustries(this.pageIndustries)
        .subscribe((data) => {
          if (data.body) {
            const newIndustries = data.body.content?.map((industry) => ({
              name: industry.name || "",
            }));
            this.industries = [...this.industries, ...newIndustries!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageIndustries++;
    }
  }

  fetchCompanies(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinCompanies(this.pageCompanies)
        .subscribe((data) => {
          if (data.body) {
            const newCompanies = data.body.content?.map((company) => ({
              name: company.name || "",
            }));
            this.companies = [...this.companies, ...newCompanies!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageCompanies++;
    }
  }

  fetchLanguages(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinLanguages(this.pageLanguages)
        .subscribe((data) => {
          if (data.body) {
            const newLanguages = data.body.content?.map((lang) => ({
              name: lang.name || "",
            }));
            this.languages = [...this.languages, ...newLanguages!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageLanguages++;
    }
  }

  fetchTitles(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinTitles(this.pageTitles)
        .subscribe((data) => {
          if (data.body) {
            const newTitles = data.body.content?.map((title) => ({
              name: title.name || "",
            }));
            this.titles = [...this.titles, ...newTitles!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageTitles++;
    }
  }

  fetchDegrees(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinDegrees(this.pageDegrees)
        .subscribe((data) => {
          if (data.body) {
            const newDegrees = data.body.content?.map((degree) => ({
              name: degree.name || "",
            }));
            this.degrees = [...this.degrees, ...newDegrees!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageDegrees++;
    }
  }

  fetchSchools(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinSchools(this.pageSchools)
        .subscribe((data) => {
          if (data.body) {
            const newSchools = data.body.content?.map((school) => ({
              name: school.name || "",
            }));
            this.schools = [...this.schools, ...newSchools!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageSchools++;
    }
  }

  applyFilter() {
    const filters = {
      industryName: this.selectedIndustries.map((industry) => industry.name),
      experienceInstitutionName: this.selectedCompanies.map(
        (company) => company.name
      ),
      languageName: this.selectedLanguages.map((language) => language.name),
      educationDegree: this.selectedDegrees.map((degree) => degree.name),
      educationInstitutionName: this.selectedSchools.map(
        (school) => school.name
      ),
      experienceTitle: this.selectedTitles.map((title) => title.name),
    };
    this.filterApplied.emit(filters);
    this.isOpen = false;
  }

  clearFilter() {
    this.checkbox1 = false;
    this.checkbox2 = false;
    this.selectedCompanies = [];
    this.selectedIndustries = [];
    this.selectedLanguages = [];
    this.selectedTitles = [];
    this.selectedDegrees = [];
    this.selectedSchools = [];
  }

  stopEventPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
}
