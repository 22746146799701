import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from "@angular/core";
import {
  CountryControllerService,
  DSResponseListString,
  JobTraceControllerService,
  LinkedinProfilesControllerService,
  UserControllerExtendService,
  UserDto,
} from "src/app/oapi_client/data_symphony";
import {
  JobTraceRequest,
  DSResponseListJobTraceWithUserInfoDto,
} from "src/app/oapi_client/data_symphony";
import { Observable } from "rxjs";
import { AuthHelper } from "src/app/helpers/auth.helper";
import { DataService } from "src/app/helpers/data.service";

@Component({
  selector: "app-previous-hunts",
  templateUrl: "./previous-hunts.component.html",
  styleUrls: ["./previous-hunts.component.scss"],
})
export class PreviousHuntsComponent implements OnInit {
  @Output() filtersApplied: EventEmitter<any> = new EventEmitter<any>();
  isOpen: boolean = false;
  users: UserDto[] | undefined;
  selectedUsers: any[] = [];
  checkbox1: boolean = false;
  checkbox2: boolean = false;
  suggestedWords: string[] = [];
  displayedSuggestions: string[] = [];
  values: string[] = [];
  selectedSkills: string[] = [];
  countries?: any;
  selectedCountries: any[] = [];
  currentUserID: number = 0;
  selectedDate: { label: string; value: string } = {
    label: "",
    value: "",
  };
  dateOptions: { label: string; value: string }[] = [];

  constructor(
    private elementRef: ElementRef,
    private readonly countryControllerService: CountryControllerService,
    private readonly jobTraceControllerService: JobTraceControllerService,
    private userControllerService: UserControllerExtendService,
    private authHelper: AuthHelper,
    private dataService: DataService,
    private linkedinService: LinkedinProfilesControllerService
  ) {
    countryControllerService.findCountry("EN").subscribe((response) => {
      if (response.success) {
        this.countries = response.body;
      }
    });
    this.updateDisplayedSuggestions();
    this.generateDateOptions();
  }

  ngOnInit(): void {
    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
    this.getUser(this.currentUserID);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  onCheckboxChange(checkboxName: string) {
    if (checkboxName === "checkbox1") {
      this.checkbox1 = !this.checkbox1;
    } else if (checkboxName === "checkbox2") {
      this.checkbox2 = !this.checkbox2;
    }
  }

  getUser(currentUserID: number) {
    this.userControllerService.userTeammates(currentUserID).subscribe(
      (response) => {
        this.users = response?.body?.map((user: any) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        }));
      },
      (error) => {
        console.error("Error:", error);
      }
    );
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  applyFilter() {
    let fromDate = new Date();
    let toDate = new Date();

    switch (this.selectedDate.value) {
      case "today":
        const today = new Date();
        today.setDate(today.getDate() + 1);
        fromDate = new Date();
        toDate = today;
        break;
      case "yesterday":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        fromDate = yesterday;
        toDate = new Date();
        break;
      case "lastWeek":
        const lastWeekStart = new Date();
        lastWeekStart.setDate(lastWeekStart.getDate() - 7);
        fromDate = lastWeekStart;
        toDate = new Date();
        break;
      case "lastMonth":
        const lastMonthStart = new Date();
        lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
        fromDate = lastMonthStart;
        toDate = new Date();
        break;
    }
    let searchStatus: string[] = [];
    if (this.checkbox1) {
      searchStatus.push("STARTED", "IN_PROGRESS");
    }
    if (this.checkbox2 === true) {
      searchStatus.push("COMPLETED");
    }

    const teammateIds = this.selectedUsers?.map((user) => user?.id);
    const requestBody: JobTraceRequest = {
      teammateIds: teammateIds,
      source: "linkedIn",
      sourceType: "profile",
      fromDate: this.formatDate(fromDate),
      toDate: this.formatDate(toDate),
      searchStatus: searchStatus,
      keywordList: this.values,
      countryList: this.selectedCountries.map((country) => country?.name),
    };

    this.jobLinkedinList(requestBody).subscribe((response) => {
      this.filtersApplied.emit(response?.body);
      this.isOpen = false;
    });
  }

  jobLinkedinList(
    requestBody: JobTraceRequest
  ): Observable<DSResponseListJobTraceWithUserInfoDto> {
    return this.jobTraceControllerService.jobFilter(requestBody);
  }

  stopEventPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  clearFilter() {
    this.selectedUsers = [];
    this.selectedDate = {
      label: "",
      value: "",
    };
    this.checkbox1 = false;
    this.checkbox2 = false;
    this.values = [];
    this.displayedSuggestions = [];
    this.selectedCountries = [];
  }

  onValueRemoved(event: any): void {
    const removedWord = event.value;
    const index = this.selectedSkills.indexOf(removedWord);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
      this.addWordToSuggestions(removedWord);
      this.updateDisplayedSuggestions();
    }
  }

  onValueAdded(event: any): void {
    this.suggestKeyword(this.currentUserID, event.value).subscribe(
      (response) => {
        if (response.body) {
          this.suggestedWords = response.body || [];
          this.updateDisplayedSuggestions();
        }
      }
    );
  }

  suggestKeyword(
    userId: number,
    input: string
  ): Observable<DSResponseListString> {
    return this.linkedinService.suggestKeyword(userId, input);
  }

  removeEnteredWordsFromSuggestions(): void {
    this.values.forEach((enteredWord: string) => {
      const index = this.suggestedWords.indexOf(enteredWord);
      if (index !== -1) {
        this.suggestedWords.splice(index, 1);
      }
    });
    this.updateDisplayedSuggestions();
  }

  addWordToSkills(event: Event, word: string): void {
    event.stopPropagation();
    if (this.suggestedWords.includes(word)) {
      const index = this.values.indexOf(word);
      if (index === -1) {
        this.values.push(word);
        this.removeWordFromSuggestions(word);
        this.updateDisplayedSuggestions();
      }
    } else {
      // If the word is not in the suggested list, add it directly to the values array
      const index = this.values.indexOf(word);
      if (index === -1) {
        this.values.push(word);
      }
    }
  }

  updateDisplayedSuggestions(): void {
    const availableSuggestions = this.suggestedWords.filter(
      (word) => !this.values.includes(word.toLowerCase())
    );
    const allWords = [...availableSuggestions, ...this.selectedSkills];
    this.displayedSuggestions = allWords.slice(0, 4);
  }

  addWordToSuggestions(word: string): void {
    if (!this.suggestedWords.includes(word)) {
      this.suggestedWords.push(word);
      this.updateDisplayedSuggestions();
    }
  }

  removeWordFromSuggestions(word: string): void {
    const index = this.suggestedWords.indexOf(word);
    if (index !== -1) {
      this.suggestedWords.splice(index, 1);
    }
  }

  generateDateOptions(): void {
    this.dateOptions = [
      { label: "Today", value: "today" },
      { label: "Yesterday", value: "yesterday" },
      { label: "Last Week", value: "lastWeek" },
      { label: "Last Month", value: "lastMonth" },
    ];
  }
}
