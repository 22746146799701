import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  public title: string = "";
  public paraph: string = "";
  public proposal: string | null | undefined;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig, private dialogService: DialogService) {
    this.title = this.config.data.title;
    this.paraph = this.config.data.paraph;
    this.proposal = this.config.data.proposal;
  }

  send() {
    this.ref.close(true);
  }

  close() {
    this.ref.close(false);
  }
}
