<p-overlayPanel
  [showTransitionOptions]="'0ms'"
  [hideTransitionOptions]="'0ms'"
  #op
  [dismissable]="true"
  [showCloseIcon]="true"
>
  <div class="info">
    <tr>
      <td>
        <div class="icon-column">
          <i class="fa fa-user-circle fa-5x"></i>
        </div>
      </td>
      <td>
        <div class="text-column">
          <h4>{{ profiles$?.personInfo?.name }}</h4>
          {{ profiles$?.personInfo?.title }}
          {{ profiles$?.personInfo?.location }}
          <br />
        </div>
      </td>
    </tr>
    <br />
    <tr>
      <td>
        <div class="icon-column-small">
          <a [href]="profiles$?.linkedinProfileLink" target="_blank">
            <i class="fab fa-linkedin fa-3x"></i>
          </a>
        </div>
      </td>
      <td>
        <div class="icon-column-small">
          <a [href]="profiles$?.linkedinProfileLink" target="_blank">
            <i class="fa fa-envelope-square fa-3x"></i>
          </a>
        </div>
      </td>
    </tr>
  </div>
  <ng-container
    *ngIf="
      profiles$ && profiles$.personInfo && profiles$.personInfo.about !== null
    "
  >
    <br />
    <p-card header="About">
      <div class="info">
        <p>{{ profiles$!.personInfo!.about }}</p>
      </div>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="profiles$ && profiles$.experience && profiles$.experience.length > 0"
  >
    <br />
    <p-card header="Experience">
      <ul>
        <ng-container *ngFor="let experience of profiles$?.experience">
          <h5>{{ experience.institutionName }}</h5>
          <div class="info">
            <ul *ngFor="let titles of experience.titles">
              <tr>
                <td>
                  <h6>{{ titles.positionTitle }}</h6>
                </td>
                <td>
                  <span class="additional-info"> {{ titles.location }}</span>
                </td>
                <td>
                  <span class="additional-info">
                    {{ titles.fromDate | date : "MMM yyyy" }} -
                    {{ titles.toDate | date : "MMM yyyy" }} /
                    {{ titles.duration }}</span
                  >
                </td>
              </tr>
              <span class="about" *ngIf="titles.about">
                {{ titles.about }}</span
              >
              <ng-container *ngIf="titles.about">
                <br />
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ul>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="profiles$ && profiles$.education && profiles$.education.length > 0"
  >
    <br />
    <p-card header="Education">
      <ng-container *ngFor="let education of profiles$?.education">
        <tr class="info">
          <td>
            <strong>{{ education.institutionName }}</strong>
          </td>
          <td>
            <span class="additional-info"
              >{{ education.fromDate | date : "yyyy" }} -
              {{ education.toDate | date : "yyyy" }}</span
            >
          </td>
        </tr>
        <div class="additional-tab">
          <p *ngIf="education.about" class="additional-info">
            {{ education.about }}
          </p>
          <p *ngIf="education.degree" class="additional-info">
            {{ education.degree }}
          </p>
        </div>
        <br />
      </ng-container>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="
      profiles$ && profiles$.volunteering && profiles$.volunteering.length > 0
    "
  >
    <br />
    <p-card header="Volunteering">
      <ng-container *ngFor="let volunteer of profiles$?.volunteering">
        <tr class="info">
          <td>
            <strong *ngIf="volunteer.title">{{ volunteer.title }}</strong>
          </td>
          <td>
            <span class="additional-info"
              >{{ volunteer.fromDate | date : "MMM yyyy" }} -
              {{ volunteer.toDate | date : "MMM yyyy" }} /
              {{ volunteer.duration }}
            </span>
          </td>
        </tr>
        <div class="additional-tab">
          <span *ngIf="volunteer.mission" class="additional-info">{{
            volunteer.mission
          }}</span>
          <br />
          <span *ngIf="volunteer.about" class="additional-info">{{
            volunteer.about
          }}</span>
        </div>
        <br />
      </ng-container>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="
      profiles$ && profiles$.certification && profiles$.certification.length > 0
    "
  >
    <br />
    <p-card header="Certification">
      <ng-container *ngFor="let certificate of profiles$?.certification">
        <tr class="info">
          <td>
            <strong *ngIf="certificate.institution">{{
              certificate.institution
            }}</strong>
          </td>
          <td>
            <ng-container *ngIf="certificate.certificationCredentialLink">
              <p *ngIf="certificate.title">
                <a [href]="certificate.certificationCredentialLink">{{
                  certificate.title
                }}</a>
              </p>
            </ng-container>
            <ng-container *ngIf="!certificate.certificationCredentialLink">
              <p *ngIf="certificate.title">{{ certificate.title }}</p>
            </ng-container>
          </td>
          <td>
            <span class="additional-info">{{
              certificate.date | date : "MMM yyyy"
            }}</span>
          </td>
        </tr>
        <p class="additional-info">
          {{ certificate.certificationCredentialId }}
        </p>
      </ng-container>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="profiles$ && profiles$.project && profiles$.project.length > 0"
  >
    <br />
    <p-card header="Project">
      <ng-container *ngFor="let project of profiles$?.project">
        <tr class="info">
          <td>
            <strong *ngIf="project.title">{{ project.title }}</strong>
          </td>
          <td>
            <span class="additional-info"
              >{{ project.fromDate | date : "MMM yyyy" }} -
              {{ project.toDate | date : "MMM yyyy" }}</span
            >
          </td>
        </tr>
        <tr>
          <ul *ngFor="let creator of project?.otherProjectCreatorList">
            <p>{{ creator }}</p>
          </ul>
        </tr>
        <div class="additional-tab">
          <p *ngIf="project.about">{{ project.about }}</p>
          <a [href]="project.projectLink"></a>
        </div>
      </ng-container>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="profiles$ && profiles$.course && profiles$.course.length > 0"
  >
    <br />
    <p-card header="Course">
      <ul>
        <ng-container *ngFor="let course of profiles$?.course">
          <strong>{{ course.title }}</strong>
          <p class="additional-info" *ngIf="course.about">{{ course.about }}</p>
        </ng-container>
      </ul>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="
      profiles$ && profiles$.publication && profiles$.publication.length > 0
    "
  >
    <br />
    <p-card header="Publication">
      <ul>
        <ng-container *ngFor="let publication of profiles$?.publication">
          <tr class="info">
            <td>
              <ng-container *ngIf="publication.publicationLink">
                <h6>
                  <a [href]="publication.publicationLink">{{
                    publication.title
                  }}</a>
                </h6>
              </ng-container>
              <ng-container *ngIf="!publication.publicationLink">
                <h6>{{ publication.title }}</h6>
              </ng-container>
            </td>
            <td>
              <p class="additional-info">
                {{ publication.date | date : "MMM yyyy" }}
              </p>
            </td>
          </tr>
          <ng-container class="additional-tab">
            <p *ngIf="publication.institution" class="additional-info">
              {{ publication.institution }}
            </p>
            <p *ngIf="publication.about" class="additional-info">
              {{ publication.about }}
            </p>
          </ng-container>
        </ng-container>
      </ul>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="profiles$ && profiles$.award && profiles$.award.length > 0"
  >
    <br />
    <p-card header="Award">
      <ng-container *ngFor="let award of profiles$?.award">
        <tr class="info">
          <td>
            <h5>{{ award.title }}</h5>
          </td>
          <td>
            <span class="additional-info">{{
              award.date | date : "MMM-yyyy"
            }}</span>
          </td>
        </tr>

        <p *ngIf="award.institution">
          <strong>{{ award.institution }}</strong>
        </p>
        <p class="additional-info" *ngIf="award.about">{{ award.about }}</p>
      </ng-container>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="profiles$ && profiles$.language && profiles$.language.length > 0"
  >
    <br />
    <p-card header="Language">
      <ng-container *ngFor="let language of profiles$?.language">
        <strong> {{ language.name }}</strong>
        <p class="additional-info">{{ language.degree }}</p>
      </ng-container>
    </p-card>
  </ng-container>
  <ng-container
    *ngIf="
      profiles$ &&
      profiles$.recommendation &&
      profiles$.recommendation.length > 0
    "
  >
    <br />
    <p-card header="Recommendation">
      <ng-container *ngFor="let recommend of profiles$?.recommendation">
        <ng-container *ngIf="recommend.recommenderLink">
          <h6>
            <a [href]="recommend.recommenderLink">{{
              recommend.recommender
            }}</a>
          </h6>
        </ng-container>
        <ng-container *ngIf="!recommend.recommenderLink">
          <h6>{{ recommend.recommender }}</h6>
        </ng-container>
        <p class="additional-info" *ngIf="recommend.emphasis">
          {{ recommend.emphasis }}
        </p>
      </ng-container>
    </p-card>
  </ng-container>
  <br />
</p-overlayPanel>
