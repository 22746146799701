<div class="talent-hunt">
  <p>Talent Hunt</p>
  <span>
    Here you can filter what you’re looking for in a candidate and see the list
    of all the results.
  </span>
</div>
<div class="table-container">
  <div class="container-fluid talent-wrapper">
    <div class="p-fluid grid">
      <div class="col-12">
        <div class="card">
          <div class="p-fluid grid">
            <div class="col-5">
              <label for="chipsInput">Keywords</label>
              <p-chips
                [(ngModel)]="values"
                placeholder="Type your keywords and press enter"
                (onRemove)="onValueRemoved($event)"
                (onAdd)="onValueAdded($event)"
                id="chipsInput"
              ></p-chips>
            </div>
            <div class="col-4">
              <label for="multiSelectInput">Country</label>
              <p-multiSelect
                [options]="countries"
                [(ngModel)]="selectedCountries"
                placeholder="Select Country"
                optionLabel="name"
                id="multiSelectInput"
              >
              </p-multiSelect>
            </div>
            <div class="col-1">
              <button
                pButton
                label="Apply"
                (click)="startSearch()"
                tooltipPosition="top"
                class="custom-button p-mr-2"
              ></button>
            </div>
            <div class="col-2">
              <app-previous-hunts
                (filtersApplied)="receiveFilters($event)"
              ></app-previous-hunts>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <p-table
        #dt
        [value]="searches$"
        [rows]="paginationService.searchListPaginationState.rows"
        [first]="paginationService.searchListPaginationState.first"
        [paginator]="true"
        [globalFilterFields]="['name']"
        [(selection)]="selectedSearches"
        [rowHover]="true"
        dataKey="externalId"
        [showCurrentPageReport]="true"
      >
        <ng-template pTemplate="header" class="header">
          <tr class="header-row">
            <td colspan="6">
              <div class="header-content">
                <th pColumn="createdAt" style="width: 10.3%; margin-left: 15px">
                  Date
                </th>
                <th pColumn="parameters" style="width: 39.5%">Keywords</th>
                <th pColumn="userName" style="width: 17.5%">User</th>
                <th pColumn="resultCount" style="width: 15.13%">
                  Found Profile
                </th>
                <th pColumn="searchStatus" style="width: 9.3%">Status</th>
                <th style="width: 7%">Actions</th>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-search let-expanded="expanded">
          <tr>
            <td (click)="viewSearchDetails(search)" style="width: 10%">
              <div class="wrapper" style="padding-left: 15px">
                {{ formatDateString(search.createdAt.toString()) }}
              </div>
            </td>

            <td (click)="viewSearchDetails(search)" style="width: 34%">
              <ng-container>
                <div class="wrapper">
                  <div>
                    <div
                      *ngIf="search.parameters['keyword_list'] !== undefined && search.parameters['keyword_list'] !== ''"
                    >
                      <ng-container
                        *ngFor="let keyword of splitKeywords(search.parameters['keyword_list']); let i = index"
                      >
                        <span
                          class="keyword"
                          *ngIf="(search.isExpandedKeyword || i < 2)"
                          >{{ keyword.trim() }}</span
                        >
                      </ng-container>
                      <button
                        *ngIf="splitKeywords(search.parameters['keyword_list']).length > 2"
                        (click)="toggleExpand(search, 'keyword'); $event.stopPropagation()"
                        class="expand-button"
                      >
                        {{ search.isExpandedKeyword ? '▲' : '▼' }}
                      </button>
                    </div>
                    <div
                      *ngIf="search.parameters['country_list'] !== undefined && search.parameters['country_list'] !== ''"
                    >
                      <ng-container
                        *ngFor="let country of splitKeywords(search.parameters['country_list']); let i = index"
                      >
                        <span
                          class="keyword country"
                          *ngIf="(search.isExpandedCountry || i < 3)"
                          >{{ country.trim() }}</span
                        >
                      </ng-container>
                      <button
                        *ngIf="splitKeywords(search.parameters['country_list']).length > 3"
                        (click)="toggleExpand(search, 'country'); $event.stopPropagation()"
                        class="expand-button"
                      >
                        {{ search.isExpandedCountry ? '▲' : '▼' }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </td>

            <td
              (click)="viewSearchDetails(search)"
              pTooltip="{{ search.userName }}"
              style="width: 15%"
            >
              <div class="wrapper">
                {{ search.firstName + " " + search.lastName }}
              </div>
            </td>
            <td (click)="viewSearchDetails(search)" style="width: 13%">
              <div class="wrapper">
                {{ search.resultCount }}
              </div>
            </td>
            <td
              (click)="viewSearchDetails(search)"
              *ngIf="
              search.searchStatus.toLowerCase() == 'in_progress' ||
              search.searchStatus.toLowerCase() == 'started'
            "
              style="width: 8%"
            >
              <div class="wrapper">
                <img
                  src="../../../assets/images/loaderIcon.svg"
                  alt="Loader Image"
                  class="custom-image"
                />
              </div>
            </td>

            <td
              *ngIf="search.searchStatus.toLowerCase() == 'completed'"
              style="width: 8%"
            >
              <div class="wrapper">
                <img
                  src="../../../assets/images/checkedIcon.svg"
                  alt="Checked Image"
                />
              </div>
            </td>

            <td
              *ngIf="
              search.searchStatus.toLowerCase() != 'in_progress' &&
              search.searchStatus.toLowerCase() != 'started'
            "
              style="width: 6%"
            >
              <div class="wrapper">
                <button
                  class="image-button mr-2"
                  pTooltip="View Profiles"
                  (click)="viewSearchDetails(search)"
                >
                  <img
                    src="../../../assets/images/viewProfilesIcon.svg"
                    alt="View Profiles Image"
                  />
                </button>

                <button
                  class="image-button mr-2"
                  pTooltip="Delete"
                  (click)="deleteSearch(search)"
                >
                  <img
                    src="../../../assets/images/deleteIcon.svg"
                    alt="Delete Image"
                  />
                </button>
              </div>
            </td>
            <td
              *ngIf="search.searchStatus.toLowerCase() != 'completed'"
              style="width: 6%"
            >
              <div class="wrapper"></div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<app-modal
  #confirmationModal
  [isVisible]="confirmationModalVisible"
></app-modal>
