/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseBoolean } from '../models/AmsResponseBoolean';
import type { IpRequest } from '../models/IpRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class IpControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public addIp(
        requestBody: IpRequest,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/ip',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public deleteIp(
        requestBody: IpRequest,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/ip',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
