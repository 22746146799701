<div class="request-dialog">
  <div ngClass="config.data.requestId && !config.data.assignToHrProvider ? edit-providers : create-providers">
    <div class="header">
      <h4>{{title}}</h4>
    </div>
    <div *ngIf="!config.data.requestId || config.data.assignToHrProvider == true">
      <div *ngFor="let provider of providers" class="card-provider">
        <div class="provider">
          <div class="cologn">
            <div class="image">
              <!-- <img src="{{provider.logo}}" /> -->
            </div>
            <div class="provider-information">
              <div class="provider-info">
                {{provider.name}}
              </div>
              <div class="provider-rating">
                <p-rating [(ngModel)]="averageRating" [readonly]="true" [cancel]="false" />
              </div>
              <div class="provider-jobs-done">
                {{provider.companyStarsDto!.length}} Jobs Done
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-2 button-positions">
    <button class="button-confirm mr-2" (click)="send()">Yes</button>
    <button class="button-denial ml-2" (click)="close()">No</button>
  </div>
</div>