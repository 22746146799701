import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import { BudgetDto, CreateNegotiationRequest, NegotiationControllerService, ReceivedProcessDto, StepDto } from 'src/app/oapi_client/data_symphony';
import { CompanyControllerService } from 'src/app/oapi_client/data_symphony/services/CompanyControllerService';
import { SendCounterOfferComponent } from '../negotiation-dialogs/send-counter-offer/send-counter-offer.component';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';

interface ProcessStep {
  name: string;
  details?: string;
}

@Component({
  selector: 'app-received-requests',
  templateUrl: './received-requests.component.html',
  styleUrls: ['./received-requests.component.scss'],
})
export class ReceivedRequestsComponent {
  processSteps: ProcessStep[] = [];
  skills: string[] = [];
  processes: ReceivedProcessDto[] = [];
  filteredProcesses: ReceivedProcessDto[] = [];
  paginatedProcesses: ReceivedProcessDto[] = [];
  selectedProcessId: number | null = null;
  selectedProcess: ReceivedProcessDto | null = null;
  selectedTab: string = 'all';
  page: number = 1;
  totalRecords: number = 0;
  pageSize: number = 5;
  searchText: string = '';
  hrProviderId: number = 37;

  constructor(
    private processManagementService: CompanyControllerService,
    private processUtils: ProcessUtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private negotiationControllerService: NegotiationControllerService
  ) {}

  ngOnInit(): void {
    this.receiveRequests();
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.selectProcess(parseInt(params['id'], 10));
      }
    });
  }

  receiveRequests(): void {
    this.processManagementService.getReceivedRequest().subscribe({
      next: (response) => {
        this.processes = response?.body?.sort((a, b) =>new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()) || [];
        this.filterProcesses('all');
        if (this.selectedProcessId !== null) {
          this.selectedProcess =
            this.processes.find(
              (process) => process.id === this.selectedProcessId
            ) || null;
        }
      },
      error: (error) => {
        console.error('Error fetching received requests:', error);
      },
    });
  }
  
  sendOffer(event: any, processId: number, employerId: number){
    event.stopPropagation();
    const ref = this.dialogService.open(SendCounterOfferComponent,{})

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(res=>{
      if(res){
        const offer: CreateNegotiationRequest={
          employerId: employerId, //to the employer
          processId: processId,
          hrProviderId: this.hrProviderId, // logged hr
          offer: {
            type: BudgetDto.type.EURO,
            amount: res.amount
          },
          offeredById: this.hrProviderId, //logged hr
          note: res.note
        }
        this.negotiationControllerService.createNegotiation(offer).subscribe(()=>{
          const ref = this.dialogService.open(SuccessDialogComponent,{
            data:{title: 'Offer successfully sent!', paraph:'You’ll get notified once the company responds to your offer!'}
          })
      
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
      
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        })
      }
    })
  }

  decline(event: any, processId: number, employerId: number){
    event.stopPropagation();
    const ref = this.dialogService.open(ConfirmationDialogComponent,{
      data:{title: 'Are you sure you want to decline Company XY‘s request?', paraph:'Once you confirm, you can‘t go back.'}
    })

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(res=>{
      if(res){
        this.negotiationControllerService.declineOffer(processId).subscribe((res)=>{
          const ref = this.dialogService.open(SuccessDialogComponent,{
            data:{title: 'Request declined', paraph:'You have declined the request. The Employer will be notified.'}});

          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
      
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        })
      }
    })
  }

  toggleView(): void {
    this.selectedProcess = null;
  }

  filterProcesses(status: string): void {
    this.selectedTab = status;
    this.page = 1;
    let filtered = this.processes;

    if (status !== 'all') {
      filtered = filtered.filter((process) => process.status === status);
    }

    if (this.searchText) {
      filtered = filtered.filter((process) =>
        process.requirement?.name
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    }

    this.filteredProcesses = filtered;
    this.totalRecords = filtered.length;
    this.updatePaginatedProcesses();
  }

  updatePaginatedProcesses(): void {
    const start = (this.page - 1) * this.pageSize;
    const end = this.page * this.pageSize;
    this.paginatedProcesses = this.filteredProcesses.slice(start, end);
  }

  onPageChange(event: any): void {
    this.page = event.page + 1;
    this.updatePaginatedProcesses();
  }

  onSearchChange(event: any): void {
    this.searchText = event.target.value;
    this.filterProcesses(this.selectedTab);
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  formatJobType(jobType: string): string {
    return this.processUtils.formatJobType(jobType);
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    return this.processUtils.formatExperience(min, max);
  }

  formatLanguages(languages: Array<string> | undefined): string {
    return this.processUtils.formatLanguages(languages);
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    return this.processUtils.formatJobTypes(jobTypes);
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    return this.processUtils.formatWorkingTypes(workingTypes);
  }

  setProcessSteps(
    steps: StepDto[] | undefined,
    stepOrder: number[] | undefined
  ): void {
    const orderedSteps = stepOrder?.map((orderId) =>
      steps?.find((step) => step.id === orderId)
    );
    this.processSteps = orderedSteps?.filter(
      (step) => step !== undefined
    ) as ProcessStep[];
  }

  selectProcess(processId: any): void {
    this.selectedProcessId = processId;
    this.selectedProcess =
      this.processes.find((process) => process.id === processId) || null;
    if (this.selectedProcess) {
      this.setProcessSteps(
        this.selectedProcess.steps,
        this.selectedProcess.stepOrder
      );
    }
  }

  navigateToDetails(id: any): void {
    this.router.navigate([`/received-request-details/${id}`]);
  }
}
