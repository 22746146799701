import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { UploadVideoDialogComponent } from './upload-video-dialog/upload-video-dialog.component';
import { ProcessCandidateStatusControllerService } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-process-candidates-table',
  templateUrl: './process-candidates-table.component.html',
  styleUrls: ['./process-candidates-table.component.scss'],
})
export class ProcessCandidatesTableComponent implements OnInit {
  @Input() public candidates: any | undefined;
  @Output() public statusUpdated = new EventEmitter<void>();

  public rows: number = 5;
  public first: number = 0;
  public selectAll: boolean = false;
  public totalRecords: number = 0;
  public allConnectionsChecked: boolean = false;
  public videoAdded: boolean = false;
  public selectCandidate: any;
  public connectionsChecked: any[] = [];
  public showNoteDialog: boolean = false;
  public selectedCandidateIds: number[] = [];

  constructor(
    private dialogService: DialogService,
    private talentService: ProcessCandidateStatusControllerService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges():void{
    this.getVideos();
  }

  getVideos(){
    if(this.candidates.length > 0 && !this.videoAdded){
      this.videoAdded = true;
      for(const element of this.candidates){
        this.talentService.getCandidateVideo(element.id).subscribe(res=>{
          element.video = URL.createObjectURL(res);
        }, error=>{
          console.log('error')
        })
      }
    }
  }

  showNotes(id: number){
    this.showNoteDialog = true;
  }

  checkAllConnections(event: any) {
    this.candidates.map((candidate: { checked: any }) => {
      candidate.checked = event.checked;
    });
    this.connectionsChecked = this.candidates.filter(
      (candidate: { checked: any }) => candidate.checked
    );
  }

  isValidInstitutionName(name: string | null): boolean {
    return name !== null && name?.trim().length > 0;
  }

  generateTooltip(candidate: any): string {
    let tooltipText = '';
    candidate?.profile?.experience?.forEach(
      (experience: { institutionName: string }) => {
        if (experience.institutionName && experience.institutionName.trim()) {
          if (tooltipText) {
            tooltipText += ', ';
          }
          tooltipText += experience.institutionName.trim();
        }
      }
    );
    return tooltipText;
  }

  getVideo(id: number) {
    this.talentService.getCandidateVideo(id).subscribe((res) => {
      return res;
    });
  }

  uploadVideo(candidateName: string, memberId: string, candidateId: number) {
    const ref = this.dialogService.open(UploadVideoDialogComponent, {
      data: {
        name: candidateName,
        memberId: memberId,
        candidateId: candidateId,
      },
    })

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(()=>{
      this.videoAdded = false;
      this.getVideos();
    })
  }

  selectRow(candidate: any) {
    const id = candidate.id;
    if (this.selectedCandidateIds.includes(id)) {
      this.selectedCandidateIds = this.selectedCandidateIds.filter(
        (candidateId) => candidateId !== id
      );
    } else {
      this.selectedCandidateIds.push(id);
    }
  }

  isSelected(item: any): boolean {
    return item === this.selectCandidate;
  }

  onCheckboxChange(event: any, candidate: any) {
    const id = candidate.id;
    if (event.target.checked) {
      this.selectedCandidateIds.push(id);
    } else {
      const index = this.selectedCandidateIds.indexOf(id);
      if (index > -1) {
        this.selectedCandidateIds.splice(index, 1);
      }
    }
  }

  notesUpdate(){
    this.statusUpdated.emit();
  }

  succeedCandidateStatus(id: number) {
    if (!this.selectedCandidateIds.includes(id)) {
      this.selectedCandidateIds.push(id);
    }

    this.talentService
      .succeedCandidateStatus(this.selectedCandidateIds)
      .subscribe({
        next: (response) => {
          console.log('Candidate status updated successfully', response);
          this.statusUpdated.emit();
        },
        error: (error) => {
          console.error('Failed to update candidate status', error);
        },
      });
  }
}
