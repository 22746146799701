/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateDownloadYoutubeJobRequest } from '../models/CreateDownloadYoutubeJobRequest';
import type { CreateYoutubeMetadataRequest } from '../models/CreateYoutubeMetadataRequest';
import type { DSResponseListSpeech2TextDto } from '../models/DSResponseListSpeech2TextDto';
import type { DSResponseListVideoDto } from '../models/DSResponseListVideoDto';
import type { DSResponseSpeech2TextDto } from '../models/DSResponseSpeech2TextDto';
import type { DSResponseString } from '../models/DSResponseString';
import type { DSResponseVideoDto } from '../models/DSResponseVideoDto';
import type { DSResponseVideoSummaryDto } from '../models/DSResponseVideoSummaryDto';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class VideoControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public fetchYoutubeTranscript(
        requestBody: CreateDownloadYoutubeJobRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/video/transcript/youtube',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public fetchYoutubeMetadata(
        requestBody: CreateDownloadYoutubeJobRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/video/metadata/youtube',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public saveYoutubeMetadataContent(
        requestBody: CreateYoutubeMetadataRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/video/metadata/youtube/saveContent',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public youtubeVideoDownload(
        requestBody: CreateDownloadYoutubeJobRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/video/download/youtube',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param videoId
     * @param partName
     * @returns binary OK
     * @throws ApiError
     */
    public getVideoPart(
        videoId: string,
        partName: string,
    ): Observable<Blob> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/youtube/fetchPart/{videoId}/{partName}',
            path: {
                'videoId': videoId,
                'partName': partName,
            },
        });
    }
    /**
     * @param videoId
     * @returns binary OK
     * @throws ApiError
     */
    public getVideo(
        videoId: string,
    ): Observable<Blob> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/youtube/fetch/{videoId}',
            path: {
                'videoId': videoId,
            },
        });
    }
    /**
     * @param jobId
     * @returns DSResponseListVideoDto OK
     * @throws ApiError
     */
    public getVideoTranscript(
        jobId: string,
    ): Observable<DSResponseListVideoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/transcript/youtube/{jobId}',
            path: {
                'jobId': jobId,
            },
        });
    }
    /**
     * @param videoId
     * @returns DSResponseVideoSummaryDto OK
     * @throws ApiError
     */
    public getSummaryByVideoId(
        videoId: string,
    ): Observable<DSResponseVideoSummaryDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/summary/youtube/getByVideoId/{videoId}',
            path: {
                'videoId': videoId,
            },
        });
    }
    /**
     * @param jobId
     * @returns DSResponseListSpeech2TextDto OK
     * @throws ApiError
     */
    public getAllSpeech2Text(
        jobId: string,
    ): Observable<DSResponseListSpeech2TextDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/speech2textList/{jobId}',
            path: {
                'jobId': jobId,
            },
        });
    }
    /**
     * @param videoUniqueId
     * @returns DSResponseSpeech2TextDto OK
     * @throws ApiError
     */
    public getSpeech2Text(
        videoUniqueId: string,
    ): Observable<DSResponseSpeech2TextDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/speech2text/{videoUniqueId}',
            path: {
                'videoUniqueId': videoUniqueId,
            },
        });
    }
    /**
     * @param jobId
     * @returns DSResponseListVideoDto OK
     * @throws ApiError
     */
    public getVideoInfo(
        jobId: string,
    ): Observable<DSResponseListVideoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/metadata/youtube/{jobId}',
            path: {
                'jobId': jobId,
            },
        });
    }
    /**
     * @param videoId
     * @returns DSResponseVideoDto OK
     * @throws ApiError
     */
    public getVideoInfoByVideoId(
        videoId: string,
    ): Observable<DSResponseVideoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/video/metadata/youtube/getByVideoId/{videoId}',
            path: {
                'videoId': videoId,
            },
        });
    }
}
