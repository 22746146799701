<div
  class="card-provider"
  (click)="selectProvider()"
  [ngStyle]="select ? {'border-color': '#4942E5'} : {}"
>
  <div class="provider">
    <div class="cologn">
      <div class="image">
        <!-- <img src="{{provider.logo}}" /> -->
      </div>
      <div class="provider-information">
        <div class="provider-info">
          {{provider.name}}
        </div>
        <div class="provider-rating">
          <p-rating
            [(ngModel)]="averageRating"
            [readonly]="true"
            [cancel]="false"
          />
        </div>
        <div class="provider-jobs-done">
          {{provider.companyStarsDto!.length}} Jobs Done
        </div>
      </div>
    </div>
    <div   (click)="navigateToProviderDetails(provider.id)" class="router-button">
      <button pButton pRipple type="button">
        <img
          src="../../../assets/images/arrowRight.svg"
          class="dropdown-image"
        
        />
      </button>
    </div>
  </div>
</div>
