import { Component, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmDialogOutgoingRequestComponent } from './confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import { CompanyDto, CreateRequirementRequest, StepDto, CountryControllerService, StepControllerService, CreateProcessRequest, ProcessControllerService, UpdateProcessRequest } from 'src/app/oapi_client/data_symphony';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upsert-outgoing-request',
  templateUrl: './upsert-outgoing-request.component.html',
  styleUrls: ['./upsert-outgoing-request.component.scss']
})
export class UpsertOutgoingRequestComponent {

  public myForm: FormGroup;
  @Input() public providers: CompanyDto[] = [];
  @Input() public buttonSplit: boolean = false;
  @Output() public update = new EventEmitter();

  public languagesArray: any[] = [
    'TURKISH', 'ARABIC', 'ENGLISH', 'RUSSIA', 'FRENCH', 'SPANISH', 'BOSNIAN', 'CHINESE', 'HINDI', 'PORTUGUESE', 'JAPANESE', 'GERMAN', 'KOREAN', 'ITALIAN', 'FARSI'
  ];

  public section1Dropdowns: any[] = [
    { label: 'Location', placeholder: 'Select Location', control: 'location', array: [] },
    {
      label: 'Working type', placeholder: 'Select Working Type', control: 'remote', array:
        [{ label: 'Remote', value: "REMOTE" },
        { label: 'On Site', value: "ON_SIDE" },
        { label: 'Hybrid', value: "HYBRID" }]
    },
    {
      label: 'Job type', placeholder: 'Select Job Type', control: 'type', array:
        [{ label: 'Full-time', value: "FULL_TIME" },
        { label: 'Part-Time', value: "PART_TIME" }]
    },
    {
      label: 'Languages', placeholder: 'Select Languages', control: 'languages', array: this.languagesArray.map(language => ({
        label: language,
        value: language
      }))
    }];

  public section3Textareas = [{
    control: 'description', label: 'Description', placeholder: 'Enter Description', optional: false
  },
  { control: 'note', label: 'Add note', placeholder: 'Enter Note', optional: true }];

  public selectedSkills: any;
  public values: string[] = [];
  public countries?: Array<Country>;
  public stepList: any[] = [];
  public requestId: number | null = null;
  public stepName: string = '';
  public filteredStepLists: any[] = [];

  constructor(private fb: FormBuilder,
    private dialogService: DialogService,
    private countryService: CountryControllerService,
    private stepController: StepControllerService,
    private route: ActivatedRoute,
    private processControllerService: ProcessControllerService,
    private router: Router
  ) {

    this.myForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(3)]],
      keywordList: ['', [Validators.required]],
      location: ['', [Validators.required]],
      remote: ['', [Validators.required]],
      type: ['', [Validators.required]],
      languages: ['', [Validators.required]],
      min: [0, Validators.required],
      max: [0, [Validators.required, Validators.min(1)]],
      description: [''],
      note: [''],
      steps: this.fb.array([this.createDropdown()])
    });

    countryService.findCountry('EN').subscribe((response) => {
      if (response.success) {
        this.section1Dropdowns[0].array = response.body?.map((item) => ({
          label: item['name'],
          value: item['name'],
        }));
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.requestId = +params['id'];
      if (this.requestId)
        this.loadData()
    });
    this.getStepList();
    this.steps.valueChanges.subscribe(() => {
      this.updateFilteredStepLists();
    });
  }


  loadData() {
    this.processControllerService.getById1(this.requestId!).subscribe(data => {
      const response = data.body;
      this.providers = response?.hrProviders!;
      this.myForm.patchValue({
        title: response?.requirement?.name,
        keywordList: response?.requirement?.keywordList,
        location: response?.requirement?.location,
        remote: response?.requirement?.workingTypes,
        type: response?.requirement?.jobTypes,
        languages: response?.requirement?.languages,
        min: response?.requirement?.minExperience,
        max: response?.requirement?.maxExperience,
        description: response?.requirement?.description,
        note: response?.note,
      });

      this.steps.clear();
      if (response?.steps?.length) {
        response.steps.forEach(step => {
          this.addDropdown(step.id);
        });
      } else {
        this.addDropdown();
      }
    });
  }

  get steps(): FormArray {
    return this.myForm.get('steps') as FormArray;
  }

  createDropdown(selectedOption: number | null = null): FormGroup {
    return this.fb.group({
      selectedOption: [selectedOption]
    });
  }

  addDropdown(selectedOption: number | null = null) {
    this.steps.push(this.createDropdown(selectedOption));
    this.updateFilteredStepLists();
  }

  removeDropdown(index: number) {
    this.steps.removeAt(index);
    this.updateFilteredStepLists();
  }

  getStepList() {
    this.stepController.getStepList().subscribe(response => {
      if (response.body) {
        this.stepList = response.body?.map((item) => ({
          label: item['name'],
          value: item['id'],
        }));
        this.updateFilteredStepLists();
      }
    })
  }

  updateFilteredStepLists() {
    const selectedOptions = this.steps.controls.map(control => control.get('selectedOption')?.value);

    this.steps.controls.forEach((control, index) => {
      this.filteredStepLists[index] = this.stepList.filter(
        step => !selectedOptions.includes(step.value) || step.value === control.get('selectedOption')?.value
      );
    });
  }

  stop(event: any) {
    event.stopPropagation();
  }

  addStep(event: any) {
    event.preventDefault();
    this.stepController.createStep({ name: event.target.value }).subscribe(() => {
      this.getStepList();
      event.target.value = '';
    })
  }

  onSubmit(): void {
    if (this.myForm.valid) {
      const formValues = this.myForm.value;
      const requirement: CreateRequirementRequest = {
        name: formValues.title,
        keywordList: formValues.keywordList,
        minExperience: Number(formValues.min),
        maxExperience: Number(formValues.max),
        jobTypes: formValues.type,
        location: formValues.location,
        workingTypes: formValues.remote,
        description: formValues.description,
        languages: formValues.languages
      }

      let request: CreateProcessRequest | UpdateProcessRequest;

      if (!this.requestId) {
        request = {
          employerId: 36,
          requirement: requirement,
          hrProviderIds: this.providers.map(provider => provider.id).filter((id): id is number => id !== undefined),
          note: formValues.note,
          stepIds: formValues.steps
          .filter((step: { selectedOption: number | null }) => step.selectedOption !== null)
          .map((step: { selectedOption: number | null }) => step.selectedOption!) || []
        } as CreateProcessRequest;
      } else {
        request = {
          requirement: requirement,
          note: formValues.note,
          stepIds: formValues.steps.map((step: { selectedOption: number; }) => step.selectedOption)
        } as UpdateProcessRequest;
      }

      const ref = this.dialogService.open(ConfirmDialogOutgoingRequestComponent, {
        data: {
          request: request,
          providers: this.providers,
          requestId: this.requestId
        }
      });

      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      ref.onClose.subscribe(() => {
        this.myForm.reset();
        if(this.buttonSplit){
          this.update.emit('');
        }
        else{
          this.router.navigate(['/admin/outgoing-requests']);
        }
      });
    }
  }

  getClassObject(field: string) {
    return {
      'border-invalid': !this.myForm!.get(field)!.valid && this.myForm!.get(field)!.touched,
    };
  }

  onValueRemoved(event: any): void {
    const index = this.selectedSkills.indexOf(event.value);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
    }
  }

  onValueAdded(event: any): void {
    this.values = this.values.map((str) => str.trim().toLowerCase());
    const uniqueSkills = [...new Set(this.values)];
    this.values = uniqueSkills.flat();
  }
}

interface Country {
  code: string;
  name: string;
}