<div class="filter-dropdown" [ngClass]="{ isOpen: isOpen }">
  <button (click)="toggleDropdown()" class="dropdown-button">
    <img src="../../../assets/images/dropdownIcon.svg" class="dropdown-image" />
    Industry & Education
  </button>
  <div *ngIf="isOpen" class="dropdown-container">
    <div class="dropdown-content">
      <div class="dropdown-columns">
        <div class="dropdown-column">
          <label for="multiSelectInput">Industry</label>
          <p-multiSelect
            placeholder="Select industries"
            [options]="industries"
            [(ngModel)]="selectedIndustries"
            id="multiSelectInput"
            optionLabel="name"
            [virtualScroll]="true"
            [scrollable]="true"
            [scrollHeight]="'200px'"
            [lazy]="true"
            [loading]="loading"
            [virtualScrollItemSize]="42"
            (onLazyLoad)="fetchIndustries($event)"
          >
          </p-multiSelect>
          <label for="multiSelectInput">Previous companies</label>
          <p-multiSelect
            placeholder="Select companies"
            id="multiSelectInput"
            [options]="companies"
            [(ngModel)]="selectedCompanies"
            optionLabel="name"
            [virtualScroll]="true"
            [scrollable]="true"
            [scrollHeight]="'200px'"
            [lazy]="true"
            [loading]="loading"
            [virtualScrollItemSize]="42"
            (onLazyLoad)="fetchCompanies($event)"
          >
          </p-multiSelect>
          <label for="multiSelectInput">Languages</label>
          <p-multiSelect
            placeholder="Select languages"
            id="multiSelectInput"
            [options]="languages"
            [(ngModel)]="selectedLanguages"
            optionLabel="name"
            [virtualScroll]="true"
            [scrollable]="true"
            [scrollHeight]="'200px'"
            [lazy]="true"
            [loading]="loading"
            [virtualScrollItemSize]="42"
            (onLazyLoad)="fetchLanguages($event)"
          >
          </p-multiSelect>

          <!-- <label for="multiSelectInput">Gender</label>
          <p-multiSelect
            placeholder="Select gender"
            optionLabel="name"
            id="multiSelectInput"
            
          >
          </p-multiSelect> -->
        </div>
        <div class="dropdown-column">
          <label for="multiSelectInput">Schools</label>
          <p-multiSelect
            placeholder="Select schools"
            id="multiSelectInput"
            [options]="schools"
            [(ngModel)]="selectedSchools"
            optionLabel="name"
            [virtualScroll]="true"
            [scrollable]="true"
            [scrollHeight]="'200px'"
            [lazy]="true"
            [loading]="loading"
            [virtualScrollItemSize]="42"
            (onLazyLoad)="fetchSchools($event)"
          >
          </p-multiSelect>
          <label for="multiSelectInput">Title</label>
          <p-multiSelect
            placeholder="Select titles"
            id="multiSelectInput"
            [options]="titles"
            optionLabel="name"
            [(ngModel)]="selectedTitles"
            [virtualScroll]="true"
            [scrollable]="true"
            [scrollHeight]="'200px'"
            [lazy]="true"
            [loading]="loading"
            [virtualScrollItemSize]="42"
            (onLazyLoad)="fetchTitles($event)"
          >
          </p-multiSelect>
          <label for="multiSelectInput">Degree</label>
          <p-multiSelect
            placeholder="Select degrees"
            id="multiSelectInput"
            [options]="degrees"
            [(ngModel)]="selectedDegrees"
            optionLabel="name"
            [virtualScroll]="true"
            [scrollable]="true"
            [scrollHeight]="'200px'"
            [lazy]="true"
            [loading]="loading"
            [virtualScrollItemSize]="42"
            (onLazyLoad)="fetchDegrees($event)"
          >
          </p-multiSelect>

          <!-- <div
            class="checkbox-container"
            (click)="stopEventPropagation($event)"
          >
            <p>
              <p-checkbox
                label="Contant information available"
                value="Contant information available"
                [(ngModel)]="checkbox1"
                class="checkbox"
              ></p-checkbox>
            </p>

            <p-checkbox
              label="User consent"
              value="User consent"
              [(ngModel)]="checkbox2"
              class="checkbox"
            ></p-checkbox>
          </div> -->
        </div>
      </div>
      <div class="buttons-wrapper">
        <button (click)="clearFilter()">Clear All</button>
        <button
          pButton
          label="Apply"
          type="button"
          tooltipPosition="top"
          (click)="applyFilter()"
          (filterApplied)="filterApplied.emit($event)"
          class="custom-button p-mr-2"
        ></button>
      </div>
    </div>
  </div>
</div>
