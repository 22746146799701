import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-request-success-dialog',
  templateUrl: './request-success-dialog.component.html',
  styleUrls: ['./request-success-dialog.component.scss']
})
export class RequestSuccessDialogComponent implements OnInit {
  public title: string = '';
  public paraph: string = '';
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private route: Router) {
  }
  ngOnInit() {
    this.title = this.config.data.title;
    this.paraph = this.config.data.paraph;
  }

  close() {
    this.ref.close();
  }
}
