/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OfferWithCompanyDto = {
    date?: string;
    id?: number;
    employerId?: number;
    hrProviderId?: number;
    isEmployerAgreed?: boolean;
    isHrAgreed?: boolean;
    note?: string;
    amount?: number;
    moneyType?: OfferWithCompanyDto.moneyType;
    offeredById?: number;
    companyName?: string;
    avgStarCount?: number;
    succeedProcessCount?: number;
};
export namespace OfferWithCompanyDto {
    export enum moneyType {
        DOLLAR = 'DOLLAR',
        EURO = 'EURO',
        TL = 'TL',
    }
}

