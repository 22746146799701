<div class="verification-container">
  <div class="verification-content">
    <div>
      <img src="../../../assets/images/purpleLogo.svg" />
    </div>
    <div class="text-container">
      <img src="../../../assets/images/verifyEmail.svg" />
      <h1>Verify Your Email!</h1>
      <p>We’ve sent verification link to <span>email@email.e</span></p>
      <p>Please check your email to verify this email address.</p>
      <button class="general-button-purple">Resend Link</button>
    </div>
  </div>
</div>
