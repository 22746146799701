/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FilterContactInfoRequest = {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    address?: string;
    twitterUserName?: string;
    phoneNumber?: string;
    pageSize?: number;
    pageIndex?: number;
    sortBy?: string;
    sortDirection?: FilterContactInfoRequest.sortDirection;
};
export namespace FilterContactInfoRequest {
    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }
}

