<div class="filter-dropdown" [ngClass]="{ isOpen: isOpen }">
  <button (click)="toggleDropdown()" class="dropdown-button">
    <img src="../../../assets/images/dropdownIcon.svg" class="dropdown-image" />
    Experience & Location
  </button>
  <div *ngIf="isOpen" class="dropdown-container">
    <div class="dropdown-years">
      <div class="dropdown-content">
        <label for="minYears">Min. years of experience</label>
        <input
          [(ngModel)]="minYearsInput"
          placeholder="0"
          class="custom-input"
          id="minYears"
          type="number"
          min="0"
          max="50"
          (input)="onMinYearsInput($event)"
        />
      </div>
      <div class="dropdown-content">
        <label for="maxYears">Max. years of experience</label>
        <input
          [(ngModel)]="maxYearsInput"
          placeholder="0"
          class="custom-input"
          id="maxYears"
          type="number"
          min="0"
          max="50"
          (input)="onMaxYearsInput($event)"
        />
      </div>
    </div>

    <div class="dropdown-content">
      <label for="chipsInput">Skills</label>
      <p-chips
        class="skills"
        [(ngModel)]="values"
        placeholder="Type your skills and press enter"
        id="chipsInput"
        (onRemove)="onValueRemoved($event)"
        (onAdd)="onValueAdded($event)"
        [suggestions]="displayedSuggestions"
      ></p-chips>
    </div>
    <div class="dropdown-content">
      <label>Suggested</label>
      <div>
        <span *ngFor="let word of displayedSuggestions">
          <button (click)="addWordToSkills($event, word)" class="keyword">
            {{ word }}
          </button>
        </span>
      </div>
    </div>
    <div class="dropdown-content">
      <label for="multiSelectInput">Location</label>
      <p-multiSelect
        placeholder="Select location"
        id="multiSelectInput"
        [options]="locations"
        [(ngModel)]="selectedLocations"
        optionLabel="name"
        [virtualScroll]="true"
        [scrollable]="true"
        [scrollHeight]="'200px'"
        [lazy]="true"
        [loading]="loading"
        [virtualScrollItemSize]="42"
        (onLazyLoad)="loadMoreData($event)"
      >
      </p-multiSelect>
    </div>

    <div class="buttons-wrapper">
      <button (click)="clearFilter()">Clear All</button>
      <button
        pButton
        label="Apply"
        tooltipPosition="top"
        class="custom-button p-mr-2"
        (click)="applyFilter()"
        (filterApplied)="filterApplied.emit($event)"
      ></button>
    </div>
  </div>
</div>
