<div [ngClass]="{ overlay: isVisible }"></div>
<div class="custom-modal" [class.visible]="isVisible">
  <div class="modal-header">
    <h4>{{ header }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer">
    <button (click)="onAccept()" class="modal-accept-button">
      {{ acceptText }}
    </button>
    <button (click)="onCancel()" class="modal-cancel-button">
      {{ cancelText }}
    </button>
  </div>
</div>
