import { Component, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment'; 

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [{ provide: "VERSION", useValue: environment.version }]
})

export class FooterComponent {

  protected version: string;

  constructor(@Inject("VERSION") private VERSION: string) {
    this.version = VERSION;
  }

}
