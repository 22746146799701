/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateCompanyRequest = {
    industry?: string;
    name?: string;
    type?: UpdateCompanyRequest.type;
    address?: string;
    taxNumber?: string;
    phoneNumber?: string;
    size?: string;
    webSiteUrl?: string;
    additional?: string;
    logo?: string;
    email?: string;
};
export namespace UpdateCompanyRequest {
    export enum type {
        EMPLOYER = 'EMPLOYER',
        HR_PROVIDER = 'HR_PROVIDER',
    }
}

