import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  LinkedinControllerService,
  RegisterControllerService,
} from 'src/app/oapi_client/data_symphony';

export const EMPLOYEE_RANGES = [
  { label: 'Self-employed', value: 'SELF_EMPLOYED' },
  { label: '1-10 employees', value: 'EMPLOYEES_1_10' },
  { label: '11-50 employees', value: 'EMPLOYEES_11_50' },
  { label: '51-200 employees', value: 'EMPLOYEES_51_200' },
  { label: '201-500 employees', value: 'EMPLOYEES_201_500' },
  { label: '501-1000 employees', value: 'EMPLOYEES_501_1000' },
  { label: '1001-5000 employees', value: 'EMPLOYEES_1001_5000' },
  { label: '5001-10,000 employees', value: 'EMPLOYEES_5001_10000' },
  { label: '10,001+ employees', value: 'EMPLOYEES_10001_PLUS' },
];

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  displayTOS: boolean = false;
  industries: any[] = [];
  logo: Blob | undefined;
  userType: string = 'EMPLOYER';
  empty: boolean = false;
  pageIndustries: number = 0;
  form: UntypedFormGroup;
  termsAndCondition: boolean = false;
  affirmation: boolean = false;
  termsChecked: boolean = false;
  affirmationChecked: boolean = false;
  logoPreview: string | ArrayBuffer | null = null;
  employeeRanges = EMPLOYEE_RANGES;
  selectedRange: string | undefined;

  selectedUserType: string = 'EMPLOYER';
  userTypes = [
    { label: 'Employer', value: 'EMPLOYER' },
    { label: 'HR Provider', value: 'HR_PROVIDER' },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private registerService: RegisterControllerService,
    private linkedinService: LinkedinControllerService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
      companyInfo: [null, [Validators.required]],
      address: [null, Validators.required],
      phoneNumber: [null],
      taxNumber: [null],
      url: [null],
      selectedIndustries: [null],
      selectedRange: [null],
      additionalInfo: [null],
      userType: [null, Validators.required],
      termsAndCondition: [false],
      affirmation: [false],
    });

    this.form
      .get('termsAndCondition')
      ?.valueChanges.subscribe((value: boolean) => {
        this.termsChecked = value;
      });

    this.form.get('affirmation')?.valueChanges.subscribe((value: boolean) => {
      this.affirmationChecked = value;
    });
  }

  ngOnInit(): void {}

  isPasswordMismatch(): boolean {
    const password = this.form.get('password')?.value;
    const confirmPassword = this.form.get('confirmPassword')?.value;
    return password && confirmPassword && password !== confirmPassword;
  }

  isFieldInvalid(field: string): boolean {
    const control = this.form.get(field);
    return !!(control && control.invalid && control.touched);
  }

  validateNumeric(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  isFieldValid(field: string) {
    return !this.form!.get(field)!.valid && this.form!.get(field)!.touched;
  }

  displayFieldCss(field: string): { [key: string]: boolean } {
    const control = this.form.get(field);
    return {
      'ng-dirty': control?.dirty ?? false,
      'ng-valid': control?.valid && control?.value ? true : false,
      'ng-invalid':
        (control?.invalid && control?.touched) || this.isPasswordMismatch()
          ? true
          : false,
      'has-value': control?.value !== null && control?.value !== '',
      'no-value': control?.value === null || control?.value === '',
    };
  }

  get hasValue() {
    const control = this.form.get('selectedRange');
    return control?.value !== null && control?.value !== '';
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showDialog() {
    this.displayTOS = true;
  }

  fetchIndustries(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinIndustries(this.pageIndustries)
        .subscribe((data) => {
          if (data.body) {
            const newIndustries = data.body.content?.map((industry) => ({
              name: industry.name || '',
            }));

            this.industries = [...this.industries, ...newIndustries!];

            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });

      this.pageIndustries++;
    }
  }

  onCheckboxChange(checkboxName: string) {
    if (checkboxName === 'termsAndCondition') {
      this.termsChecked = !this.termsChecked;
    } else if (checkboxName === 'affirmation') {
      this.affirmationChecked = !this.affirmationChecked;
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.logoPreview = reader.result;
      };

      reader.readAsDataURL(file);
      this.logo = file;
    }
  }

  private convertFormDataToObject(formData: FormData): {
    logo: Blob;
    info: string;
  } {
    const obj: { logo: Blob; info: string } = {
      logo: new Blob(),
      info: '',
    };

    if (formData.has('logo')) {
      const logo = formData.get('logo');
      if (logo instanceof Blob) {
        obj.logo = logo;
      }
    }

    if (formData.has('info')) {
      const info = formData.get('info');
      if (typeof info === 'string') {
        obj.info = info;
      }
    }

    return obj;
  }

  isButtonDisabled(): boolean {
    return (
      !this.form.valid ||
      !this.logo ||
      !this.form.get('termsAndCondition')?.value ||
      !this.form.get('affirmation')?.value
    );
  }

  register() {
    if (this.form.valid && this.logo) {
      const selectedIndustries = this.form.value.selectedIndustries;
      const industry =
        typeof selectedIndustries === 'object' && selectedIndustries !== null
          ? selectedIndustries.name
          : selectedIndustries || '';
      const companyData = {
        industry: industry,
        name: this.form.value.companyInfo || '',
        type: this.form.value.userType || 'EMPLOYER',
        address: this.form.value.address || '',
        taxNumber: this.form.value.taxNumber || '',
        phoneNumber: this.form.value.phoneNumber || '',
        size: this.form.value.selectedRange || '',
        webSiteUrl: this.form.value.url || '',
        additional: this.form.value.additionalInfo || '',
        email: '',
      };

      const userData = {
        email: this.form.value.email || '',
        password: this.form.value.password || '',
        confirmPassword: this.form.value.confirmPassword || '',
      };

      const infoString = JSON.stringify({
        company: companyData,
        user: userData,
      });

      const formData = new FormData();
      formData.append('logo', this.logo);
      formData.append('info', infoString);

      const convertedData = this.convertFormDataToObject(formData);
      this.registerService.createCompanyAndUser(convertedData).subscribe({
        next: (response) => {
          sessionStorage.setItem('userEmail', userData.email);
          this.router.navigate(['/email-verification']);
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Registration failed',
          });
        },
      });
    } else {
      this.validateAllFormFields(this.form);
      this.messageService.add({
        severity: 'warning',
        summary: 'Warning',
        detail: 'Please complete all required fields and upload a logo',
      });
    }
  }
}
