/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateAndUpdateStepTemplateRequest } from '../models/CreateAndUpdateStepTemplateRequest';
import type { DSResponseListStepTemplateDto } from '../models/DSResponseListStepTemplateDto';
import type { DSResponseObject } from '../models/DSResponseObject';
import type { DSResponseStepTemplateDto } from '../models/DSResponseStepTemplateDto';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class StepTemplateControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param id
     * @returns DSResponseStepTemplateDto OK
     * @throws ApiError
     */
    public getById(
        id: number,
    ): Observable<DSResponseStepTemplateDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/stepTemplate/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns DSResponseObject OK
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: CreateAndUpdateStepTemplateRequest,
    ): Observable<DSResponseObject> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/stepTemplate/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns DSResponseObject OK
     * @throws ApiError
     */
    public delete(
        id: number,
    ): Observable<DSResponseObject> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/stepTemplate/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns DSResponseListStepTemplateDto OK
     * @throws ApiError
     */
    public getStepTemplateList(): Observable<DSResponseListStepTemplateDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/stepTemplate/',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseObject OK
     * @throws ApiError
     */
    public createStepTemplate(
        requestBody: CreateAndUpdateStepTemplateRequest,
    ): Observable<DSResponseObject> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/stepTemplate/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
