<div class="dialog">
    <div class="text-center data">
        <h4>{{title}}</h4>
        <p>{{paraph}}</p>
        <span *ngIf="proposal">{{proposal}}</span>
    </div>
    <div class="text-center button-positions">
        <button class="button-confirm mr-2" (click)="send()">Yes</button>
        <button class="button-denial ml-2"(click)="close()">No</button>
    </div>
</div>