/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LinkedinProfileStatisticModel = {
    country_code?: string;
    experience_type?: LinkedinProfileStatisticModel.experience_type;
    profile_count?: number;
    retrieval_time?: string;
};
export namespace LinkedinProfileStatisticModel {
    export enum experience_type {
        ZERO_TWO_YEARS = 'ZERO_TWO_YEARS',
        TWO_FIVE_YEARS = 'TWO_FIVE_YEARS',
        FIVE_EIGHT_YEARS = 'FIVE_EIGHT_YEARS',
        EIGHT_TEN_YEARS = 'EIGHT_TEN_YEARS',
        TEN_PLUS_YEARS = 'TEN_PLUS_YEARS',
        ALL = 'ALL',
    }
}

