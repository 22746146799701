import {
  Component,
  ViewChild,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  DSResponsePageLinkedinProfileDto,
  LinkedinProfilesControllerService,
  CreateLinkedinProfileFilterRequest,
  LinkedinProfileDto,
  ProcessCandidateStatusControllerService,
  CreateProcessCandidateStatusRequest,
} from '../../oapi_client/data_symphony';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/helpers/parameter.service';
import * as Sentry from '@sentry/browser';
import { PatternService } from 'src/app/helpers/pattern.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-talent-discovery-modal',
  templateUrl: './talent-discovery-modal.component.html',
  styleUrls: ['./talent-discovery-modal.component.scss'],
})
export class TalentDiscoveryModalComponent {
  @Output() closeModal = new EventEmitter<void>();
  @Output() peopleAdded = new EventEmitter<void>();
  public processId!: number;
  filter: CreateLinkedinProfileFilterRequest = {};
  @ViewChild('dt') dataTable: Table | undefined;
  loading?: boolean;
  profiles$: LinkedinProfileDto[] = [];
  memberId: string = '';
  dropdownVisible: boolean = false;
  selectedProfiles: { [pageIndex: number]: Set<string> } = {};
  selectAll: boolean = false;
  totalRecords: number | undefined;
  first: number = 0;
  rows: number = 5;
  filtersApplied: boolean = false;
  isLoading: boolean = false;

  constructor(
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    protected paginationService: PaginationService,
    protected parameterService: ParameterService,
    public patternService: PatternService,
    private messageService: MessageService,
    private talentService: ProcessCandidateStatusControllerService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.processId = this.config.data.processId;
  }

  ngOnInit() {
    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.lazyProfileListPaginationState.first;
      this.dataTable.rows =
        this.paginationService.lazyProfileListPaginationState.rows;
      this.dataTable.totalRecords =
        this.paginationService.lazyProfileListPaginationState.totalRecords;
    }
    this.loading = true;
    this.filter = this.parameterService.filterState;
  }

  loadProfiles(event: TableLazyLoadEvent) {
    this.loading = true;
    this.first = event.first || 0;
    this.rows = event.rows || 5;
    setTimeout(() => {
      this.filter.pageIndex = this.first / this.rows;
      this.getOfflineProfiles(this.filter);
      this.isLoading = false;
    }, 10);
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.loadProfiles({ first: this.first, rows: this.rows });
  }

  getOfflineProfiles(filter: CreateLinkedinProfileFilterRequest) {
    this.isLoading = true;
    this.linkedinProfileService.filter1(filter).subscribe(
      (response: DSResponsePageLinkedinProfileDto) => {
        if (response.body === null || !response.body?.content?.length) {
          this.filtersApplied = false;
          this.profiles$ = [];
          this.totalRecords = 0;
          this.isLoading = false;
        } else {
          this.paginationService.lazyProfileListPaginationState.totalRecords =
            response.body?.totalElements || 0;
          if (this.dataTable) {
            this.dataTable.totalRecords =
              this.paginationService.lazyProfileListPaginationState.totalRecords;
          }
          this.totalRecords = response.body?.totalElements;
          this.profiles$ = response.body?.content as LinkedinProfileDto[];
          this.isLoading = false;
          this.checkSelectAll();
        }
      },
      (error: any) => {
        Sentry.captureException('Talent discovery error.', {
          tags: { customTag: 'Talent Discovery' },
          level: 'error',
        });
        this.isLoading = false;
      }
    );
  }

  checkSelectAll() {
    const pageIndex = this.filter.pageIndex ?? 0;
    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    const selectedProfileIds = Array.from(this.selectedProfiles[pageIndex]);
    this.selectAll = selectedProfileIds?.length === this.profiles$?.length;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(
      '.dropdown'
    );
    if (!clickedInsideDropdown) {
      this.dropdownVisible = false;
    }
  }

  applyFilters(filters: any) {
    this.filter = {
      ...this.filter,
      ...filters,
    };
    this.filtersApplied = true;
    this.getOfflineProfiles(this.filter);
  }

  clearFilters() {
    this.filter = {};
    this.filtersApplied = false;
    this.getOfflineProfiles(this.filter);
  }

  generateTooltip(profile: any): string {
    let tooltipText = '';
    profile.experience?.forEach((experience: { institutionName: string }) => {
      if (experience.institutionName && experience.institutionName.trim()) {
        if (tooltipText) {
          tooltipText += ', ';
        }
        tooltipText += experience.institutionName.trim();
      }
    });
    return tooltipText;
  }

  isValidInstitutionName(name: string | null): boolean {
    return name !== null && name?.trim()?.length > 0;
  }

  onClose() {
    this.clearSelections();
    this.ref.close();
  }

  toggleSelectAll(event: any) {
    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }
    this.selectAll = event.target.checked;
    if (this.selectAll) {
      this.profiles$.forEach((profile) => {
        const profileId =
          profile.publicMemberId || profile.publicLinkedinMemberId;
        if (profileId) {
          this.selectedProfiles[pageIndex].add(profileId);
        }
      });
    } else {
      this.selectedProfiles[pageIndex].clear();
    }
  }

  toggleSelect(profile: LinkedinProfileDto | undefined) {
    if (!profile) return;
    const profileId = profile.publicMemberId || profile.publicLinkedinMemberId;
    if (!profileId) return;

    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    if (this.selectedProfiles[pageIndex].has(profileId)) {
      this.selectedProfiles[pageIndex].delete(profileId);
    } else {
      this.selectedProfiles[pageIndex].add(profileId);
    }

    this.checkSelectAll();
  }

  addTalentsToProcessStep() {
    const selectedProfilesArray: string[] = [];

    Object.keys(this.selectedProfiles).forEach((pageIndex) => {
      const profilesSet = this.selectedProfiles[parseInt(pageIndex)];
      profilesSet.forEach((profileId) => {
        selectedProfilesArray.push(profileId);
      });
    });

    if (selectedProfilesArray.length > 0) {
      const requests: CreateProcessCandidateStatusRequest[] =
        selectedProfilesArray.map((profileId) => ({
          processId: this.processId,
          memberId: profileId,
        }));

      this.talentService.createCandidateStatus(requests).subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Members added to the process',
          });
          this.peopleAdded.emit();
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error adding members to process.',
          });
        }
      );
    } else {
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'No profiles selected.',
      });
    }
  }

  clearSelections() {
    this.selectedProfiles = {};
    this.selectAll = false;
    this.closeModal.emit();
  }
}
