/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateNegotiationRequest } from '../models/CreateNegotiationRequest';
import type { DSResponseListNegotiation } from '../models/DSResponseListNegotiation';
import type { DSResponseListOfferWithCompanyDto } from '../models/DSResponseListOfferWithCompanyDto';
import type { DSResponseNegotiation } from '../models/DSResponseNegotiation';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class NegotiationControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param id
     * @returns DSResponseNegotiation OK
     * @throws ApiError
     */
    public declineOffer(
        id: number,
    ): Observable<DSResponseNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/negotiation/offer/decline/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns DSResponseNegotiation OK
     * @throws ApiError
     */
    public approveByHrProvider(
        id: number,
    ): Observable<DSResponseNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/negotiation/offer/approveByHrProvider/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseNegotiation OK
     * @throws ApiError
     */
    public createNegotiation(
        requestBody: CreateNegotiationRequest,
    ): Observable<DSResponseNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/negotiation/offer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processId
     * @returns DSResponseListNegotiation OK
     * @throws ApiError
     */
    public getAllByProcessId(
        processId: number,
    ): Observable<DSResponseListNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/negotiation/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
    /**
     * @param processId
     * @returns DSResponseListNegotiation OK
     * @throws ApiError
     */
    public getAllByHrCompanyId(
        processId: number,
    ): Observable<DSResponseListNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/negotiation/received/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
    /**
     * @param processId
     * @returns DSResponseListOfferWithCompanyDto OK
     * @throws ApiError
     */
    public getAllOfferWithCompany(
        processId: number,
    ): Observable<DSResponseListOfferWithCompanyDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/negotiation/getAllOfferWithCompany/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
}
