<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="talent-hunt" *ngIf="!isLoading">
  <div class="user-info">
    <div>
      <div class="name">
        <p>
          {{ groupName }}
        </p>
        <div>
          <button
            class="image-button mr-2"
            pTooltip="Edit"
            (click)="editCandidatePool(groupId)"
          >
            <img src="../../../assets/images/edit.svg" alt="Edit Image" />
          </button>

          <button class="image-button mr-2" pTooltip="Delete">
            <img
              src="../../../assets/images/deleteIcon.svg"
              alt="Delete Image"
              (click)="deleteGroup(groupId)"
            />
          </button>
        </div>
      </div>
      <div *ngIf="keyword" class="keyword-container">
        <ng-container *ngFor="let keyword of splitKeywords(keyword)">
          <span *ngIf="keyword" class="tag">{{ keyword }}</span>
        </ng-container>
      </div>
    </div>
    <div class="button-container">
      <button
        class="image-button mr-2"
        pTooltip="If you want to add more people into this Candidate Pool, you can do so by clicking this button"
      >
        <img
          src="../../../assets/images/info.svg"
          alt="info icon"
          class="info-icon"
        />
      </button>
      <button class="custom-button" (click)="navigateToTalentDiscovery()">
        Talent Discovery
      </button>
    </div>
  </div>
</div>

<div class="table-container" *ngIf="!isLoading">
  <div>
    <div class="description">{{ description }}</div>

    <div class="card talent-wrapper">
      <div class="empty-card" *ngIf="profiles$.length === 0">
        <img src="../../../assets/images/addMember.svg" alt="add member" />
        <p>Add people to your pool!</p>
        <span
          >Seems like you didn’t add any talents to this Candidate pool. Click
          the button to start.</span
        >
        <div class="user-info">
          <button class="custom-button" (click)="navigateToTalentDiscovery()">
            Talent Discovery
          </button>
        </div>
      </div>

      <div *ngIf="profiles$.length > 0">
        <div class="col-12 filter-wrapper">
          <div class="col-5">
            <input class="search-input" pInputText placeholder="Search..." />
          </div>
          <div class="col-6 modal-wrapper">
            <app-experience-location-modal
              style="display: flex; align-items: center;"
              (filterApplied)="applyFilters($event)"
            ></app-experience-location-modal>

            <div class="dropdown">
              <img
                src="../../../assets/images/dots.svg"
                alt="dots"
                (click)="toggleDropdown()"
              />
              <div *ngIf="dropdownVisible" class="dropdown-content">
                <label class="dropdown-item">
                  <p (click)="exportToCSV()">Export To CSV</p>
                </label>
                <!-- <label class="dropdown-item"> -->
                <!-- <input
              type="file"
              (change)="importFromCSV($event)"
              style="display: none;"
            /> -->

                <!-- <p (click)="importFromCSV($event)">Import From CSV</p> -->
                <!-- </label> -->
              </div>
            </div>
          </div>
        </div>

        <p-table
          #dt
          [value]="profiles$"
          [lazy]="true"
          (onLazyLoad)="loadProfiles($event)"
          [rows]="paginationService.lazyProfileListPaginationState.rows"
          [first]="paginationService.lazyProfileListPaginationState.first"
          [paginator]="true"
          [rowHover]="true"
          dataKey="publicMemberId"
          [paginatorPosition]="'bottom'"
        >
          <ng-template pTemplate="header" class="header">
            <tr>
              <th colspan="5" class="header-buttons">
                <div class="button-wrapper">
                  <div>
                    <th style="display: flex; align-items: center">
                      <label class="custom-checkbox-label">
                        <input
                          type="checkbox"
                          [checked]="selectAll"
                          (change)="toggleSelectAll($event)"
                        />
                        <span class="custom-checkbox-icon"></span>
                      </label>
                      <span style="padding-left: 5px;">Select All</span>
                    </th>
                  </div>
                </div>
              </th>
            </tr>
            <tr class="header-row">
              <td colspan="5">
                <div class="header-content">
                  <th
                    pColumn="person_info.name"
                    style="width: 20.7%; margin-left: 25px"
                  >
                    Name
                  </th>

                  <th pColumn="person_info.headline" style="width: 40.3%">
                    Title
                  </th>
                  <th pColumn="person_info.experience" style="width: 28.4%">
                    Experiences
                  </th>
                  <th style="width: 5%">Actions</th>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-profile let-expanded="expanded">
            <tr>
              <td style="width: 22.6%">
                <div class="wrapper" style="padding-left: 1rem;width: 260px;">
                  <label class="custom-checkbox-label">
                    <input
                      type="checkbox"
                      [checked]="selectedProfiles[filter.pageIndex ?? 0]?.has(profile.linkedinProfileLink)"
                      (change)="toggleSelect(profile.linkedinProfileLink)"
                      class="custom-checkbox"
                    />
                    <span class="custom-checkbox-icon"></span>
                  </label>
                  <span
                    class="ellipsis-description"
                    [pTooltip]="profile.personInfo.name"
                    (click)="viewProfileDetails(profile)"
                    style="padding-left: 5px; width: 172px;"
                  >
                    {{ profile.personInfo.name }}
                  </span>
                </div>
              </td>

              <td (click)="viewProfileDetails(profile)" style="width: 49.7%">
                <div class="wrapper" [pTooltip]="profile?.personInfo?.headline">
                  <div
                    class="ellipsis-description"
                    style="max-width: 440px; margin-right: 5px;"
                    *ngIf="profile?.personInfo?.headline !== 'Null'"
                  >
                    {{ profile?.personInfo?.headline }}
                  </div>
                </div>
              </td>
              <td (click)="viewProfileDetails(profile)" style="width: 28.43%">
                <div
                  class="wrapper"
                  [pTooltip]="generateTooltip(profile)"
                  style="width: 319px;"
                >
                  <div
                    class="ellipsis-description"
                    style="max-width: 305px; margin-right: 5px;"
                  >
                    <ng-container
                      *ngFor="let experience of profile?.experience; let last = last"
                    >
                      <span
                        *ngIf="!last && isValidInstitutionName(experience.institutionName)"
                        >{{ experience.institutionName }},
                      </span>
                      <span *ngIf="last">{{ experience.institutionName }}</span>
                    </ng-container>
                  </div>
                </div>
              </td>
              <td style="min-width: 91px;">
                <div class="flex wrapper">
                  <a [href]="profile?.linkedinProfileLink" target="_blank">
                    <button class="image-button mr-2" pTooltip="LinkedIn">
                      <img
                        src="../../../assets/images/linkedinIcon.svg"
                        alt="linkedin Icon"
                      />
                    </button>
                  </a>

                  <button class="image-button mr-2" pTooltip="Delete">
                    <img
                      src="../../../assets/images/deleteIcon.svg"
                      alt="Delete icon"
                      (click)="deleteGroupMember(profile)"
                    />
                  </button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<app-modal
  #confirmationModal
  [isVisible]="confirmationModalVisible"
></app-modal>
<app-modal #memberModal [isVisible]="memberModalVisible"></app-modal>
