import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private groupName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private personName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private source: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setGroupName(name: string): void {
    this.groupName.next(name);
  }

  getGroupName(): Observable<string> {
    return this.groupName.asObservable();
  }

  setPersonName(name: string): void {
    this.personName.next(name);
  }

  getPersonName(): Observable<string> {
    return this.personName.asObservable();
  }

  setSource(source: string): void {
    this.source.next(source);
  }

  getSource(): Observable<string> {
    return this.source.asObservable();
  }
}
