import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import {
  CompanyControllerService,
  OutgoingProcessDto,
  ProcessControllerService,
} from 'src/app/oapi_client/data_symphony';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';

@Component({
  selector: 'app-outgoing-request',
  templateUrl: './outgoing-request.component.html',
  styleUrls: ['./outgoing-request.component.scss'],
  providers: [DatePipe],
})
export class OutgoingRequestComponent {
  processes: OutgoingProcessDto[] = [];
  filteredProcesses: OutgoingProcessDto[] = [];
  selectedTab: string = 'all';
  page: number = 1;
  totalRecords: number = 0;
  pageSize: number = 5;
  searchText: string = '';

  constructor(
    private processManagementService: CompanyControllerService,
    private router: Router,
    private processUtils: ProcessUtilsService,
    private dialogService: DialogService,
    private processService: ProcessControllerService
  ) { }

  ngOnInit(): void {
    this.getRequests();
  }

  getRequests(): void {
    this.processManagementService.getOutgoingRequest().subscribe({
      next: (response) => {
        this.processes = response?.body!.sort((a, b) =>new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()) || [];
        this.filterProcesses('all');
      },
      error: (error) => {
        console.error('Error fetching outgoing requests:', error);
      },
    });
  }

  filterProcesses(status: string): void {
    this.selectedTab = status;
    this.page = 1;
    let filtered = this.processes;

    if (status !== 'all') {
      filtered = filtered.filter((process) => process.status === status);
    }

    if (this.searchText) {
      filtered = filtered.filter((process) =>
        process.requirement?.name
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    }

    this.totalRecords = filtered.length;
    this.filteredProcesses = filtered.slice(0, this.pageSize);
  }

  onPageChange(event: any): void {
    this.page = event.page + 1;
    this.updateFilteredProcesses();
  }

  onSearchChange(event: any): void {
    this.searchText = event.target.value;
    this.filterProcesses(this.selectedTab);
  }

  deleteProcess(event: any, processId: number, processName: string) {
    event.stopPropagation();
    this.dialogService.open(ConfirmationDialogComponent, {
      data:
        { title: `Are you sure that you want to delete ${processName} request?`,
          paraph: 'Once you confirm you can’t go back.'
      }
        
    }).onClose.subscribe(res => {
      if (res) {
        this.processService.deleteProcess(processId).subscribe(res => {
          this.dialogService.open(SuccessDialogComponent, { data: { title: 'success' } }).onClose.subscribe(() => {
            this.getRequests();
          });
        })
      }
    });
  }

  updateFilteredProcesses(): void {
    const start = (this.page - 1) * this.pageSize;
    const end = this.page * this.pageSize;
    const filtered = this.processes.filter((process) => {
      const matchesStatus =
        this.selectedTab === 'all' || process.status === this.selectedTab;
      const matchesSearch = process.requirement?.name
        ?.toLowerCase()
        .includes(this.searchText.toLowerCase());
      return matchesStatus && matchesSearch;
    });

    this.totalRecords = filtered.length;
    this.filteredProcesses = filtered.slice(start, end);
  }

  navigateToDetails(id: any): void {
    this.router.navigate([`/outgoing-request-details/${id}`]);
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  getHrProviderNames(process: OutgoingProcessDto): string {
    if (process.status === 'SUCCEED' || process.status === 'IN_PROGRESS') {
      return process.acceptedHRProvider?.name || 'N/A';
    } else {
      return (
        process.hrProviders?.map((provider) => provider.name).join(', ') || ''
      );
    }
  }
}
