import { DatePipe } from '@angular/common';
import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import {
  DSResponsePageLinkedinProfileDto,
  LinkedinProfilesControllerService,
  CreateLinkedinProfileFilterRequest,
  LinkedinProfileDto,
  GroupControllerService,
  DSResponseListGroupModelDto,
  GroupModelDto,
} from '../../oapi_client/data_symphony';
import { ActivatedRoute, Router } from '@angular/router';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { MenuItem, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/helpers/parameter.service';
import * as Sentry from '@sentry/browser';
import { PatternService } from 'src/app/helpers/pattern.service';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { UpsertCandidateModalComponent } from '../candidate-pool/upsert-candidate-modal/upsert-candidate-modal.component';

@Component({
  selector: 'app-offline-profile-list',
  templateUrl: './offline-profile-list.component.html',
  styleUrls: ['./offline-profile-list.component.scss'],
  providers: [DatePipe],
})
export class OfflineProfileListComponent implements OnInit {
  filter: CreateLinkedinProfileFilterRequest = {};
  dateFormatted: string = '';
  @ViewChild('dt') dataTable: Table | undefined;
  loading?: boolean;
  profiles$: LinkedinProfileDto[] = [];
  items: MenuItem[] = [];
  memberId: string = '';
  groups$: GroupModelDto[] = [];
  dropdownVisible: boolean = false;
  selectedProfiles: { [pageIndex: number]: Set<string> } = {};
  selectAll: boolean = false;
  groupName: string | null = null;
  groupId: number = 0;
  totalRecords: number | undefined;

  constructor(
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    private router: Router,
    private datePipe: DatePipe,
    protected paginationService: PaginationService,
    protected parameterService: ParameterService,
    public patternService: PatternService,
    private readonly dialogService: DialogService,
    private groupService: GroupControllerService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['groupName']) {
        this.groupName = `Add to ${params['groupName']}` || null;
      }
      this.groupId = +params['groupId'] || 0;
    });
    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.lazyProfileListPaginationState.first;
      this.dataTable.rows =
        this.paginationService.lazyProfileListPaginationState.rows;
      this.dataTable.totalRecords =
        this.paginationService.lazyProfileListPaginationState.totalRecords;
    }
    this.loading = true;
    this.filter = this.parameterService.filterState;
    this.items = [
      { label: 'Talent Discovery', url: 'admin/talent-discovery' },
      { label: 'View Profiles', url: '' },
    ];

    this.getGroups();
  }

  loadProfiles(event: TableLazyLoadEvent) {
    this.loading = true;
    setTimeout(() => {
      this.filter.pageIndex = (event?.first || 0) / event?.rows!;
      this.getOfflineProfiles(this.filter);
    }, 10);
  }

  getGroups() {
    this.groupService.listMember;
    this.groupService.getAll1().subscribe(
      (response: DSResponseListGroupModelDto) => {
        this.groups$ = response.body || [];
        this.paginationService.lazyProfileListPaginationState.totalRecords = 0;
        if (this.dataTable) {
          this.dataTable.totalRecords =
            this.paginationService.lazyProfileListPaginationState.totalRecords;
        }
        this.loading = false;
      },
      (error: any) => {
        alert(error);
      }
    );
  }

  getOfflineProfiles(filter: CreateLinkedinProfileFilterRequest) {
    this.linkedinProfileService.filter1(filter).subscribe(
      (response: DSResponsePageLinkedinProfileDto) => {
        this.paginationService.lazyProfileListPaginationState.totalRecords =
          response.body?.totalElements || 0;
        if (this.dataTable) {
          this.dataTable.totalRecords =
            this.paginationService.lazyProfileListPaginationState.totalRecords;
        }
        this.totalRecords = response.body?.totalElements;
        this.profiles$ = response.body?.content as LinkedinProfileDto[];
        this.loading = false;
        this.checkSelectAll();
      },
      (error: any) => {
        Sentry.captureException('Offline filter service error.', {
          tags: { customTag: 'Offline Profile List' },
          level: 'error',
        });
        this.loading = false;
      }
    );
  }

  checkSelectAll() {
    const pageIndex = this.filter.pageIndex ?? 0;
    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    const selectedProfileIds = Array.from(this.selectedProfiles[pageIndex]);
    this.selectAll = selectedProfileIds?.length === this.profiles$?.length;
  }

  toggleSelect(profile: LinkedinProfileDto | undefined) {
    if (!profile) return;
    const profileId = profile.publicMemberId || profile.publicLinkedinMemberId;
    if (!profileId) return;

    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    if (this.selectedProfiles[pageIndex].has(profileId)) {
      this.selectedProfiles[pageIndex].delete(profileId);
    } else {
      this.selectedProfiles[pageIndex].add(profileId);
    }

    this.checkSelectAll();
  }

  toggleSelectAll(event: any) {
    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }
    this.selectAll = event.target.checked;
    if (this.selectAll) {
      this.profiles$.forEach((profile) => {
        const profileId =
          profile.publicMemberId || profile.publicLinkedinMemberId;
        if (profileId) {
          this.selectedProfiles[pageIndex].add(profileId);
        }
      });
    } else {
      this.selectedProfiles[pageIndex].clear();
    }
  }

  addMembersToGroup(groupId: any) {
    if (groupId) {
      const selectedProfilesArray: string[] = [];
      Object.keys(this.selectedProfiles).forEach((pageIndex) => {
        const pageIndexNumber = parseInt(pageIndex);
        if (!isNaN(pageIndexNumber)) {
          const profilesSet = this.selectedProfiles[pageIndexNumber];
          profilesSet.forEach((profileId) => {
            selectedProfilesArray.push(profileId);
          });
        }
      });

      if (selectedProfilesArray.length > 0) {
        this.groupService.addMember(groupId, selectedProfilesArray).subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Members added to the group',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error adding members to group.',
            });
          }
        );
      } else {
        this.messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: 'No profiles selected.',
        });
      }
    } else {
      console.warn('Invalid groupId.');
    }
    this.toggleDropdown();
  }

  addSingleProfileToGroup(profile: LinkedinProfileDto): void {
    const profileId = profile.publicMemberId || profile.publicLinkedinMemberId;
    if (this.groupId && profileId) {
      this.groupService.addMember(this.groupId, [profileId]).subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `${profile?.personInfo?.name} successfully added to group.`,
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error adding member to group.',
          });
        }
      );
    } else {
      console.warn('Invalid groupId or profileId.');
    }
  }

  toggleDropdown() {
    if (this.groups$.length === 0) {
      const ref = this.dialogService.open(UpsertCandidateModalComponent, {});
      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      return ref;
    } else {
      this.dropdownVisible = !this.dropdownVisible;
      return;
    }
  }

  openDialog() {
    const ref = this.dialogService.open(UpsertCandidateModalComponent, {});
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
    return ref;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(
      '.dropdown'
    );
    if (!clickedInsideDropdown) {
      this.dropdownVisible = false;
    }
  }

  applyFilters(filters: any) {
    this.filter = {
      ...this.filter,
      ...filters,
    };
    this.getOfflineProfiles(this.filter);
  }

  clearFilters() {
    this.filter = {};
    this.getOfflineProfiles(this.filter);
  }

  formatDateString(dateString: string): string {
    try {
      this.dateFormatted = <string>(
        this.datePipe.transform(dateString, 'dd/MM/yyyy HH:mm')
      );
    } catch (ex: any) {
      this.dateFormatted = dateString;
      Sentry.captureException('Date time convert error.' + this.dateFormatted, {
        tags: { customTag: 'Offline Profile List' },
        level: 'error',
      });
    }
    return this.dateFormatted;
  }

  viewProfileDetails(selectedSearch: any) {
    this.paginationService.lazyProfileListPaginationState = {
      first: this.dataTable?.first,
      rows: this.dataTable?.rows,
      totalRecords: this.dataTable?.totalRecords || 0,
    };
    if (
      selectedSearch &&
      (selectedSearch.publicMemberId || selectedSearch.publicLinkedinMemberId)
    ) {
      const memberId =
        selectedSearch.publicMemberId || selectedSearch.publicLinkedinMemberId;
      this.router.navigate(['/profile', memberId], {
        queryParams: { source: 'talent-discovery' },
      });
    }
  }

  backToFilterPage() {
    this.router.navigateByUrl('/admin/talent-discovery');
  }

  generateTooltip(profile: any): string {
    let tooltipText = '';
    profile.experience?.forEach((experience: { institutionName: string }) => {
      if (experience.institutionName && experience.institutionName.trim()) {
        if (tooltipText) {
          tooltipText += ', ';
        }
        tooltipText += experience.institutionName.trim();
      }
    });
    return tooltipText;
  }

  isValidInstitutionName(name: string | null): boolean {
    return name !== null && name?.trim().length > 0;
  }
}
