<div class="login-container">
  <div class="login-content">
    <p-toast></p-toast>
    <div>
      <img src="../../../assets/images/purpleLogo.svg" />
      <h3>Create Your Account</h3>
      <p>Please enter the information to sign up.</p>
    </div>
    <div class="form">
      <form [formGroup]="form" class="form-container">
        <div class="role">
          <label for="userType">I want to register as:</label>
          <p-dropdown
            id="userType"
            [options]="userTypes"
            formControlName="userType"
            [(ngModel)]="selectedUserType"
            class="custom-dropdown"
          >
          </p-dropdown>
       
        </div>
        <div class="form-side">
          <div class="left-side-form">
            <h3>Company Information</h3>
            <div class="input-wrapper">
              <label for="companyInfo"><sup>*</sup>Company name</label>
              <input
                placeholder="Company name"
                id="companyInfo"
                class="p-inputtext-lg rounded form-control"
                formControlName="companyInfo"
                pInputText
                [ngClass]="displayFieldCss('companyInfo')"
              />
              <div *ngIf="isFieldInvalid('companyInfo')">
                <small class="error-message">
                  <img
                    src="../../../assets/images/error.svg"
                    alt="error"
                  />Please enter company name.</small
                >
              </div>
            </div>

            <div class="input-wrapper">
              <label for="dropdownInput">Industry</label>
              <p-dropdown
                id="dropdownInput"
                placeholder="Industry"
                optionLabel="name"
                [options]="industries"
                formControlName="selectedIndustries"
                [virtualScroll]="true"
                [lazy]="true"
                [scrollHeight]="'200px'"
                [virtualScrollItemSize]="33"
                (onLazyLoad)="fetchIndustries($event)"
              ></p-dropdown>
            </div>

            <div class="input-wrapper">
              <label for="address"><sup>*</sup>Address</label>
              <input
                id="address"
                placeholder="Address"
                class="p-inputtext-lg rounded form-control"
                formControlName="address"
                pInputText
                [ngClass]="displayFieldCss('address')"
              />
              <div *ngIf="isFieldInvalid('address')">
                <small class="error-message">
                  <img
                    src="../../../assets/images/error.svg"
                    alt="error"
                  />Please enter address.</small
                >
              </div>
            </div>
            <div class="input-wrapper">
              <label for="taxNumber">Tax number</label>
              <input
                id="taxNumber"
                placeholder="Tax number"
                class="p-inputtext-lg rounded"
                formControlName="taxNumber"
                pInputText
                (input)="validateNumeric($event)"
                [ngClass]="displayFieldCss('taxNumber')"
              />
            </div>

            <div class="input-wrapper">
              <label for="phoneNumber">Phone number</label>
              <input
                id="phoneNumber"
                placeholder="Phone number"
                class="p-inputtext-lg rounded"
                formControlName="phoneNumber"
                pInputText
                (input)="validateNumeric($event)"
                [ngClass]="displayFieldCss('phoneNumber')"
              />
            </div>

            <div class="input-wrapper">
              <label for="dropdownInput">Company size</label>
              <p-dropdown
                id="dropdownInput"
                placeholder="Company size"
                optionLabel="label"
                [options]="employeeRanges"
                formControlName="selectedRange"
                [virtualScroll]="true"
                [lazy]="true"
                [scrollHeight]="'200px'"
                [virtualScrollItemSize]="30"
                optionValue="value"
              ></p-dropdown>
            </div>

            <label for="url">Website URL</label>
            <input
              id="url"
              placeholder="Website URL"
              class="p-inputtext-lg rounded"
              formControlName="url"
              pInputText
              [ngClass]="displayFieldCss('url')"
            />
          </div>

          <div class="right-side-form">
            <h3>Account Information</h3>
            <div class="input-wrapper">
              <label for="email"><sup>*</sup>Email</label>
              <input
                id="email"
                placeholder="Email"
                class="p-inputtext-lg rounded form-control"
                formControlName="email"
                pInputText
                [ngClass]="displayFieldCss('email')"
              />
              <div *ngIf="isFieldInvalid('email')">
                <small class="error-message">
                  <img
                    src="../../../assets/images/error.svg"
                    alt="error"
                  />Please enter a valid email address.</small
                >
              </div>
            </div>

            <div class="input-wrapper">
              <label for="password"><sup>*</sup>Password</label>
              <input
                id="password"
                placeholder="••••••••"
                class="p-inputtext-lg rounded form-control"
                type="password"
                formControlName="password"
                pInputText
                [ngClass]="displayFieldCss('password')"
              />
              <div *ngIf="isFieldInvalid('password')" class="error-message">
                <img src="../../../assets/images/error.svg" alt="error" />
                Please enter password.
              </div>
            </div>

            <div class="input-wrapper">
              <label for="confirmPassword"><sup>*</sup>Confirm password</label>
              <input
                id="confirmPassword"
                placeholder="••••••••"
                class="p-inputtext-lg rounded form-control"
                type="password"
                formControlName="confirmPassword"
                pInputText
                [ngClass]="displayFieldCss('confirmPassword')"
              />
              <div
                *ngIf="isFieldInvalid('confirmPassword')"
                class="error-message"
              >
                <img src="../../../assets/images/error.svg" alt="error" />
                Please enter confirm password.
              </div>

              <div
                *ngIf="isPasswordMismatch() && form.get('password')?.value && form.get('confirmPassword')?.value"
                class="error-message"
              >
                <img src="../../../assets/images/error.svg" alt="error" />
                Passwords do not match.
              </div>
            </div>

            <div class="upload-wrapper">
              <div *ngIf="!logoPreview">
                <p><strong>Upload your logo</strong></p>
                <p>(.png, .jpg, .svg)</p>
                <p><span>Maximum upload size: 20MB</span></p>
              </div>

              <div *ngIf="logoPreview" class="logo-preview">
                <img [src]="logoPreview" alt="Uploaded Logo" />
              </div>

              <button
                [ngClass]="{ 'button-purple': !logoPreview, 'button-change': logoPreview }"
                (click)="fileInput.click()"
              >
                {{ logoPreview ? 'Change logo' : 'Upload' }}
              </button>

              <input
                #fileInput
                type="file"
                (change)="onFileSelected($event)"
                accept=".png, .jpg, .svg"
                style="display:none"
              />
            </div>

            <label for="additionalInfo">Additional information</label>
            <textarea
              id="additionalInfo"
              placeholder="Enter additional information..."
              class="p-inputtext-lg rounded info form-control"
              formControlName="additionalInfo"
              rows="4"
              [ngClass]="displayFieldCss('additionalInfo')"
            ></textarea>
          </div>
        </div>
        <div class="checkbox">
          <div>
            <p-checkbox
              [binary]="true"
              formControlName="termsAndCondition"
            ></p-checkbox>
            <label>
              <strong [ngClass]="{'highlighted-text': termsChecked}"
                >I agree with</strong
              >
              <a href="/terms-and-conditions"
                ><strong>Terms and Conditions</strong></a
              >
            </label>
          </div>
          <div>
            <p-checkbox
              [binary]="true"
              formControlName="privacyPolicy"
            ></p-checkbox>
            <label>
              <strong [ngClass]="{'highlighted-text': privacyChecked}">
                I agree with the</strong
              >
              <a href="/privacy-policy"><strong>Privacy Policy</strong></a>
            </label>
          </div>
        </div>
        <button
          pButton
          pRipple
          type="submit"
          class="button-purple"
          (click)="register()"
          [disabled]="isButtonDisabled()"
          [ngClass]="{'button-purple': !isButtonDisabled(), 'disabled-btn': isButtonDisabled()}"
        >
          Sign up
        </button>
      </form>
      <p class="link">
        <strong>
          Already have an account? <a href="/landing/login">Sign in</a></strong
        >
      </p>
    </div>
  </div>
</div>
