import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableProdMode } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from './environments/environment';
import { Debug } from '@sentry/integrations';

Sentry.init({
  dsn: environment.sentryDSN,
  integrations: [
    new Sentry.Replay(),
    new Debug(),
  ],

  debug: true,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));