/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateWebContentRequest } from '../models/CreateWebContentRequest';
import type { DSResponseString } from '../models/DSResponseString';
import type { DSResponseWebContentModel } from '../models/DSResponseWebContentModel';
import type { LinkedinPostRequest } from '../models/LinkedinPostRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class WebContentControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public creteLinkedinPost(
        requestBody: LinkedinPostRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/webContent/post',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public creteWebContent(
        requestBody: CreateWebContentRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/webContent/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uniqueId
     * @param type
     * @returns DSResponseWebContentModel OK
     * @throws ApiError
     */
    public getWebContentByUrl(
        uniqueId: string,
        type: string,
    ): Observable<DSResponseWebContentModel> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/webContent',
            query: {
                'uniqueId': uniqueId,
                'type': type,
            },
        });
    }
}
