<div *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  

<div class="talent-hunt" *ngIf="!isLoading">
    <div class="user-info">
        <div>
            <p>Outgoing requests</p>
            <span>
                Here you’ll find the list of all the requests that you have prepared/sent out to other companies. You
                can edit them, remove them, see progress and add new ones. </span>
        </div>
    </div>
</div>
<div class="card"  *ngIf="!isLoading">
    <div class="buttons">
        <div class="request-buttons">
            <button pButton pRipple type="button" label="Existing request" (click)="buttonSplit=false" 
            [ngStyle]="buttonSplit ? {'background': '#F0F2F4', 'color':'#BCBCBB'}
            : {'transform': 'translate(10px)', 'z-index':'2'}">
            </button>
            <button pButton pRipple type="button" label="Create new request" (click)="buttonSplit=true"
                [ngStyle]="!buttonSplit ? {'background': '#F0F2F4', 'color':'#BCBCBB'} :  {'transform': 'translate(-10px)', 'z-index':'2'}">
            </button>
        </div>
    </div>
    <div *ngIf="!buttonSplit">
        <div class="filter-search mt-3 ml-3">
            <div class="search-bar">
                <input class="search" placeholder="Search..." (keyup)="searchProviders()" [(ngModel)]="searchText">
            </div>
            <div class="tab-menu mt-3">
                <p-tabMenu [model]="items" [activeItem]="items[0]" (activeItemChange)="onActiveItemChange($event)" />
            </div>
        </div>
        <div class="mt-1">
            <div *ngFor="let request of pagedItems">
                <app-outgoing-request-item [request]="request" [providers]="providers"></app-outgoing-request-item>
            </div>
        </div>
        <div *ngIf="pagedItems.length === 0">
            <div class="no-results">
                <div class="image">
                    <img src="../../assets/images/no-results.svg">
                </div>
                <h4>No outgoing requests.</h4>
            </div>
        </div>
    
        <div class="pagination mt-5" *ngIf="pagedItems.length !== 0">
            <p-paginator [rows]="5" [totalRecords]="totalRecords" (onPageChange)="paginate($event)" />
        </div>
    </div>
    <div *ngIf="buttonSplit">
        <app-upsert-outgoing-request [providers]="providers" [buttonSplit]="buttonSplit" (update)="update()"></app-upsert-outgoing-request>
    </div>
 
</div>