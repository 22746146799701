import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import {
  ChangeProcessStatusRequest,
  CreateLinkedinProfileFilterRequest,
  LinkedinProfilesControllerService,
  ProcessCandidateStatusControllerService,
  ProcessControllerService,
  ProcessDto,
  StepControllerService,
  StepDto,
} from 'src/app/oapi_client/data_symphony';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { TalentDiscoveryModalComponent } from '../talent-discovery-modal/talent-discovery-modal.component';

interface ProcessStep {
  name: string;
  details?: string;
  isOpen?: boolean;
  id?: number;
  isCompleted?: boolean;
  showUndoMessage?: boolean;
  timeoutId?: any;
}

@Component({
  selector: 'app-received-request-details',
  templateUrl: './received-request-details.component.html',
  styleUrls: ['./received-request-details.component.scss'],
})
export class ReceivedRequestDetailsComponent {
  public openTable: boolean = false;
  constructor(
    private processManagementService: ProcessControllerService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private processUtils: ProcessUtilsService,
    private router: Router,
    private stepControllerService: StepControllerService,
    private dialogService: DialogService,
    private talentService: ProcessCandidateStatusControllerService,
    private readonly linkedinProfileService: LinkedinProfilesControllerService
  ) {}

  public id: number = 0;
  public process: ProcessDto | undefined;
  public keyword: string | null = null;
  public processSteps: ProcessStep[] = [];
  public displayTalentDiscovery: boolean = false;
  public displayCandidatePool: boolean = false;
  public openStepsForm: boolean = false;
  public steps: any[] = [];
  public addedSteps: any[] = [null];
  public selectedSteps: any[] = [];
  public allCandidatesByProcess: any[] = [];
  public orgCandidatesByProces: any[] = [];
  public sortOptions = ['Successful', 'In progress', 'Failed'];
  public isDropdownOpen: boolean = false;
  public videoUrl: any;
  public hasPeople: boolean = false;
  public showProcessUndoMessage: boolean = false;
  public processCompletionTimeoutId: any;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      this.id = id;
      this.getProcessById(id);
      this.getCandidates();
    });
  }

  openCandidatePoolModal() {
    this.displayCandidatePool = true;
  }

  openTalentDiscoveryModal() {
    const ref = this.dialogService.open(TalentDiscoveryModalComponent, {
      data: { processId: this.id },
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  }

  getProcessById(id: number): void {
    this.processManagementService.getById1(id).subscribe({
      next: (response) => {
        this.process = response?.body;
        this.setProcessSteps(response.body?.steps, response.body?.stepOrder);
        this.breadcrumbService.setGroupName(
          response?.body?.requirement?.name ?? ''
        );
      },
      error: (error) => {
        console.error('Error fetching process:', error);
      },
    });
  }

  navigateToPreviousPage(): void {
    this.router.navigate(['/admin/received-requests']);
  }

  navigateToList(): void {
    this.router.navigate(['/admin/received-requests'], {
      queryParams: { id: this.id },
      state: { expanded: true },
    });
  }

  openStepForm() {
    this.openStepsForm = true;
    this.getStepList();
  }

  getStepList() {
    this.stepControllerService.getStepList().subscribe((steps) => {
      if (steps.body)
        this.steps = steps.body?.map((item) => ({
          label: item['name'],
          value: item['id'],
        }));
    });
  }

  getVideo() {
    this.talentService.getCandidateVideo(36).subscribe({
      next: (res: Blob) => {
        this.videoUrl = res;
      },
      error: (err) => {
        console.error('Error fetching video:', err);
      },
    });
  }

  getCandidates() {
    this.talentService.getAllByProcessId1(this.id).subscribe((res) => {
      if (res.body?.length! > 1) {
        this.hasPeople = true;
      }

      if (res.body) {
        let candidatesByProcess = res.body.sort((a, b) => a.id! - b.id!);

        const stepCandidatesMap = new Map<number, Map<string, any>>();
        const memberIds: string[] = [];

        candidatesByProcess.forEach((candidate) => {
          const stepId = candidate.stepId!;
          const memberId = candidate.memberId!;
          memberIds.push(memberId);

          if (!stepCandidatesMap.has(stepId)) {
            stepCandidatesMap.set(stepId, new Map<string, any>());
          }

          const candidatesInStep = stepCandidatesMap.get(stepId)!;

          if (!candidatesInStep.has(memberId)) {
            candidatesInStep.set(memberId, candidate);
          } else {
            const existingCandidate = candidatesInStep.get(memberId)!;
            if (candidate.id! < existingCandidate.id!) {
              candidatesInStep.set(memberId, candidate);
            }
          }
        });

        const requestBody: CreateLinkedinProfileFilterRequest = {
          memberId: memberIds,
          pageSize: memberIds.length,
        };

        this.linkedinProfileService
          .filter1(requestBody)
          .subscribe((profileResponse) => {
            const profiles = profileResponse.body?.content || [];

            candidatesByProcess = Array.from(
              stepCandidatesMap.values()
            ).flatMap((stepMap) => Array.from(stepMap.values()));

            this.allCandidatesByProcess = candidatesByProcess.map((talent) => {
              const profile = profiles.find(
                (p) =>
                  p.publicMemberId === talent.memberId ||
                  p.publicLinkedinMemberId === talent.memberId
              );
              return {
                ...talent,
                profile: profile ? profile : null,
              };
            });
            this.allCandidatesByProcess.map(profile=>profile.video = null);
            this.orgCandidatesByProces = this.allCandidatesByProcess;
          });
      }
    });
  }

  filterCandidates(step: any) {
    step.isOpen = !step.isOpen;
    this.processSteps.map((process) =>
      process !== step ? (process.isOpen = false) : ''
    );
    this.allCandidatesByProcess = this.orgCandidatesByProces.filter(
      (candidate) => candidate.stepId === step.id
    );
  }

  onStatusUpdated(step: any): void {
    this.getCandidates();
    step.isOpen = !step.isOpen;
  }

  removeStepProcess(step: any) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Are you sure you want to remove ${step.name} step from the request?`,
          paraph: `It will remove all the candidates if they are added.`,
        },
      })
      .onClose.subscribe((res) => {
        if (res) {
          const steps = this.processSteps.filter(
            (processStep) => step.id !== processStep.id
          );
          this.processManagementService
            .updateProcess(this.id, { stepIds: steps.map((step) => step.id!) })
            .subscribe(() => this.getProcessById(this.id));
        }
      });
  }

  addDropdown() {
    this.addedSteps.push(null);
    this.selectedSteps.push(null);
  }

  removeDropdown(index: number) {
    if (this.addedSteps.length > 1) {
      this.addedSteps.splice(index, 1);
      this.selectedSteps.splice(index, 1);
    }
  }

  saveSteps() {
    this.processManagementService
      .updateProcess(this.id, { stepIds: this.selectedSteps })
      .subscribe(() => {
        this.dialogService
          .open(ConfirmationDialogComponent, {
            data: {
              title: `Are you sure you want to complete adding steps?`,
              paraph:
                'Once you confirm the process is started and you can‘t add any more steps.',
            },
          })
          .onClose.subscribe((res) => {
            if (res) {
              this.getProcessById(this.id);
            }
          });
      });
  }

  stop(event: any) {
    event.stopPropagation();
  }

  addStep(event: any) {
    event.preventDefault();
    this.stepControllerService
      .createStep({ name: event.target.value })
      .subscribe(() => {
        this.openStepForm();
        event.target.value = '';
      });
  }

  setProcessSteps(
    steps: StepDto[] | undefined,
    stepOrder: number[] | undefined
  ): void {
    const orderedSteps = stepOrder?.map((orderId) =>
      steps?.find((step) => step.id === orderId)
    );
    this.processSteps = orderedSteps?.filter(
      (step) => step !== undefined
    ) as ProcessStep[];
  }

  markProcessAsCompleted() {
    this.processSteps.forEach((step) => {
      step.isCompleted = true;
    });
    this.showProcessUndoMessage = true;

    this.processCompletionTimeoutId = setTimeout(() => {
      if (this.showProcessUndoMessage) {
        const requestBody: ChangeProcessStatusRequest = {
          processStatus: ChangeProcessStatusRequest.processStatus.IN_REVIEW,
          changedBy: this.process?.acceptedHRProvider?.id,
        };

        this.processManagementService
          .changeStatus(this.id, requestBody)
          .subscribe({
            next: (response) => {
              console.log('Process status changed successfully', response);
              this.processSteps.forEach((step) => (step.isCompleted = true));
              this.showProcessUndoMessage = false;
              this.getProcessById(this.process?.id!);
            },
            error: (error) => {
              console.error('Error changing process status', error);
              this.processSteps.forEach((step) => {
                step.isCompleted = false;
                this.showProcessUndoMessage = false;
              });
              alert('Failed to mark process as completed. Please try again.');
            },
          });
      }
    }, 10000);
  }

  undoProcessCompletion() {
    clearTimeout(this.processCompletionTimeoutId);
    this.showProcessUndoMessage = false;
    this.processSteps.forEach((step) => {
      step.isCompleted = false;
    });
    console.log(
      `Undo process completion for process: ${this.process?.requirement?.name}`
    );
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  formatJobType(jobType: string): string {
    return this.processUtils.formatJobType(jobType);
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    return this.processUtils.formatExperience(min, max);
  }

  formatLanguages(languages: Array<string> | undefined): string {
    return this.processUtils.formatLanguages(languages);
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    return this.processUtils.formatJobTypes(jobTypes);
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    return this.processUtils.formatWorkingTypes(workingTypes);
  }
}
