import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PatternService {
  readonly pattern = /Null|--|\.\.|^\/$|\b\/\b|^\-$|^-$/;

  constructor() {}
}
