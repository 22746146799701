<div class="p-5 text-center">
    <div class="circle">
        <span class="pi pi-check-circle"></span>
    </div>
    <div class="headline mt-3">
        <label class="tag-text">{{title}}</label>
    </div>
    <div class="line pt-2">
        <label class="tag-paraph">
            {{paraph}}</label>
    </div>
    <div class="button-class">
        <button pButton pRipple type="button" (click)="close()" label="OK" class="p-button-add" iconPos="left"></button>
    </div>
</div>