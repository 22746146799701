import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { filter } from 'rxjs';
import { AuthHelper } from 'src/app/helpers/auth.helper';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  items: MenuItem[] = [];
  selectedRoute: string | null = null;
  constructor(private authHelper: AuthHelper, private router: Router) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.selectedRoute = event.url.substring(1);
      });
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'HOME',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Dashboard',
            icon: '../../../assets/images/dashboardIcon.svg',
            url: 'admin/dashboard',
          },
        ],
      },
      {
        label: 'SEARCH MANAGEMENT',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Talent Hunt',
            icon: '../../../assets/images/talentHuntIcon.svg',
            url: 'admin/talent-hunt',
          },
          {
            label: 'Talent Discovery',
            icon: '../../../assets/images/talentDiscoveryIcon.svg',
            url: 'admin/talent-discovery',
          },
          {
            label: 'Candidate Pool',
            icon: '../../../assets/images/cpIcon.svg',
            url: 'admin/candidate-pool',
          },
          {
            label: 'HR Providers',
            icon: '../../../assets/images/hrProviderIcon.svg',
            url: 'admin/hr-providers',
          },
          {
            label: 'Outgoing Requests',
            icon: '../../../assets/images/folders.svg',
            url: 'admin/outgoing-request',
          },
        ],
      },
    ];
  }
}
