<div *ngIf="candidates.length === 0">
    <p>Please finish the process above to move to the next process.</p>
</div>

<div class="table-container" *ngIf="candidates.length > 0">
    <div class="talent-wrapper">
        <div class="header">
            <div class="export-button-container mb-2">
                <div class="selectAll mt-1">
                    <p-checkbox [(ngModel)]="allConnectionsChecked" (onChange)="checkAllConnections($event)"
                        [binary]="true" inputId="binary"></p-checkbox>
                    Select All
                </div>
            </div>
        </div>
        <p-table #dt [value]="candidates" [rows]="rows" [first]="first" [paginator]="true" dataKey="externalId"
            [paginatorPosition]="'bottom'" class="table">
            <ng-template pTemplate="header" class="header">
                <tr class="header-row">
                    <th pColumn="person_info.name" style="width: auto;">
                        Talent name
                    </th>
                    <th>Title</th>
                    <th>
                        Experiences
                    </th>
                    <th style="width: 160px" >
                        Process Status
                    </th>
                    <th style="width: 200px;">
                        Interviewed
                    </th>
                    <th style="width: auto;">Actions</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-candidate>
                <tr [ngClass]="candidate.checked ? 'boldRow' : ''" (click)="selectRow(candidate)"
                    [class.selected]="isSelected(candidate)">
                    <td>
                        <div class="name">
                            <label class="custom-checkbox-label">
                                <input type="checkbox" class="custom-checkbox" [(ngModel)]="candidate.checked"
                                    (change)="onCheckboxChange($event, candidate)" />
                                <span class="custom-checkbox-icon"></span>
                            </label>

                            <span class="ellipsis-description" [pTooltip]="candidate?.profile?.personInfo.name"
                                style="padding-left: 5px; width: 172px;">
                                {{ candidate?.profile?.personInfo?.name }}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div [pTooltip]="candidate?.profile?.personInfo?.title">
                            <div class="ellipsis-description">
                                {{ candidate.profile?.personInfo?.title }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div [pTooltip]="generateTooltip(candidate)">
                            <div class="ellipsis-description">
                                <ng-container
                                    *ngFor="let experience of candidate.profile?.experience; let last = last">
                                    <span *ngIf="!last && isValidInstitutionName(experience.institutionName)">{{
                                        experience.institutionName }},
                                    </span>
                                    <span *ngIf="last">{{ experience.institutionName }}</span>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                    <td style="display: flex; ">
                        <p class="status" [ngClass]="{
                        'succeed': candidate.status === 'SUCCEED',
                        'pending': candidate.status === 'PENDING',
                        'created': candidate.status === 'CREATED',
                        'in-progress': candidate.status === 'IN_PROGRESS',
                        'failed': candidate.status === 'FAILED'
                      }"> {{ candidate.status }}</p>
                    </td>
                    <td>
                        <div>
                            <button class="button-purple"
                                (click)="uploadVideo(candidate?.profile?.personInfo?.name, candidate.profile?.publicMemberId, candidate.id)"
                                *ngIf="candidate.video == null">Upload Video</button>
                        </div>
                        <div *ngIf="candidate.video != null" class="video-td">
                            <video [src]="candidate.video" width="100" height="61" controls></video>
                            <span class="pi pi-trash" (click)="deleteVideo(candidate)"></span>
                        </div>
                    </td>

                    <td style="display: inline-flex;">
                        <button class="note-button"   [ngClass]="{'disabled-button': disabledCandidates[candidate.id]}" (click)="succeedCandidateStatus(candidate.id)" *ngIf="!employerLogged"   [disabled]="disabledCandidates[candidate.id]"> 
                            <img src="../../../assets/images/order.svg" alt="Note">
                        </button>
                        <app-notes-modal [notes]="candidate.notes" [userId]="candidate.id" (noteAdded)="notesUpdate()"></app-notes-modal>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <!-- <div class="button-container">
      <button class="button-transparent" (click)="onClose()">Cancel</button>
      <button class="button-purple" (click)="addTalentsToProcessStep()">
        Save
      </button>
    </div> -->
</div>