import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CompanyControllerService, CreateStarRequest, ProcessCandidateStatusControllerService, ProcessControllerService } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-review-provider',
  templateUrl: './review-provider.component.html',
  styleUrls: ['./review-provider.component.scss']
})
export class ReviewProviderComponent {
  public value: number = 0;
  public characterCount: number = 0;
  public processId: number = 0;
  public comment: string = '';
  constructor(public companyController: CompanyControllerService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, public messagingService: MessageService ){
      this.processId = this.config.data?.processId
  }
  rate(){
    const request: CreateStarRequest = {starCount: this.value, title: 'Title', processId: this.processId, comment: this.comment}
    this.companyController.createStar(request).subscribe(()=>{
      this.messagingService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'You have successfully rated a provider.',
        life: 3000,
      });
      this.ref.close();
    },error=>{
      this.messagingService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.messages[0],
        life: 3000,
      });
      this.ref.close()
    })
  }
}
