<div class="talent-hunt">
  <div>
    <p>Received requests</p>
    <span>
      Here you’ll find the list of all the requests that have been sent to you.
    </span>
  </div>
</div>

<div class="wrapper expanded">
  <div class="image-wrapper">
    <img src="../../../assets/images/expanded.svg" alt="close" (click)="navigateToList()" />
    <img src="../../../assets/images/closeIcon.svg" alt="close" (click)="navigateToPreviousPage()" />
  </div>
  <div class="company-info">
    <span class="date">By: <strong>{{ process?.employer?.name }}</strong></span>
    <span class="small-dot">•</span>
    <span class="date">{{formatDate(process?.createdAt)}}</span>
  </div>
  <div class="provider-info">
    <h2 class="title">{{process?.requirement?.name}}</h2>
    <p class="status" [ngClass]="{
              'succeed': process?.status === 'SUCCEED' || process?.status === 'IN_REVIEW',
              'pending': process?.status === 'PENDING',
              'in-progress': process?.status === 'IN_PROGRESS'
            }">
      <ng-container *ngIf="process?.status === 'SUCCEED'; else otherStatus">
        done
      </ng-container>
      <ng-template #otherStatus>{{ process?.status | titlecase }}</ng-template>
    </p>
  </div>
  <div class="provider-info" *ngIf="process?.status === 'PENDING'">
    <div>
      <ng-container *ngIf="process.status === 'PENDING' && process!.offers === null " >
        <div class="provider-info">
          <button class="offer" (click)="sendOffer()">
            Send Offer
          </button>
          <button class="decline" (click)="declineOffer()">
            Decline
          </button>
        </div>
      </ng-container>
      <ng-container
        *ngIf="process?.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 1 && process?.offers?.at(-1).isEmployerAgreed == null
        && process?.offers?.at(-1).isHrAgreed == true">
        <div class="text-center"> Offer sent: <div class="font-bold ml-2">{{process?.offers?.at(-1).amount}}
            {{process?.offers?.at(-1).moneyType}}</div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="process?.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 2">
        <div class="provider-info">
          <div class="text-center mb-1"> Counter-offer: <div class="font-bold ml-2">
            {{process?.offers?.at(-1).amount}}
            {{process?.offers?.at(-1).moneyType}}</div>
        </div>
        <div class="provider-info">
          <button pButton type="button" label="Accept" class="accept" (click)="acceptOffer(process?.offers?.at(-1).id)"></button>
          <button pButton type="button" label="Decline" class="decline" (click)="declineOffer()"></button>
          <button pButton type="button" label="Last price" class="offer"(click)="sendOffer()"></button>
        </div>
        </div>
        
      </ng-container>
      <ng-container
        *ngIf="process?.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 3 && process?.offers?.at(-1).isEmployerAgreed != false">
        <div class="text-center"> Last price offer sent: <div class="font-bold ml-2">{{process?.offers?.at(-1).amount}}
          {{process?.offers?.at(-1).moneyType}}</div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="process?.status === 'PENDING'&& process?.offers !== null && process?.offers?.at(-1).isEmployerAgreed == false">
        <div class="text-center font-bold">Your offer was declined.</div>
      </ng-container>
      <ng-container
        *ngIf="process?.status === 'PENDING'&& process?.offers !== null && process?.offers?.at(-1).isEmployerAgreed == null && 
        process?.offers?.at(-1).isHrAgreed == null  && process?.offers?.at(-1).offer == null && process?.offers?.at(-1).offeredById == null">
        <div class="text-center font-bold">Your have declined this offer.</div>
      </ng-container>
    </div>
  </div>

  <div class="container">
    <div>
      <h2>Experience</h2>
      <p>
        {{ formatExperience(process?.requirement?.minExperience, process?.requirement?.maxExperience) }}
      </p>
    </div>
    <div>
      <h2>Language(s)</h2>
      <p>{{ formatLanguages(process?.requirement?.languages) }}</p>
    </div>
    <div>
      <h2>Location</h2>
      <p>{{process?.requirement?.location}}</p>
    </div>
    <div>
      <h2>Remote</h2>
      <p>{{ formatWorkingTypes(process?.requirement?.workingTypes) }}</p>
    </div>
    <div>
      <h2>Job type</h2>
      <p>{{ formatJobTypes(process?.requirement?.jobTypes) }}</p>
    </div>
    <div *ngIf="process?.requirement?.keywordList">
      <h2>Skills</h2>
      <div class="skills">
        <ng-container *ngFor="let keyword of process?.requirement?.keywordList">
          <span>{{ keyword }}</span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="description" *ngIf="process?.requirement?.description">
    <h2>Description</h2>
    <p>{{ process?.requirement?.description }}</p>
  </div>
  <hr />
  <div *ngIf="process?.note">
    <h2>Note</h2>
    <p>{{ process?.note }}</p>
    <hr />
  </div>

  <div class="info top">
    <div class="info-wrapper">
      <h2>Process</h2>
      <img src="../../../assets/images/info.svg" alt="info" />
      <button class="button-green" (click)="markProcessAsCompleted()" *ngIf="process?.status === 'IN_PROGRESS'">
        Mark Process as completed
      </button>
      <button *ngIf="process?.status === 'IN_REVIEW'" class="button-completed">
        Process marked as completed
      </button>
    </div>
    <div>
      <p *ngIf="process?.status === 'IN_REVIEW'" class="status" [ngClass]="{
              'succeed': process?.status === 'IN_REVIEW'}">
        To be reviewed
      </p>
    </div>
  </div>
  <div *ngIf="showProcessUndoMessage">
    <p-progressBar [value]="progressValue" [style]="{height: '3px', width: '320px', marginLeft: '5px', borderRadius: '25px', color: '#6FCF97'}" 
    styleClass="custom-progress-bar"  [showValue]="false" ></p-progressBar>
    <div class="completion-message">
      <strong>{{ process?.requirement?.name }}</strong> marked as
      <strong>completed.</strong>
      <a (click)="undoProcessCompletion()">Undo</a> this action
    </div>
  </div>
  <span *ngIf="process?.steps?.length === 0 && process?.status !== 'IN_PROGRESS'">The Employer hasn‘t defined any
    process steps. Once you accept you‘ll be
    able to do so.</span>
  <div *ngFor="let step of processSteps; let i = index">
    <div class="process-item" [ngClass]="{'open': step.isOpen}">
      <div class="process">
        <div class="not-completed"
          *ngIf="!step.isCompleted && process?.status !== 'IN_REVIEW' &&  process?.status !== 'SUCCEED'"></div>
        <div class="not-completed"
          *ngIf="step.isCompleted || process?.status === 'IN_REVIEW' || process?.status === 'SUCCEED'">
          <img src="../../../assets/images/checkmark.svg" />
        </div>
        <span>{{ step.name }}</span>
      </div>
      <!-- <div class="filter-buttons" *ngIf="step.isOpen && allCandidatesByProcess.length>0">
        <div class="sort">
          <span>Sort by: <span>Successful</span><span class="pi pi-angle-down"></span></span>
        </div>
        <div class="filter">
          <app-filters-modal (filtersApplied)="ngOnInit()"></app-filters-modal>
        </div>
      </div> -->
      <div class="process-action">
        <button class="note-button" [disabled]="true" *ngIf="step.isOpen" [pTooltip]="'Comments'">
          <img src="../../../assets/images/note-process.svg" alt="Note" class="disabled" />
        </button>
        <button
          pButton
          pRipple
          class="process-button plus-button"
          [pTooltip]="'Candidate Pool'"
          (click)="openCandidatePoolModal()"
          *ngIf="i === 0 && step.isOpen && process?.status !== 'IN_REVIEW'"
        ></button>
        <button
          pButton
          pRipple
          class="process-button folder-button"
          [pTooltip]="'Talent Discovery'"
          (click)="openTalentDiscoveryModal()"
          *ngIf="i === 0 && step.isOpen && process?.status !== 'IN_REVIEW'"
        ></button>
        <button
          pButton
          pRipple
          class="process-button order-button"
          [pTooltip]="'Move candidates to next step'"
          (click)="updateCandidatesStatusToSucceed()"
          *ngIf="step.isOpen && process?.status !== 'IN_REVIEW'"
          [ngClass]="{'disabled-button': allCandidatesMoved}"
          [disabled]="allCandidatesMoved"
        ></button>
        <!-- <button
          pButton
          pRipple
          icon="pi pi-pencil"
          class="process-button edit-button"
        ></button> -->
        <button pButton pRipple icon="pi pi-trash" class="process-button delete-button"
          (click)="removeStepProcess(step)" *ngIf="step.isOpen && process?.status !== 'IN_REVIEW'" 
          [pTooltip]="'Delete step'"></button>
        <button pButton pRipple icon="pi pi-angle-down" class="expand-button" (click)="filterCandidates(step)"
          [ngClass]="{'open': step.isOpen, 'closed': !step.isOpen}"></button>
      </div>
    </div>

    <div class="panel-content" [ngClass]="{'opened': step.isOpen}">
      <app-process-candidates-table
        [candidates]="allCandidatesByProcess"
        [disabledCandidates]="disabledCandidates"
        (statusUpdated)="onStatusUpdated(step)"
        (selectedCandidatesChanged)="onSelectedCandidatesChanged($event)"
      ></app-process-candidates-table>
    </div>
  </div>
  <div class="info" *ngIf="process?.steps?.length! > 0">
    <img src="../../../assets/images/info.svg" alt="info" />
    <p>
      You’ll be able to add more tasks and edit existing ones once you confirm
      this request.
    </p>
  </div>
</div>

<div class="add-talent-wrapper" *ngIf="process?.status === 'IN_PROGRESS' && 
   !hasPeople 
  ">
  <img src="../../../assets/images/addTalent.svg" alt="add talent" />
  <p>Add Talents!</p>
  <span>Lorem ipsum subtitle.</span>
  <div class="button-wrapper">
    <button class="button-purple" (click)="openTalentDiscoveryModal()">
      Open Talent Discovery
    </button>
    <button class="button-green" (click)="openCandidatePoolModal()">
      Candidate Pool
    </button>
  </div>
</div>

<div *ngIf="process?.status === 'IN_PROGRESS' && process?.steps!.length == 0">
  <div *ngIf="!openStepsForm" class="add-talent-wrapper">
    <img src="../../../assets/images/addProcess.svg" alt="add talent" />
    <p>No process defined</p>
    <span>To start the process add some steps. After that you can talents.</span>
    <div class="button-wrapper">
      <button class="button-purple" (click)="openStepForm()">Add Steps</button>
    </div>
  </div>
  <div *ngIf="openStepsForm" class="process-form">
    <div class="input-group" *ngFor="let step of addedSteps; let i = index">
      <div class="dropdown-form mt-2">
        <p-dropdown [options]="steps" placeholder="Select a step" [(ngModel)]="selectedSteps[i]">
          <ng-template pTemplate="footer">
            <div class="define-step">
              <input type="text" class="custom-input" placeholder="Add new step (on enter)" (click)="stop($event)"
                (keydown.enter)="addStep($event)" />
            </div>
          </ng-template>
        </p-dropdown>
        <button pButton pRipple icon="pi pi-trash" class="mt-2 delete-button" (click)="removeDropdown(i)"></button>
      </div>
    </div>
    <button pButton pRipple icon="pi pi-plus" (click)="addDropdown()" class="mt-2">
      Add more
    </button>
    <div class="done-button">
      <button class="button-purple" (click)="saveSteps()">Done</button>
    </div>
  </div>
</div>

<p-dialog [(visible)]="displayCandidatePool" [modal]="true" [closable]="false">
  <app-candidate-pool-modal
    [processId]="id"
    (closeModal)="displayCandidatePool  = false"
    (peopleAdded)="onPeopleAdded()"
  ></app-candidate-pool-modal>
</p-dialog>
