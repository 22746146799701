/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ChangeProcessStatusRequest = {
    processStatus?: ChangeProcessStatusRequest.processStatus;
    changedBy?: number;
};
export namespace ChangeProcessStatusRequest {
    export enum processStatus {
        CREATED = 'CREATED',
        PENDING = 'PENDING',
        IN_PROGRESS = 'IN_PROGRESS',
        IN_REVIEW = 'IN_REVIEW',
        SUCCEED = 'SUCCEED',
        CANCELLED = 'CANCELLED',
    }
}

