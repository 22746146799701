import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyControllerService, CreateHRCompanyFilterRequest, DSResponseListCompanyDto, ProcessCandidateStatusControllerService, ProcessCandidateStatusNoteDto } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent {

  @Input() notes: ProcessCandidateStatusNoteDto[] | undefined;
  @Input() userId: number | undefined;
  @Output() noteAdded: EventEmitter<any> = new EventEmitter<any>();

  isOpen=false;
  public note: string = '';

  constructor(private elementRef: ElementRef, private datePipe: DatePipe, 
  private talentService: ProcessCandidateStatusControllerService) { }

  ngOnInit(){
    this.notes?.map(note=> note.createdAt = String(this.datePipe.transform(note.createdAt, 'dd.MM.yyyy HH:mm')) )
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  addNote(){
    this.talentService.createNote({processCandidateStatusId: this.userId!, note: this.note, companyId: 36}).subscribe(()=>{
      this.noteAdded.emit();
      this.note = '';
    })
  }

}
