/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseBoolean } from '../models/AmsResponseBoolean';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ApiControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param api
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public createApi(
        api: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/api/{api}',
            path: {
                'api': api,
            },
        });
    }
}
