/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateGroupRequest } from '../models/CreateGroupRequest';
import type { CreateLinkedinProfileFilterRequest } from '../models/CreateLinkedinProfileFilterRequest';
import type { DSResponseGroupModelDto } from '../models/DSResponseGroupModelDto';
import type { DSResponseListGroupModelDto } from '../models/DSResponseListGroupModelDto';
import type { DSResponsePageLinkedinProfileDto } from '../models/DSResponsePageLinkedinProfileDto';
import type { DSResponseString } from '../models/DSResponseString';
import type { UpdateGroupRequest } from '../models/UpdateGroupRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class GroupControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param groupId
     * @param requestBody
     * @returns DSResponseGroupModelDto OK
     * @throws ApiError
     */
    public updateGroup(
        groupId: number,
        requestBody: UpdateGroupRequest,
    ): Observable<DSResponseGroupModelDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/group/{groupId}',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param groupId
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteGroup(
        groupId: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/group/{groupId}',
            path: {
                'groupId': groupId,
            },
        });
    }
    /**
     * @param groupId
     * @param requestBody
     * @returns DSResponsePageLinkedinProfileDto OK
     * @throws ApiError
     */
    public listMember(
        groupId: number,
        requestBody: CreateLinkedinProfileFilterRequest,
    ): Observable<DSResponsePageLinkedinProfileDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/group/memberList/{groupId}',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param groupId
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public importMemberToGroup(
        groupId: number,
        requestBody?: {
            file: Blob;
        },
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/group/import/{groupId}',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param groupId
     * @returns DSResponsePageLinkedinProfileDto OK
     * @throws ApiError
     */
    public exportGroupMembers(
        groupId: number,
    ): Observable<DSResponsePageLinkedinProfileDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/group/exportGroupMembers/{groupId}',
            path: {
                'groupId': groupId,
            },
        });
    }
    /**
     * @param groupId
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public addMember(
        groupId: number,
        requestBody: Array<string>,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/group/addMember/{groupId}',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns DSResponseListGroupModelDto OK
     * @throws ApiError
     */
    public getAll1(): Observable<DSResponseListGroupModelDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/group/',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseGroupModelDto OK
     * @throws ApiError
     */
    public createGroup(
        requestBody: CreateGroupRequest,
    ): Observable<DSResponseGroupModelDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/group/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns DSResponseGroupModelDto OK
     * @throws ApiError
     */
    public getById4(
        id: number,
    ): Observable<DSResponseGroupModelDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/group/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param groupId
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteGroupMember(
        groupId: number,
        requestBody: Array<string>,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/group/{groupId}/deleteMember',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
