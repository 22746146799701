<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="talent-hunt" *ngIf="!isLoading">
  <p>
    {{ formatKeywordList() }}
  </p>
  <span> See the results of this specific talent hunt. </span>
</div>

<div class="table-container" *ngIf="!isLoading">
  <div class="card talent-wrapper">
    <div class="col-12 filter-wrapper">
      <div class="col-5">
        <input
          class="search-input"
          [(ngModel)]="search"
          pInputText
          placeholder="Search..."
        />
      </div>
      <div class="col-6 modal-wrapper">
        <app-experience-location-modal
          (filterApplied)="applyFilters($event)"
          (filterCleared)="clearFilters()"
        ></app-experience-location-modal>
        <app-industry-education-modal
          (filterApplied)="applyFilters($event)"
          (filterCleared)="clearFilters()"
        ></app-industry-education-modal>
      </div>
    </div>

    <p-table
      #dt
      [value]="profiles$"
      [lazy]="true"
      (onLazyLoad)="loadProfiles($event)"
      [rows]="paginationService.profileListPaginationState.rows"
      [first]="paginationService.profileListPaginationState.first"
      [paginator]="true"
      [rowHover]="true"
      dataKey="externalId"
      [showCurrentPageReport]="true"
      [paginatorPosition]="'bottom'"
    >
      <ng-template pTemplate="header" class="header">
        <tr>
          <th colspan="5" class="header-buttons">
            <div class="button-wrapper">
              <div style="display: flex">
                <th style="display: flex; align-items: center">
                  <label class="custom-checkbox-label">
                    <input
                      type="checkbox"
                      [checked]="selectAll"
                      (change)="toggleSelectAll($event)"
                    />
                    <span class="custom-checkbox-icon"></span>
                  </label>
                  <span style="padding-left: 5px;">Select All</span>
                </th>
              </div>

              <div class="dropdown">
                <div class="header-candidate" (click)="toggleDropdown()">
                  <img
                    src="../../../assets/images/addCPIcon.svg"
                    alt="Add to CP Image"
                  />
                  <span> Add to Candidate Poll</span>

                  <img
                    src="../../../assets/images/downArrowGreen.svg"
                    alt="arrow down"
                  />
                  <div *ngIf="dropdownVisible" class="dropdown-content">
                    <label
                      *ngFor="let group of groups$"
                      class="dropdown-item"
                      (click)="addMembersToGroup(group.id)"
                    >
                      {{ group.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr class="header-row">
          <td colspan="5">
            <div class="header-content">
              <th
                pColumn="profile.person_info.name"
                style="width: 16.8%; margin-left: 25px"
              >
                Candidate
              </th>
              <th pColumn="person_info.headline" style="width: 27.3%">Title</th>
              <th pColumn="person_info.experience" style="width: 32.2%">
                Experiences
              </th>

              <th pColumn="person_info.location" style="width: 19.7%">
                Location
              </th>
              <th style="width: 8.3%">Actions</th>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-profile let-expanded="expanded">
        <tr>
          <td style="width: 13.5%">
            <div class="wrapper" style="padding-left: 1rem">
              <label class="custom-checkbox-label">
                <input
                  type="checkbox"
                  [checked]="selectedProfiles[filter.pageIndex ?? 0]?.has(profile.publicMemberId || profile.publicLinkedinMemberId)"
                  (change)="toggleSelect(profile)"
                  class="custom-checkbox"
                />
                <span class="custom-checkbox-icon"></span>
              </label>
              <span
                class="ellipsis-description"
                [pTooltip]="profile.personInfo.name"
                (click)="viewProfileDetails(profile)"
                style="padding-left: 5px; width: 172px;"
              >
                {{ profile.personInfo.name }}
              </span>
            </div>
          </td>

          <td
            (click)="viewProfileDetails(profile)"
            style="width: 27%; margin-right: 5px;"
          >
            <div class="wrapper" [pTooltip]="profile?.personInfo?.headline">
              <div class="ellipsis-description">
                {{ profile?.personInfo?.headline }}
              </div>
            </div>
          </td>
          <td (click)="viewProfileDetails(profile)" style="width: 31.8%">
            <div class="wrapper" [pTooltip]="generateTooltip(profile)">
              <div class="ellipsis-description">
                <ng-container
                  *ngFor="let experience of profile?.experience; let last = last"
                >
                  <span
                    *ngIf="!last && isValidInstitutionName(experience.institutionName)"
                    >{{ experience.institutionName }},
                  </span>
                  <span *ngIf="last">{{ experience.institutionName }}</span>
                </ng-container>
              </div>
            </div>
          </td>

          <td (click)="viewProfileDetails(profile)" style="width: 19.5%">
            <div class="wrapper">
              {{ profile.personInfo?.location }}
            </div>
          </td>
          <td style="width: 10%">
            <div class="wrapper">
              <a [href]="profile?.linkedinProfileLink" target="_blank">
                <button
                  pTooltip="LinkedIn"
                  class="custom-button"
                  style="padding-left: 0.3rem;"
                >
                  <img
                    src="../../../assets/images/linkedinIcon.svg"
                    alt="LinkedIn Image"
                  />
                </button>
              </a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
