<div [ngClass]="!buttonSplit ? 'card':'form-card mt-6'">
  <form [formGroup]="myForm">
    <div class="form">
      <div class="section section-1">
        <h3>
          <span class="required">*</span>Requirements
          <img src="../../../assets/images/info.svg" class="ml-2" />
        </h3>
        <div class="mb-0">
          <label>Title</label>
          <input
            formControlName="title"
            placeholder="Enter Title"
            [ngClass]="getClassObject('title')"
          />
        </div>
        <div class="input-group mb-0 years-wrapper">
          <div class="input-max">
            <div>
              <label>Min. years of experience</label>
              <input
                formControlName="min"
                placeholder="Min. experience"
                [ngClass]="{'border-invalid': !myForm.get('min')?.valid || isMinMaxInvalid()}"
                type="number"
              />
            </div>
            <div>
              <label>Max. years of experience</label>
              <input
                formControlName="max"
                placeholder="Max. experience"
                [ngClass]="{'border-invalid':  isMinMaxInvalid()}"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="isMinMaxInvalid()" class="error-message">
            Min years cannot be greater than Max years.
          </div>
        </div>
        <div class="input-group">
          <label>Keywords</label>
          <p-chips
            placeholder="Type your keywords and press enter"
            formControlName="keywordList"
            (onRemove)="onValueRemoved($event)"
            (onAdd)="onValueAdded($event)"
            id="chipsInput"
          ></p-chips>
        </div>

        <div
          class="dropdown-group"
          *ngFor="let control of section1Dropdowns; let i = index"
        >
          <label [for]="'dropdown' + i">{{control.label}}</label>
          <p-multiSelect
            [options]="control.array"
            [formControlName]="control.control"
            placeholder="Select"
            [id]="'dropdown' + i"
          >
          </p-multiSelect>
        </div>
      </div>
      <div class="section section-2">
        <h3>
          Add steps <span class="optional">(Optional)</span
          ><img src="../../../assets/images/info.svg" class="ml-2" />
        </h3>
        <div formArrayName="steps">
          <div
            class="input-group mb-0"
            *ngFor="let dropdown of steps.controls; let i = index"
            [formGroupName]="i"
          >
            <p-dropdown
              formControlName="selectedOption"
              [options]="filteredStepLists[i]"
              placeholder="Select an option"
              class="input-steps"
            >
              <ng-template pTemplate="footer">
                <div class="define-step">
                  <input
                    type="text"
                    class="custom-input"
                    placeholder="Add new step (on enter)"
                    (click)="stop($event)"
                    (keydown.enter)="addStep($event)"
                  />
                </div> </ng-template
            ></p-dropdown>
            <button
              pTooltip="Delete"
              class="input-steps-button"
              (click)="removeDropdown(i)"
            >
              <img
                src="../../../assets/images/deleteIcon.svg"
                alt="Delete Image"
              />
            </button>
          </div>
        </div>
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          (click)="addDropdown()"
          class="mt-2"
          class="button-more"
        >
          Add more
        </button>
      </div>
      <div class="section section-3">
        <div *ngFor="let control of section3Textareas; let i = index">
          <h3>
            {{control.label}}
            <span class="optional" *ngIf="control.optional">(Optional)</span>
            <img src="../../../assets/images/info.svg" class="ml-2" />
          </h3>
          <div class="textarea-group">
            <label [for]="'textarea' + i">{{control.label}}</label>
            <textarea
              [id]="'textarea' + i"
              [formControlName]="control.control"
              placeholder="{{control.placeholder}}"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="down-buttons">
        <button
          type="button"
          label="Cancel"
          class="p-button-cancel  mr-5"
          iconPos="left"
          routerLink="/outgoing-requests"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="p-button-save"
          iconPos="left"
          (click)="onSubmit()"
          [disabled]="!myForm.valid"
        >
          {{ !requestId ? 'Next' : 'Save' }}
        </button>
      </div>
    </div>
  </form>
</div>
