import { Component, OnInit } from '@angular/core';
import { CompanyControllerService, CompanyDto } from '../../../oapi_client/data_symphony';
import { PaginationService } from 'src/app/helpers/pagination.service';

@Component({
  selector: 'app-hr-providers-list',
  templateUrl: './hr-providers-list.component.html',
  styleUrls: ['./hr-providers-list.component.scss']
})
export class HrProvidersListComponent implements OnInit {

  public providers: CompanyDto[] = [
    {
      id: 1,
      name: "Tech Innovators",
      type: CompanyDto.type.HR_PROVIDER,
      address: "123 Tech Road, Silicon Valley, CA",
      taxNumber: "TXN123456",
      phoneNumber: "123-456-7890",
      size: "Large",
      webSiteUrl: "http://www.techinnovators.com",
      additional: "Leading the industry in innovation",
      // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "info@techinnovators.com",
      companyStarsDto: [
        { starCount: 5 },
      ]
    },
    {
      id: 2,
      name: "Green Energy Solutions",
      type: CompanyDto.type.HR_PROVIDER,
      address: "456 Green Way, Portland, OR",
      taxNumber: "TXN654321",
      phoneNumber: "987-654-3210",
      size: "Medium",
      webSiteUrl: "http://www.greenenergysolutions.com",
      additional: "Committed to sustainable energy solutions",
      // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "contact@greenenergysolutions.com",
      companyStarsDto: [
        { starCount: 5 },
      ]
    },
    {
      id: 3,
      name: "Urban Construction Co.",
      type: CompanyDto.type.HR_PROVIDER,
      address: "789 Urban St, New York, NY",
      taxNumber: "TXN789012",
      phoneNumber: "555-123-4567",
      size: "Small",
      webSiteUrl: "http://www.urbanconstruction.com",
      additional: "Building the future, one project at a time",
      // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "info@urbanconstruction.com",
      companyStarsDto: [
        { starCount: 3 },
      ]
    },
    {
      id: 4,
      name: "HealthCare Plus",
      type: CompanyDto.type.HR_PROVIDER,
      address: "321 Wellness Blvd, Miami, FL",
      taxNumber: "TXN321654",
      phoneNumber: "321-654-9870",
      size: "Large",
      webSiteUrl: "http://www.healthcareplus.com",
      additional: "Your health, our priority",
      // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "support@healthcareplus.com",
      companyStarsDto: [
        { starCount: 3 },
      ]
    },
    {
      id: 5,
      name: "Fintech Pioneers",
      type: CompanyDto.type.HR_PROVIDER,
      address: "654 Money St, Chicago, IL",
      taxNumber: "TXN654987",
      phoneNumber: "654-987-3210",
      size: "Medium",
      webSiteUrl: "http://www.fintechpioneers.com",
      additional: "Innovating the finance world",
      // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "info@fintechpioneers.com",
      companyStarsDto: [
        { starCount: 4 },
      ]
    },
    {
      id: 6,
      name: "EduFuture",
      type: CompanyDto.type.HR_PROVIDER,
      address: "789 Learning Ln, Boston, MA",
      taxNumber: "TXN789654",
      phoneNumber: "789-654-1230",
      size: "Small",
      webSiteUrl: "http://www.edufuture.com",
      additional: "Shaping the minds of tomorrow",
      // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "contact@edufuture.com",
      companyStarsDto: [
        { starCount: 4 },
      ]
    },
    {
      id: 7,
      name: "Travel Explorers",
      type: CompanyDto.type.HR_PROVIDER,
      address: "123 Adventure Ave, Denver, CO",
      taxNumber: "TXN123987",
      phoneNumber: "123-987-6540",
      size: "Medium",
      webSiteUrl: "http://www.travelexplorers.com",
      additional: "Explore the world with us",
      // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "info@travelexplorers.com",
      companyStarsDto: [
        { starCount: 4 },
      ]
    },
    {
      id: 8,
      name: "Foodie's Delight",
      type: CompanyDto.type.HR_PROVIDER,
      address: "456 Gourmet St, San Francisco, CA",
      taxNumber: "TXN456123",
      phoneNumber: "456-123-7890",
      size: "Small",
      webSiteUrl: "http://www.foodiesdelight.com",
      additional: "Delicious food at your doorstep",
    //  logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "support@foodiesdelight.com",
      companyStarsDto: [
        { starCount: 4 },
      ]
    },
    {
      id: 9,
      name: "Smart Home Solutions",
      type: CompanyDto.type.HR_PROVIDER,
      address: "789 Smart St, Austin, TX",
      taxNumber: "TXN789321",
      phoneNumber: "789-321-6540",
      size: "Medium",
      webSiteUrl: "http://www.smarthomesolutions.com",
      additional: "Your home, smarter",
    //  logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "info@smarthomesolutions.com",
      companyStarsDto: [
        { starCount: 4 },
      ]
    },
    {
      id: 10,
      name: "Fashion Forward",
      type: CompanyDto.type.HR_PROVIDER,
      address: "123 Style Blvd, Los Angeles, CA",
      taxNumber: "TXN123789",
      phoneNumber: "123-789-4560",
      size: "Large",
      webSiteUrl: "http://www.fashionforward.com",
      additional: "Stay ahead of the trends",
     // logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTILstzE4n4jxRaa2LcTb5Uf_JKLVtXIZjL3w&s",
      email: "contact@fashionforward.com",
      companyStarsDto: [
        { starCount: 4 },
      ]
    }
  ]; // for testing
  public first: number = 0;
  public rows: number = 8;
  public providersCount: number = 0;
  public pagedItems: any[] = [];
  public selectedProviders: CompanyDto[] = [];
  public searchText: string = "";
  public totalRecords: number = 0;
  public selectedSort: { label: string; value: number} = {
    label: "Best Rating",
    value: 2,
  };
  public rating = [{ label: 'Newest', value: 1 }, { label: 'Best Rating', value: 2 }];

  constructor(private companyService: CompanyControllerService,
  ) { }

  ngOnInit(): void {
    this.getHrProviders();
    // this.pagedItems = this.providers;
    // this.updatePagedItems(0, 8);
  }

  getHrProviders() {
    this.companyService.getCompanyList('HR_PROVIDER').subscribe(companies => {
      this.providers = companies.body!.sort((a, b) =>- new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime());
      this.updatePagedItems(0, 8);
    }, error => { console.log(error, 'error') })
  }

  paginate(event: any) {
    const startIndex = event.first;
    const endIndex = startIndex + event.rows;
    this.updatePagedItems(startIndex, endIndex);
  }

  updatePagedItems(startIndex: number, endIndex: number) {
    const filteredProviders = this.providers.filter(provider => 
      provider.name?.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
    );
    this.totalRecords = filteredProviders.length;
    this.pagedItems = filteredProviders.slice(startIndex, endIndex);
  }

  searchProviders() {
    this.updatePagedItems(0, 8);
  }

  addSelectedProvider(event: any) {
    const index = this.selectedProviders.findIndex(provider => provider.id === event.id);
    if (index !== -1) {
      this.selectedProviders = this.selectedProviders.filter(provider => provider.id !== event.id);
    } else {
      this.selectedProviders.push(event);
    }
  }

  receiveFilters(event:any){
    if(event.request.rating == null && event.request.minFinishedJobCount == null && event.request.maxFinishedJobCount == null){
      this.getHrProviders();
    }
    else{
      this.providers = event.resp;
      this.updatePagedItems(0,8);
    }
  }
}

