import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import {
  BudgetDto,
  ChangeProcessStatusRequest,
  CreateLinkedinProfileFilterRequest,
  CreateNegotiationRequest,
  LinkedinProfilesControllerService,
  NegotiationControllerService,
  ProcessCandidateStatusControllerService,
  ProcessControllerService,
  ProcessDto,
  StepControllerService,
  StepDto,
} from 'src/app/oapi_client/data_symphony';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { TalentDiscoveryModalComponent } from '../talent-discovery-modal/talent-discovery-modal.component';
import { ProcessCandidatesTableComponent } from './process-candidates-table/process-candidates-table.component';
import { SendCounterOfferComponent } from '../negotiation-dialogs/send-counter-offer/send-counter-offer.component';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';

interface ProcessStep {
  name: string;
  details?: string;
  isOpen?: boolean;
  id?: number;
  isCompleted?: boolean;
  showUndoMessage?: boolean;
  timeoutId?: any;
}

@Component({
  selector: 'app-received-request-details',
  templateUrl: './received-request-details.component.html',
  styleUrls: ['./received-request-details.component.scss'],
})
export class ReceivedRequestDetailsComponent {
  public openTable: boolean = false;
  constructor(
    private processManagementService: ProcessControllerService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private processUtils: ProcessUtilsService,
    private router: Router,
    private stepControllerService: StepControllerService,
    private dialogService: DialogService,
    private talentService: ProcessCandidateStatusControllerService,
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    private messageService: MessageService,
    private negotiationControllerService: NegotiationControllerService,
    private processControllerService: ProcessControllerService
  ) {}

  public id: number = 0;
  public process: any | undefined;
  public keyword: string | null = null;
  public processSteps: ProcessStep[] = [];
  public displayTalentDiscovery: boolean = false;
  public displayCandidatePool: boolean = false;
  public openStepsForm: boolean = false;
  public steps: any[] = [];
  public addedSteps: any[] = [null];
  public selectedSteps: any[] = [];
  public allCandidatesByProcess: any[] = [];
  public orgCandidatesByProces: any[] = [];
  public sortOptions = ['Successful', 'In progress', 'Failed'];
  public isDropdownOpen: boolean = false;
  public videoUrl: any;
  public hasPeople: boolean = false;
  public showProcessUndoMessage: boolean = false;
  public processCompletionTimeoutId: any;
  public currentOpenStep: any = null;
  public selectedCandidateIds: number[] = [];
  public progressValue: number = 0;
  public intervalId: any;
  public disabledCandidates: { [id: number]: boolean } = {};
  public allCandidatesMoved: boolean = false;

  @ViewChild(ProcessCandidatesTableComponent)
  processCandidatesTable!: ProcessCandidatesTableComponent;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      this.id = id;
      this.getProcessById(id);
      this.getCandidates();
    });
  }

  onSelectedCandidatesChanged(selectedCandidateIds: number[]): void {
    this.selectedCandidateIds = [...selectedCandidateIds];
  }

  acceptOffer(proposalId: number) {
    this.processControllerService
      .approveNegotiation(proposalId)
      .subscribe((response) => {
        const ref = this.dialogService.open(SuccessDialogComponent, {
          data: {
            title: 'Offer accepted',
            paraph: 'You have accepted the offer. The process can start.',
          },
        });
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        this.ngOnInit();
      });
  }

  updateCandidatesStatusToSucceed(): void {
    if (this.selectedCandidateIds.length === 0) {
      this.messageService.add({
        severity: 'info',
        summary: 'No Candidates Selected',
        detail: 'Please select candidates to move them to the next step.',
      });
      return;
    }

    this.talentService
      .succeedCandidateStatus(this.selectedCandidateIds)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Status Updated',
            detail: 'Successfully moved candidates to the next process step',
          });

          this.getCandidates();
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating the candidate status',
          });
        },
      });
  }

  getCandidates() {
    this.talentService.getAllByProcessId1(this.id).subscribe((res) => {
      if (res.body?.length! > 1) {
        this.hasPeople = true;
      }

      if (res.body) {
        let candidatesByProcess = res.body.sort((a, b) => a.id! - b.id!);

        const stepCandidatesMap =
          this.groupCandidatesByStep(candidatesByProcess);
        const memberIds = this.extractMemberIds(candidatesByProcess);

        const requestBody: CreateLinkedinProfileFilterRequest = {
          memberId: memberIds,
          pageSize: memberIds.length,
        };

        this.linkedinProfileService
          .filter1(requestBody)
          .subscribe((profileResponse) => {
            const profiles = profileResponse.body?.content || [];

            candidatesByProcess = this.mapProfilesToCandidates(
              stepCandidatesMap,
              profiles
            );

            this.allCandidatesByProcess = candidatesByProcess.map(
              (candidate) => ({
                ...candidate,
                video: null,
              })
            );

            const allMoved = this.allCandidatesByProcess.every(
              (candidate) => candidate.status !== 'IN_PROGRESS'
            );
            this.allCandidatesMoved = allMoved;
            candidatesByProcess.forEach((candidate) => {
              if (candidate.status !== 'IN_PROGRESS') {
                this.disabledCandidates[candidate.id!] = true;
              } else {
                this.disabledCandidates[candidate.id!] = false;
              }
            });

            this.orgCandidatesByProces = [...this.allCandidatesByProcess];

            if (this.currentOpenStep) {
              this.filterCandidates(this.currentOpenStep);
            }
          });
      }
    });
  }

  filterCandidates(step: any) {
    step.isOpen = !step.isOpen;
    this.currentOpenStep = step.isOpen ? step : null;

    this.processSteps.forEach((process) => {
      if (process !== step) process.isOpen = false;
    });

    if (step.isOpen) {
      this.allCandidatesByProcess = this.orgCandidatesByProces.filter(
        (candidate) => candidate.stepId === step.id
      );
    } else {
      this.allCandidatesByProcess = [];
    }
  }

  onStatusUpdated(step: any): void {
    this.getCandidates();
    this.currentOpenStep = step;
  }

  openCandidatePoolModal() {
    this.displayCandidatePool = true;
  }

  openTalentDiscoveryModal() {
    const ref = this.dialogService.open(TalentDiscoveryModalComponent, {
      data: { processId: this.id },
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(() => {
      this.onPeopleAdded();
      ref.close();
    });
  }

  getProcessById(id: number): void {
    this.processManagementService.getById1(id).subscribe({
      next: (response) => {
        this.process = response?.body;
        this.negotiationControllerService
          .getAllOfferWithCompany(id)
          .subscribe((res) => {
            this.process.offers = res!.body!;
          });

        this.setProcessSteps(response.body?.steps, response.body?.stepOrder);
        this.breadcrumbService.setGroupName(
          response?.body?.requirement?.name ?? ''
        );
      },
      error: (error) => {
        console.error('Error fetching process:', error);
      },
    });
  }

  navigateToPreviousPage(): void {
    this.router.navigate(['/received-requests']);
  }

  navigateToList(): void {
    this.router.navigate(['/received-requests'], {
      queryParams: { id: this.id },
      state: { expanded: true },
    });
  }

  openStepForm() {
    this.openStepsForm = true;
    this.getStepList();
  }

  getStepList() {
    this.stepControllerService.getStepList().subscribe((steps) => {
      if (steps.body)
        this.steps = steps.body?.map((item) => ({
          label: item['name'],
          value: item['id'],
        }));
    });
  }

  getVideo() {
    this.talentService.getCandidateVideo(36).subscribe({
      next: (res: Blob) => {
        this.videoUrl = res;
      },
      error: (err) => {
        console.error('Error fetching video:', err);
      },
    });
  }

  groupCandidatesByStep(candidates: any[]): Map<number, Map<string, any>> {
    const stepCandidatesMap = new Map<number, Map<string, any>>();

    candidates.forEach((candidate) => {
      const stepId = candidate.stepId!;
      const memberId = candidate.memberId!;

      if (!stepCandidatesMap.has(stepId)) {
        stepCandidatesMap.set(stepId, new Map<string, any>());
      }

      const candidatesInStep = stepCandidatesMap.get(stepId)!;

      if (
        !candidatesInStep.has(memberId) ||
        candidate.id! < candidatesInStep.get(memberId)!.id!
      ) {
        candidatesInStep.set(memberId, candidate);
      }
    });

    return stepCandidatesMap;
  }

  extractMemberIds(candidates: any[]): string[] {
    return candidates.map((candidate) => candidate.memberId!);
  }

  mapProfilesToCandidates(
    stepCandidatesMap: Map<number, Map<string, any>>,
    profiles: any[]
  ): any[] {
    return Array.from(stepCandidatesMap.values()).flatMap((stepMap) =>
      Array.from(stepMap.values()).map((candidate) => {
        const profile = profiles.find(
          (p) =>
            p.publicMemberId === candidate.memberId ||
            p.publicLinkedinMemberId === candidate.memberId
        );
        return { ...candidate, profile: profile || null };
      })
    );
  }

  removeStepProcess(step: any) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Are you sure you want to remove ${step.name} step from the request?`,
          paraph: `It will remove all the candidates if they are added.`,
        },
      })
      .onClose.subscribe((res) => {
        if (res) {
          const steps = this.processSteps.filter(
            (processStep) => step.id !== processStep.id
          );
          this.processManagementService
            .updateProcess(this.id, { stepIds: steps.map((step) => step.id!) })
            .subscribe(() => this.getProcessById(this.id));
        }
      });
  }

  addDropdown() {
    this.addedSteps.push(null);
    this.selectedSteps.push(null);
  }

  removeDropdown(index: number) {
    if (this.addedSteps.length > 1) {
      this.addedSteps.splice(index, 1);
      this.selectedSteps.splice(index, 1);
    }
  }

  saveSteps() {
    this.processManagementService
      .updateProcess(this.id, { stepIds: this.selectedSteps })
      .subscribe(() => {
        this.dialogService
          .open(ConfirmationDialogComponent, {
            data: {
              title: `Are you sure you want to complete adding steps?`,
              paraph:
                'Once you confirm the process is started and you can‘t add any more steps.',
            },
          })
          .onClose.subscribe((res) => {
            if (res) {
              this.getProcessById(this.id);
            }
          });
      });
  }

  stop(event: any) {
    event.stopPropagation();
  }

  addStep(event: any) {
    event.preventDefault();
    this.stepControllerService
      .createStep({ name: event.target.value })
      .subscribe(() => {
        this.openStepForm();
        event.target.value = '';
      });
  }

  sendOffer() {
    const ref = this.dialogService.open(SendCounterOfferComponent, {});

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe((res) => {
      if (res) {
        const offer: CreateNegotiationRequest = {
          offerToId: this.process?.employer?.id,
          processId: this.id,
          offer: {
            type: BudgetDto.type.EURO,
            amount: res.amount,
          },
          note: res.note,
        };
        this.negotiationControllerService
          .createNegotiation(offer)
          .subscribe(() => {
            const ref = this.dialogService.open(SuccessDialogComponent, {
              data: {
                title: 'Offer successfully sent!',
                paraph:
                  'You’ll get notified once the company responds to your offer!',
              },
            });

            const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
            const dynamicComponent =
              dialogRef?.instance as DynamicDialogComponent;

            const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
            dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
            this.ngOnInit();
          });
      }
    });
  }

  declineOffer() {
    const ref = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to decline Company XY‘s request?',
        paraph: 'Once you confirm, you can‘t go back.',
      },
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe((res) => {
      if (res) {
        this.negotiationControllerService
          .declineOffer(this.id)
          .subscribe((res) => {
            const ref = this.dialogService.open(SuccessDialogComponent, {
              data: {
                title: 'Request declined',
                paraph:
                  'You have declined the request. The Employer will be notified.',
              },
            });

            const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
            const dynamicComponent =
              dialogRef?.instance as DynamicDialogComponent;

            const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
            dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
            this.ngOnInit();
          });
      }
    });
  }

  setProcessSteps(
    steps: StepDto[] | undefined,
    stepOrder: number[] | undefined
  ): void {
    const orderedSteps = stepOrder?.map((orderId) =>
      steps?.find((step) => step.id === orderId)
    );
    this.processSteps = orderedSteps?.filter(
      (step) => step !== undefined
    ) as ProcessStep[];
  }

  markProcessAsCompleted() {
    this.processSteps.forEach((step) => {
      step.isCompleted = true;
    });
    this.showProcessUndoMessage = true;

    const duration = 10000;
    const stepInterval = 100;
    const increment = 100 / (duration / stepInterval);

    this.processCompletionTimeoutId = setTimeout(() => {
      if (this.showProcessUndoMessage) {
        const requestBody: ChangeProcessStatusRequest = {
          processStatus: ChangeProcessStatusRequest.processStatus.IN_REVIEW,
          changedBy: this.process?.acceptedHRProvider?.id,
        };

        this.processManagementService
          .changeStatus(this.id, requestBody)
          .subscribe({
            next: (response) => {
              this.processSteps.forEach((step) => (step.isCompleted = true));
              this.showProcessUndoMessage = false;
              this.getProcessById(this.process?.id!);
            },
            error: (error) => {
              this.processSteps.forEach((step) => {
                step.isCompleted = false;
                this.showProcessUndoMessage = false;
              });
              alert('Failed to mark process as completed. Please try again.');
            },
          });
      }
    }, 10000);

    this.intervalId = setInterval(() => {
      this.progressValue += increment;

      if (this.progressValue >= 100) {
        this.progressValue = 100;
        clearInterval(this.intervalId);
      }
    }, stepInterval);
  }

  resetProgress() {
    this.progressValue = 0;
  }

  undoProcessCompletion() {
    clearTimeout(this.processCompletionTimeoutId);
    clearInterval(this.intervalId);
    this.progressValue = 0;
    this.showProcessUndoMessage = false;
    this.processSteps.forEach((step) => {
      step.isCompleted = false;
    });
  }

  onPeopleAdded(): void {
    this.getCandidates();
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  formatJobType(jobType: string): string {
    return this.processUtils.formatJobType(jobType);
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    return this.processUtils.formatExperience(min, max);
  }

  formatLanguages(languages: Array<string> | undefined): string {
    return this.processUtils.formatLanguages(languages);
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    return this.processUtils.formatJobTypes(jobTypes);
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    return this.processUtils.formatWorkingTypes(workingTypes);
  }
}
