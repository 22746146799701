import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent {
  public title: string = "";
  public paraph: string = "";

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.title = this.config.data.title;
    this.paraph = this.config.data.paraph;
  }

  close() {
    this.ref.close(true);
  }
}
