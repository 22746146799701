<!-- <p-dialog header="Terms of use" [(visible)]="displayTOS">
    
Test push 4 5  ss
</p-dialog>

<div class="login-body">
    <div class="login-wrapper">
        <div class="login-panel">
            <img alt="diamond-layout" class="logo" src="./assets/layout/images/logo.svg">
            <div class="login-form">

                <br>
                <br>
                <br>
                <h2>Log Into Your Account</h2>
                <p>Please enter the information to log in.</p>
                <p-messages [enableService]="true" redisplay = "false"></p-messages><br><br><br>
                <form [formGroup]="form">
                    <div class="grid">
                        <div class="col">
                            <div class="field">
                                <span class="p-float-label">
                                    <input id="txtEmail" class="p-inputtext-lg rounded"
                                        formControlName="email" pInputText [(ngModel)]="email"
                                        [ngClass]="displayFieldCss('email')">
                                    <label for="txtEmail">Email</label>

                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col">
                            <div class="field">
                                <span class="p-float-label">
                                    <input id="txtPassword" class="p-inputtext-lg rounded" type="password"
                                        formControlName="password" pInputText [ngClass]="displayFieldCss('password')"
                                        [(ngModel)]="password">
                                    <label for="txtPassword">Password</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col">
                            <p> <a href="#">Forgot password?</a></p>
                        </div>
                    </div>


                    <button pButton pRipple type="submit" label="Sign in" class="p-button" (click)="login()"
                        [icon]="buttonDisabled?'fa-solid fa-spinner':'fa-solid fa-right-to-bracket'">Log in</button>
                </form>

            </div><br><br>
            <p>Don't have an account? <a href="./landing/home#registrationoptions">Sign up.</a></p>
          

        </div>
    </div>
</div> -->

<div class="login-container">
  <div class="login-content">
    <p-toast></p-toast>
    <img src="../../../assets/images/purpleLogo.svg" />
    <div class="modal-wrapper">
      <div>
        <h3>Log Into Your Account</h3>
        <span class="info">Please enter the information to log in.</span>

        <form [formGroup]="form">
          <label for="txtEmail">Email</label>
          <input
            id="txtEmail"
            class="p-inputtext-lg rounded"
            formControlName="email"
            pInputText
            [ngClass]="displayFieldCss('email')"
            [(ngModel)]="email"
          />
          <label for="txtPassword">Password</label>
          <input
            id="txtPassword"
            class="p-inputtext-lg rounded"
            type="password"
            formControlName="password"
            pInputText
            [ngClass]="displayFieldCss('password')"
            [(ngModel)]="password"
          />

          <p><a href="#">Forgot password?</a></p>

          <button
            pButton
            pRipple
            type="submit"
            class="login-button"
            (click)="login()"
            label="Log in"
          ></button>
        </form>
        <p>
          Don't have an account?
          <a href="./landing/register">Sign up.</a>
        </p>
      </div>
    </div>
  </div>
</div>
