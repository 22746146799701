import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  LinkedinProfileDto,
  LinkedinProfilesControllerService,
} from "../../oapi_client/data_symphony";
import * as Sentry from "@sentry/browser";
import { DatePipe } from "@angular/common";
import { BreadcrumbService } from "src/app/helpers/breadcrumb.service";

@Component({
  selector: "app-user-profile-details",
  templateUrl: "./user-profile-details.component.html",
  styleUrls: ['./user-profile-details.component.scss'],
  providers: [DatePipe],
})
export class UserProfileDetailsComponent implements OnInit {
  currentProfileID: string = "";
  currentJobID?: string;
  profiles$?: LinkedinProfileDto;

  constructor(
    private route: ActivatedRoute,
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.currentProfileID = params["id"];
      this.getProfileDetails();
    });
    this.route.queryParams.subscribe((queryParams) => {
      const source = queryParams['source'] || '';
      this.breadcrumbService.setSource(source);
    });
  }

  getProfileDetails() {
    if (this.currentProfileID) {
      this.linkedinProfileService.getProfile(this.currentProfileID).subscribe(
        (result: any) => {
          this.profiles$ = result!.body;
          if (this.profiles$?.personInfo?.name) {
            this.breadcrumbService.setPersonName(this.profiles$.personInfo.name);
          }
        },
        (error) => {
          Sentry.captureException("System can not get profile details.", {
            tags: { customTag: "Profile Details" },
            level: "error",
          });
        }
      );
    }
  }
}
