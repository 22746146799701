<div class="table-wrapper">
  <div>
    <h1>Proposals</h1>
    <p>
      If you accept a proposal, the rest of them are automatically declined.
    </p>
    <p class="sort">View: <span>All</span></p>
  </div>
  <div class="proposals-table">
    <p-table [value]="proposals" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th>Sent to</th>
          <th>Rating</th>
          <th>Number of jobs</th>
          <th>Date of sending</th>
          <th>Proposal</th>
          <th class="text-left pr-5">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-proposal>
        <tr>
          <td>{{proposal.companyName}}</td>
          <td>
            <p-rating [(ngModel)]="proposal.avgStarCount" [readonly]="true" [cancel]="false" />

            <span>({{proposal.avgStarCount}})</span>
          </td>
          <td>{{proposal.succeedProcessCount}}</td>
          <td>{{ proposal.date | date:'short' }}</td>
          <td>
            <div *ngIf="proposal.isHrAgreed == true && proposal.isEmployerAgreed == false">
              <div class="proposal-pending-status declined text-center">
                Declined
              </div>
            </div>
            <div *ngIf="proposal.offer == null && proposal.isHrAgreed == null && proposal.isEmployerAgreed == null">
              <div class="proposal-pending-status text-center">Pending</div>
            </div>
            <div *ngIf="proposal.offer !== null && proposal.isEmployerAgreed == null">
              <div class="proposal-offer-status text-center">
                {{proposal.offer.amount + ' ' + proposal.offer.type}}
              </div>
            </div>
            <div *ngIf="proposal.isEmployerAgreed == true">
              <div class="proposal-pending-status  accepted text-center">
                Accepted -
                {{proposal.offer.amount + ' ' + proposal.offer.type}}
              </div>
            </div>
          </td>
          <td class="text-left">
            <div *ngIf="proposal.offer == null && proposal.isHrAgreed == null && proposal.employerId">
              <button pButton type="button" label="Cancel" class="proposal-pending-action" (click)="cancel()"></button>
            </div>
            <div
              *ngIf="proposal.offer != null && proposal.isEmployerAgreed == null && proposal.isHrAgreed == true">
              <button pButton type="button" label="Accept" class="proposal-accept-action mr-2"
                (click)="openDialog('Accept', proposal.id, proposal.offer.amount, proposal.offer.type)"></button>
              <button pButton type="button" label="Decline" class="proposal-decline-action mr-2"
                (click)="openDialog('Decline',proposal.id, undefined, undefined, proposal.employerId)"></button>
              <button pButton type="button" label="Counter Offer" class="proposal-counter-offer" *ngIf="!proposal.hasThreeOccurrences && proposal.employerId != proposal.offeredById" 
              (click)="openDialog('Counter', proposal.id)"></button>
            </div>
            <div
              *ngIf="proposal.offer != null && proposal.employerId == proposal.offeredById && proposal.isEmployerAgreed != true">
                <div *ngIf="proposal.isHrAgreed != true">
                  Counter offer sent
                </div>
            </div>
            <div *ngIf="proposal.isHrAgreed == true && proposal.isEmployerAgreed == false" class="pr-2">
              <img src="../../../assets/images/deleteIcon.svg" />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>