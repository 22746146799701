import { Component, ElementRef, ViewChild } from '@angular/core';
import { Form } from '@angular/forms';
import { error } from 'console';
import { DynamicDialogRef, DynamicDialogConfig, DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { catchError, of, tap } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/admin/negotiation-dialogs/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/app/admin/negotiation-dialogs/success-dialog/success-dialog.component';
import { ProcessCandidateStatusControllerService } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-upload-video-dialog',
  templateUrl: './upload-video-dialog.component.html',
  styleUrls: ['./upload-video-dialog.component.scss']
})
export class UploadVideoDialogComponent {

  public name!: string;
  public blobData!: Blob;
  public fileName: string = '';
  public fileSize: string = '';
  public url: any;
  public memberId: number;
  public candidateId: number;
  public file: any;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private processCandidateStatusController: ProcessCandidateStatusControllerService,
    private dialogService: DialogService) {
    this.name = this.config.data.name;
    this.memberId = this.config.data.memberId;
    this.candidateId = this.config.data.candidateId;
  }

  @ViewChild('fileInput') fileInput!: ElementRef;

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput(event: any) {
    const file = event.target.files[0];
    this.file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    this.fileName = file.name;
    this.fileSize = this.bytesToMB(file.size).toFixed(2);
    reader.onload = (event) => {
      this.url = (<FileReader>event.target).result;

    }
  }

  bytesToMB(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  uploadVideo() {
    this.processCandidateStatusController.uploadVideo(this.candidateId, { file: this.file }).subscribe((res) => {
      this.ref.close();
      if (res.messages![0] == 'Success') {

        const ref = this.dialogService.open(SuccessDialogComponent, {
          data:
            { title: 'You have successfully uploaded the interview video.', paraph: 'You can check it in the list.' }
        });

        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      }
      else{
        const ref = this.dialogService.open(ErrorDialogComponent, {
          data:
            { title: 'There was a error while uploading the video.', paraph: 'Please try again later.' }
        });

        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      }
    });
  }


  cancel() {
    this.ref.close();
  }
}
