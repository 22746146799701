import { Component, OnInit } from '@angular/core';
import { AuthHelper } from '../../helpers/auth.helper';
import { map } from 'rxjs/operators';
import {
  DSResponseListJobTraceWithUserInfoDto,
  GroupControllerService,
  JobTraceControllerService,
  JobTraceWithUserInfoDto,
  LinkedinProfileStatisticModel,
} from '../../oapi_client/data_symphony';
import { LinkedinProfilesControllerService } from 'src/app/oapi_client/data_symphony';
import * as Sentry from '@sentry/browser';

export interface Country {
  name: string | undefined;
  experience: {
    '0-2': number;
    '2-5': number;
    '5-8': number;
    '8-10': number;
    '10+': number;
  };
}
@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss'],
})
export class AdminHomeComponent implements OnInit {
  currentUserID: number = 0;
  searchStatistics$: JobTraceWithUserInfoDto[] = [];
  statsCounts = {
    completed: 0,
    started: 0,
    inProgress: 0,
  };
  totalResultCount = 0;
  todayResultCount = 0;
  isModalOpen = false;
  isLoading: boolean = true;
  countries: Country[] = [];
  userGroupCount = 0;
  teamGroupCount = 0;

  constructor(
    private authHelper: AuthHelper,
    private readonly jobTraceSAervice: JobTraceControllerService,
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    private readonly groupControllerService: GroupControllerService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
    this.getSearchStatistics();
    this.getProfileStatistics();
    this.fetchCountryExperienceStatistics();
    this.getCandidatePool();
  }

  getSearchStatistics() {
    this.jobTraceSAervice
      .jobLinkedinList()
      .pipe(
        map(
          (response: DSResponseListJobTraceWithUserInfoDto) =>
            response.body || []
        )
      )
      .subscribe(
        (result) => {
          this.searchStatistics$ = result;
          this.statsCounts.started = result.filter(
            (stat) => stat.searchStatus?.toLowerCase() === 'started'
          ).length;
          this.statsCounts.inProgress = result.filter(
            (stat) => stat.searchStatus?.toLowerCase() === 'in_progress'
          ).length;
          this.statsCounts.completed = result.filter(
            (stat) => stat.searchStatus?.toLowerCase() === 'completed'
          ).length;
          this.isLoading = false;
        },
        (error) => {
          Sentry.captureException('System can not get search statistics.', {
            tags: { customTag: 'Dashboard' },
            level: 'error',
          });
          this.isLoading = false;
        }
      );
  }

  getProfileStatistics() {
    this.linkedinProfileService
      .totalProfileCount()
      .pipe()
      .subscribe(
        (result) => {
          this.totalResultCount = result.body || 0;
          this.isLoading = false;
        },
        (error) => {
          Sentry.captureException('System can not get profile statistics.', {
            tags: { customTag: 'Dashboard' },
            level: 'error',
          });
          this.isLoading = false;
        }
      );
    this.linkedinProfileService
      .lastDayProfileCount()
      .pipe()
      .subscribe(
        (result) => {
          this.todayResultCount = result.body || 0;
          this.isLoading = false;
        },
        (error) => {
          Sentry.captureException(
            'System can not get today profile statistics.',
            { tags: { customTag: 'Dashboard' }, level: 'error' }
          );
          this.isLoading = false;
        }
      );
  }
  fetchCountryExperienceStatistics() {
    this.linkedinProfileService.countryExperienceStatistic().subscribe(
      (response) => {
        if (response.body) {
          this.processCountryExperienceStatistics(response.body);
          this.isLoading = false;
        }
      },
      (error) => {
        Sentry.captureException(
          'System can not get country experience statistics.',
          {
            tags: { customTag: 'Dashboard' },
            level: 'error',
          }
        );
        this.isLoading = false;
      }
    );
  }

  processCountryExperienceStatistics(
    statistics: LinkedinProfileStatisticModel[]
  ) {
    const countryMap: { [key: string]: Country } = {};

    statistics.forEach((stat) => {
      if (!countryMap[stat.country_code!]) {
        countryMap[stat.country_code!] = {
          name: stat.country_code,
          experience: {
            '0-2': 0,
            '2-5': 0,
            '5-8': 0,
            '8-10': 0,
            '10+': 0,
          },
        };
      }

      switch (stat.experience_type) {
        case 'ZERO_TWO_YEARS':
          countryMap[stat.country_code!].experience['0-2'] +=
            stat.profile_count || 0;
          break;
        case 'TWO_FIVE_YEARS':
          countryMap[stat.country_code!].experience['2-5'] +=
            stat.profile_count || 0;
          break;
        case 'FIVE_EIGHT_YEARS':
          countryMap[stat.country_code!].experience['5-8'] +=
            stat.profile_count || 0;
          break;
        case 'EIGHT_TEN_YEARS':
          countryMap[stat.country_code!].experience['8-10'] +=
            stat.profile_count || 0;
          break;
        case 'TEN_PLUS_YEARS':
          countryMap[stat.country_code!].experience['10+'] +=
            stat.profile_count || 0;
          break;
      }
    });

    this.countries = Object.values(countryMap)
      .sort(
        (a, b) =>
          this.totalExperience(b.experience) -
          this.totalExperience(a.experience)
      )
      .slice(0, 10);
  }

  totalExperience(experience: Country['experience']): number {
    return (
      experience['0-2'] +
      experience['2-5'] +
      experience['5-8'] +
      experience['8-10'] +
      experience['10+']
    );
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  getCandidatePool() {
    this.groupControllerService.lastDayProfileCount1().subscribe(
      (response) => {
        if (response.body) {
          this.userGroupCount = response.body?.['userGroupCount'];
          this.teamGroupCount = response.body?.['teamGroupCount'];
          this.isLoading = false;
        }
      },
      (error) => {
        Sentry.captureException(
          'System can not get candidate pool statistics.',
          {
            tags: { customTag: 'Dashboard' },
            level: 'error',
          }
        );
        this.isLoading = false;
      }
    );
  }
}
